require.register("hampool/extra.json", function(exports, require, module) {
  module.exports = {
  "questions": [
    {
      "id": "E1A01",
      "correct": 3,
      "question": "When using a transceiver that displays the carrier frequency of phone signals, which of the following displayed frequencies represents the highest frequency at which a properly adjusted USB emission will be totally within the band?",
      "answers": [
        "The exact upper band edge",
        "300 Hz below the upper band edge",
        "1 kHz below the upper band edge",
        "3 kHz below the upper band edge"
      ]
    },
    {
      "id": "E1A02",
      "correct": 3,
      "question": "When using a transceiver that displays the carrier frequency of phone signals, which of the following displayed frequencies represents the lowest frequency at which a properly adjusted LSB emission will be totally within the band?",
      "answers": [
        "The exact lower band edge",
        "300 Hz above the lower band edge",
        "1 kHz above the lower band edge",
        "3 kHz above the lower band edge"
      ]
    },
    {
      "id": "E1A03",
      "correct": 2,
      "question": "With your transceiver displaying the carrier frequency of phone signals, you hear a station calling CQ on 14.349 MHz USB. Is it legal to return the call using upper sideband on the same frequency?",
      "answers": [
        "Yes, because you were not the station calling CQ",
        "Yes, because the displayed frequency is within the 20 meter band",
        "No, the sideband will extend beyond the band edge",
        "No, U.S. stations are not permitted to use phone emissions above 14.340 MHz"
      ]
    },
    {
      "id": "E1A04",
      "correct": 2,
      "question": "With your transceiver displaying the carrier frequency of phone signals, you hear a DX station calling CQ on 3.601 MHz LSB. Is it legal to return the call using lower sideband on the same frequency?",
      "answers": [
        "Yes, because the DX station initiated the contact",
        "Yes, because the displayed frequency is within the 75 meter phone band segment",
        "No, the sideband will extend beyond the edge of the phone band segment",
        "No, U.S. stations are not permitted to use phone emissions below 3.610 MHz"
      ]
    },
    {
      "id": "E1A05",
      "correct": 2,
      "question": "What is the maximum power output permitted on the 60 meter band?",
      "answers": [
        "50 watts PEP effective radiated power relative to an isotropic radiator",
        "50 watts PEP effective radiated power relative to a dipole",
        "100 watts PEP effective radiated power relative to the gain of a half-wave dipole",
        "100 watts PEP effective radiated power relative to an isotropic radiator"
      ]
    },
    {
      "id": "E1A06",
      "correct": 1,
      "question": "Where must the carrier frequency of a CW signal be set to comply with FCC rules for 60 meter operation?",
      "answers": [
        "At the lowest frequency of the channel",
        "At the center frequency of the channel",
        "At the highest frequency of the channel",
        "On any frequency where the signal’s sidebands are within the channel"
      ]
    },
    {
      "id": "E1A07",
      "correct": 3,
      "question": "Which amateur band requires transmission on specific channels rather than on a range of frequencies?",
      "answers": [
        "12 meter band",
        "17 meter band",
        "30 meter band",
        "60 meter band"
      ]
    },
    {
      "id": "E1A08",
      "correct": 1,
      "question": "If a station in a message forwarding system inadvertently forwards a message that is in violation of FCC rules, who is primarily accountable for the rules violation?",
      "answers": [
        "The control operator of the packet bulletin board station",
        "The control operator of the originating station",
        "The control operators of all the stations in the system",
        "The control operators of all the stations in the system not authenticating the source from which they accept communications"
      ]
    },
    {
      "id": "E1A09",
      "correct": 0,
      "question": "What is the first action you should take if your digital message forwarding station inadvertently forwards a communication that violates FCC rules?",
      "answers": [
        "Discontinue forwarding the communication as soon as you become aware of it",
        "Notify the originating station that the communication does not comply with FCC rules",
        "Notify the nearest FCC Field Engineer’s office",
        "Discontinue forwarding all messages"
      ]
    },
    {
      "id": "E1A10",
      "correct": 0,
      "question": "If an amateur station is installed aboard a ship or aircraft, what condition must be met before the station is operated?",
      "answers": [
        "Its operation must be approved by the master of the ship or the pilot in command of the aircraft",
        "The amateur station operator must agree not to transmit when the main radio of the ship or aircraft is in use",
        "The amateur station must have a power supply that is completely independent of the main ship or aircraft power supply",
        "The amateur operator must have an FCC Marine or Aircraft endorsement on his or her amateur license"
      ]
    },
    {
      "id": "E1A11",
      "correct": 1,
      "question": "Which of the following describes authorization or licensing required when operating an amateur station aboard a U.S.-registered vessel in international waters?",
      "answers": [
        "Any amateur license with an FCC Marine or Aircraft endorsement",
        "Any FCC-issued amateur license",
        "Only General class or higher amateur licenses",
        "An unrestricted Radiotelephone Operator Permit"
      ]
    },
    {
      "id": "E1A12",
      "correct": 2,
      "question": "With your transceiver displaying the carrier frequency of CW signals, you hear a DX station's CQ on 3.500 MHz. Is it legal to return the call using CW on the same frequency?",
      "answers": [
        "Yes, the DX station initiated the contact",
        "Yes, the displayed frequency is within the 80 meter CW band segment",
        "No, one of the sidebands of the CW signal will be out of the band",
        "No, U.S. stations are not permitted to use CW emissions below 3.525 MHz"
      ]
    },
    {
      "id": "E1A13",
      "correct": 1,
      "question": "Who must be in physical control of the station apparatus of an amateur station aboard any vessel or craft that is documented or registered in the United States?",
      "answers": [
        "Only a person with an FCC Marine Radio",
        "Any person holding an FCC issued amateur license or who is authorized for alien reciprocal operation",
        "Only a person named in an amateur station license grant",
        "Any person named in an amateur station license grant or a person holding an unrestricted Radiotelephone Operator Permit"
      ]
    },
    {
      "id": "E1A14",
      "correct": 3,
      "question": "What is the maximum bandwidth for a data emission on 60 meters?",
      "answers": [
        "60 Hz",
        "170 Hz",
        "1.5 kHz",
        "2.8 kHz"
      ]
    },
    {
      "id": "E1B01",
      "correct": 3,
      "question": "Which of the following constitutes a spurious emission?",
      "answers": [
        "An amateur station transmission made at random without the proper call sign identification",
        "A signal transmitted to prevent its detection by any station other than the intended recipient",
        "Any transmitted signal that unintentionally interferes with another licensed radio station",
        "An emission outside its necessary bandwidth that can be reduced or eliminated without affecting the information transmitted"
      ]
    },
    {
      "id": "E1B02",
      "correct": 3,
      "question": "Which of the following factors might cause the physical location of an amateur station apparatus or antenna structure to be restricted?",
      "answers": [
        "The location is near an area of political conflict",
        "The location is of geographical or horticultural importance",
        "The location is in an ITU Zone designated for coordination with one or more foreign governments",
        "The location is of environmental importance or significant in American history, architecture, or culture"
      ]
    },
    {
      "id": "E1B03",
      "correct": 0,
      "question": "Within what distance must an amateur station protect an FCC monitoring facility from harmful interference?",
      "answers": [
        "1 mile",
        "3 miles",
        "10 miles",
        "30 miles"
      ]
    },
    {
      "id": "E1B04",
      "correct": 2,
      "question": "What must be done before placing an amateur station within an officially designated wilderness area or wildlife preserve, or an area listed in the National Register of Historical Places?",
      "answers": [
        "A proposal must be submitted to the National Park Service",
        "A letter of intent must be filed with the National Audubon Society",
        "An Environmental Assessment must be submitted to the FCC",
        "A form FSD-15 must be submitted to the Department of the Interior"
      ]
    },
    {
      "id": "E1B05",
      "correct": 2,
      "question": "What is the National Radio Quiet Zone?",
      "answers": [
        "An area in Puerto Rico surrounding the Arecibo Radio Telescope",
        "An area in New Mexico surrounding the White Sands Test Area",
        "An area surrounding the National Radio Astronomy Observatory",
        "An area in Florida surrounding Cape Canaveral"
      ]
    },
    {
      "id": "E1B06",
      "correct": 0,
      "question": "Which of the following additional rules apply if you are installing an amateur station antenna at a site at or near a public use airport?",
      "answers": [
        "You may have to notify the Federal Aviation Administration and register it with the FCC as required by Part 17 of FCC rules",
        "No special rules apply if your antenna structure will be less than 300 feet in height",
        "You must file an Environmental Impact Statement with the EPA before construction begins",
        "You must obtain a construction permit from the airport zoning authority"
      ]
    },
    {
      "id": "E1B07",
      "correct": 1,
      "question": "What is the highest modulation index permitted at the highest modulation frequency for angle modulation below 29.0 MHz?",
      "answers": [
        "0.5",
        "1.0",
        "2.0",
        "3.0"
      ]
    },
    {
      "id": "E1B08",
      "correct": 3,
      "question": "What limitations may the FCC place on an amateur station if its signal causes interference to domestic broadcast reception, assuming that the receivers involved are of good engineering design?",
      "answers": [
        "The amateur station must cease operation",
        "The amateur station must cease operation on all frequencies below 30 MHz",
        "The amateur station must cease operation on all frequencies above 30 MHz",
        "The amateur station must avoid transmitting during certain hours on frequencies that cause the interference"
      ]
    },
    {
      "id": "E1B09",
      "correct": 2,
      "question": "Which amateur stations may be operated under RACES rules?",
      "answers": [
        "Only those club stations licensed to Amateur Extra class operators",
        "Any FCC-licensed amateur station except a Technician class",
        "Any FCC-licensed amateur station certified by the responsible civil defense organization for the area served",
        "Any FCC-licensed amateur station participating in the Military Auxiliary Radio System (MARS)"
      ]
    },
    {
      "id": "E1B10",
      "correct": 0,
      "question": "What frequencies are authorized to an amateur station operating under RACES rules?",
      "answers": [
        "All amateur service frequencies authorized to the control operator",
        "Specific segments in the amateur service MF, HF, VHF and UHF bands",
        "Specific local government channels",
        "Military Auxiliary Radio System (MARS) channels"
      ]
    },
    {
      "id": "E1B11",
      "correct": 0,
      "question": "What is the permitted mean power of any spurious emission relative to the mean power of the fundamental emission from a station transmitter or external RF amplifier installed after January 1, 2003 and transmitting on a frequency below 30 MHZ?",
      "answers": [
        "At least 43 dB below",
        "At least 53 dB below",
        "At least 63 dB below",
        "At least 73 dB below"
      ]
    },
    {
      "id": "E1C01",
      "correct": 3,
      "question": "What is a remotely controlled station?",
      "answers": [
        "A station operated away from its regular home location",
        "A station controlled by someone other than the licensee",
        "A station operating under automatic control",
        "A station controlled indirectly through a control link"
      ]
    },
    {
      "id": "E1C02",
      "correct": 0,
      "question": "What is meant by automatic control of a station?",
      "answers": [
        "The use of devices and procedures for control so that the control operator does not have to be present at a control point",
        "A station operating with its output power controlled automatically",
        "Remotely controlling a station’s antenna pattern through a directional control link",
        "The use of a control link between a control point and a locally controlled station"
      ]
    },
    {
      "id": "E1C03",
      "correct": 1,
      "question": "How do the control operator responsibilities of a station under automatic control differ from one under local control?",
      "answers": [
        "Under local control there is no control operator",
        "Under automatic control the control operator is not required to be present at the control point",
        "Under automatic control there is no control operator",
        "Under local control a control operator is not required to be present at a control point"
      ]
    },
    {
      "id": "E1C04",
      "correct": 0,
      "question": "What is meant by IARP?",
      "answers": [
        "An international amateur radio permit that allows U.S. amateurs to operate in certain countries of the Americas",
        "The internal amateur radio practices policy of the FCC",
        "An indication of increased antenna reflected power",
        "A forecast of intermittent aurora radio propagation"
      ]
    },
    {
      "id": "E1C05",
      "correct": 0,
      "question": "When may an automatically controlled station originate third party communications?",
      "answers": [
        "Never",
        "Only when transmitting RTTY or data emissions",
        "When agreed upon by the sending or receiving station",
        "When approved by the National Telecommunication and Information Administration"
      ]
    },
    {
      "id": "E1C06",
      "correct": 2,
      "question": "Which of the following statements concerning remotely controlled amateur stations is true?",
      "answers": [
        "Only Extra Class operators may be the control operator of a remote station",
        "A control operator need not be present at the control point",
        "A control operator must be present at the control point",
        "Repeater and auxiliary stations may not be remotely controlled"
      ]
    },
    {
      "id": "E1C07",
      "correct": 2,
      "question": "What is meant by local control?",
      "answers": [
        "Controlling a station through a local auxiliary link",
        "Automatically manipulating local station controls",
        "Direct manipulation of the transmitter by a control operator",
        "Controlling a repeater using a portable handheld transceiver"
      ]
    },
    {
      "id": "E1C08",
      "correct": 1,
      "question": "What is the maximum permissible duration of a remotely controlled station’s transmissions if its control link malfunctions?",
      "answers": [
        "30 seconds",
        "3 minutes",
        "5 minutes",
        "10 minutes"
      ]
    },
    {
      "id": "E1C09",
      "correct": 3,
      "question": "Which of these ranges of frequencies is available for an automatically controlled repeater operating below 30 MHz?",
      "answers": [
        "18.110 MHz - 18.168 MHz",
        "24.940 MHz - 24.990 MHz",
        "10.100 MHz - 10.150 MHz",
        "29.500 MHz - 29.700 MHz"
      ]
    },
    {
      "id": "E1C10",
      "correct": 1,
      "question": "What types of amateur stations may automatically retransmit the radio signals of other amateur stations?",
      "answers": [
        "Only beacon, repeater or space stations",
        "Only auxiliary, repeater or space stations",
        "Only earth stations, repeater stations or model craft",
        "Only auxiliary, beacon or space stations"
      ]
    },
    {
      "id": "E1C11",
      "correct": 0,
      "question": "Which of the following operating arrangements allows an FCC-licensed U.S. citizen to operate in many European countries, and alien amateurs from many European countries to operate in the U.S.?",
      "answers": [
        "CEPT agreement",
        "IARP agreement",
        "ITU reciprocal license",
        "All of these choices are correct"
      ]
    },
    {
      "id": "E1C12",
      "correct": 2,
      "question": "What types of communications may be transmitted to amateur stations in foreign countries?",
      "answers": [
        "Business-related messages for non-profit organizations",
        "Messages intended for connection to users of the maritime satellite service",
        "Communications incidental to the purpose of the amateur service and remarks of a personal nature",
        "All of these choices are correct"
      ]
    },
    {
      "id": "E1C13",
      "correct": 2,
      "question": "Which of the following is required in order to operate in accordance with CEPT rules in foreign countries where permitted?",
      "answers": [
        "You must identify in the official language of the country in which you are operating",
        "The U.S. embassy must approve of your operation",
        "You must bring a copy of FCC Public Notice DA 11-221",
        "You must append \"/CEPT\" to your call sign"
      ]
    },
    {
      "id": "E1D01",
      "correct": 0,
      "question": "What is the definition of the term telemetry?",
      "answers": [
        "One-way transmission of measurements at a distance from the measuring instrument",
        "Two-way radiotelephone transmissions in excess of 1000 feet",
        "Two-way single channel transmissions of data",
        "One-way transmission that initiates, modifies, or terminates the functions of a device at a distance"
      ]
    },
    {
      "id": "E1D02",
      "correct": 2,
      "question": "What is the amateur satellite service?",
      "answers": [
        "A radio navigation service using satellites for the purpose of self training, intercommunication and technical studies carried out by amateurs",
        "A spacecraft launching service for amateur-built satellites",
        "A radio communications service using amateur radio stations on satellites",
        "A radio communications service using stations on Earth satellites for public service broadcast"
      ]
    },
    {
      "id": "E1D03",
      "correct": 1,
      "question": "What is a telecommand station in the amateur satellite service?",
      "answers": [
        "An amateur station located on the Earth’s surface for communication with other Earth stations by means of Earth satellites",
        "An amateur station that transmits communications to initiate, modify or terminate functions of a space station",
        "An amateur station located more than 50 km above the Earth’s surface",
        "An amateur station that transmits telemetry consisting of measurements of upper atmosphere"
      ]
    },
    {
      "id": "E1D04",
      "correct": 0,
      "question": "What is an Earth station in the amateur satellite service?",
      "answers": [
        "An amateur station within 50 km of the Earth's surface intended for communications with amateur stations by means of objects in space",
        "An amateur station that is not able to communicate using amateur satellites",
        "An amateur station that transmits telemetry consisting of measurement of upper atmosphere",
        "Any amateur station on the surface of the Earth"
      ]
    },
    {
      "id": "E1D05",
      "correct": 2,
      "question": "What class of licensee is authorized to be the control operator of a space station?",
      "answers": [
        "All except Technician Class",
        "Only General, Advanced or Amateur Extra Class",
        "Any class with appropriate operator privileges",
        "Only Amateur Extra Class"
      ]
    },
    {
      "id": "E1D06",
      "correct": 0,
      "question": "Which of the following is a requirement of a space station?",
      "answers": [
        "The space station must be capable of terminating transmissions by telecommand when directed by the FCC",
        "The space station must cease all transmissions after 5 years",
        "The space station must be capable of changing its orbit whenever such a change is ordered by NASA",
        "All of these choices are correct"
      ]
    },
    {
      "id": "E1D07",
      "correct": 0,
      "question": "Which amateur service HF bands have frequencies authorized for space stations?",
      "answers": [
        "Only the 40 m, 20 m, 17 m, 15 m, 12 m and 10 m bands",
        "Only the 40 m, 20 m, 17 m, 15 m and 10 m bands",
        "Only the 40 m, 30 m, 20 m, 15 m, 12 m and 10 m bands",
        "All HF bands"
      ]
    },
    {
      "id": "E1D08",
      "correct": 3,
      "question": "Which VHF amateur service bands have frequencies available for space stations?",
      "answers": [
        "6 meters and 2 meters",
        "6 meters, 2 meters, and 1.25 meters",
        "2 meters and 1.25 meters",
        "2 meters"
      ]
    },
    {
      "id": "E1D09",
      "correct": 1,
      "question": "Which UHF amateur service bands have frequencies available for a space station?",
      "answers": [
        "70 cm only",
        "70 cm and 13 cm",
        "70 cm and 33 cm",
        "33 cm and 13 cm"
      ]
    },
    {
      "id": "E1D10",
      "correct": 1,
      "question": "Which amateur stations are eligible to be telecommand stations?",
      "answers": [
        "Any amateur station designated by NASA",
        "Any amateur station so designated by the space station licensee, subject to the privileges of the class of operator license held by the control operator",
        "Any amateur station so designated by the ITU",
        "All of these choices are correct"
      ]
    },
    {
      "id": "E1D11",
      "correct": 3,
      "question": "Which amateur stations are eligible to operate as Earth stations?",
      "answers": [
        "Any amateur station whose licensee has filed a pre-space notification with the FCC's International Bureau",
        "Only those of General, Advanced or Amateur Extra Class operators",
        "Only those of Amateur Extra Class operators",
        "Any amateur station, subject to the privileges of the class of operator license held by the control operator"
      ]
    },
    {
      "id": "E1E01",
      "correct": 3,
      "question": "What is the minimum number of qualified VEs required to administer an Element 4 amateur operator license examination?",
      "answers": [
        "5",
        "2",
        "4",
        "3"
      ]
    },
    {
      "id": "E1E02",
      "correct": 2,
      "question": "Where are the questions for all written U.S. amateur license examinations listed?",
      "answers": [
        "In FCC Part 97",
        "In a question pool maintained by the FCC",
        "In a question pool maintained by all the VECs",
        "In the appropriate FCC Report and Order"
      ]
    },
    {
      "id": "E1E03",
      "correct": 2,
      "question": "What is a Volunteer Examiner Coordinator?",
      "answers": [
        "A person who has volunteered to administer amateur operator license examinations",
        "A person who has volunteered to prepare amateur operator license examinations",
        "An organization that has entered into an agreement with the FCC to coordinate amateur operator license examinations",
        "The person who has entered into an agreement with the FCC to be the VE session manager"
      ]
    },
    {
      "id": "E1E04",
      "correct": 3,
      "question": "Which of the following best describes the Volunteer Examiner accreditation process?",
      "answers": [
        "Each General, Advanced and Amateur Extra Class operator is automatically accredited as a VE when the license is granted",
        "The amateur operator applying must pass a VE examination administered by the FCC Enforcement Bureau",
        "The prospective VE obtains accreditation from the FCC",
        "The procedure by which a VEC confirms that the VE applicant meets FCC requirements to serve as an examiner"
      ]
    },
    {
      "id": "E1E05",
      "correct": 1,
      "question": "What is the minimum passing score on amateur operator license examinations?",
      "answers": [
        "Minimum passing score of 70%",
        "Minimum passing score of 74%",
        "Minimum passing score of 80%",
        "Minimum passing score of 77%"
      ]
    },
    {
      "id": "E1E06",
      "correct": 2,
      "question": "Who is responsible for the proper conduct and necessary supervision during an amateur operator license examination session?",
      "answers": [
        "The VEC coordinating the session",
        "The FCC",
        "Each administering VE",
        "The VE session manager"
      ]
    },
    {
      "id": "E1E07",
      "correct": 1,
      "question": "What should a VE do if a candidate fails to comply with the examiner’s instructions during an amateur operator license examination?",
      "answers": [
        "Warn the candidate that continued failure to comply will result in termination of the examination",
        "Immediately terminate the candidate’s examination",
        "Allow the candidate to complete the examination, but invalidate the results",
        "Immediately terminate everyone’s examination and close the session"
      ]
    },
    {
      "id": "E1E08",
      "correct": 2,
      "question": "To which of the following examinees may a VE not administer an examination?",
      "answers": [
        "Employees of the VE",
        "Friends of the VE",
        "Relatives of the VE as listed in the FCC rules",
        "All of these choices are correct"
      ]
    },
    {
      "id": "E1E09",
      "correct": 0,
      "question": "What may be the penalty for a VE who fraudulently administers or certifies an examination?",
      "answers": [
        "Revocation of the VE's amateur station license grant and the suspension of the VE's amateur operator license grant",
        "A fine of up to $1000 per occurrence",
        "A sentence of up to one year in prison",
        "All of these choices are correct"
      ]
    },
    {
      "id": "E1E10",
      "correct": 2,
      "question": "What must the administering VEs do after the administration of a successful examination for an amateur operator license?",
      "answers": [
        "They must collect and send the documents to the NCVEC for grading",
        "They must collect and submit the documents to the coordinating VEC for grading",
        "They must submit the application document to the coordinating VEC according to the coordinating VEC instructions",
        "They must collect and send the documents to the FCC according to instructions"
      ]
    },
    {
      "id": "E1E11",
      "correct": 1,
      "question": "What must the VE team do if an examinee scores a passing grade on all examination elements needed for an upgrade or new license?",
      "answers": [
        "Photocopy all examination documents and forward them to the FCC for processing",
        "Three VEs must certify that the examinee is qualified for the license grant and that they have complied with the administering VE requirements",
        "Issue the examinee the new or upgrade license",
        "All these choices are correct"
      ]
    },
    {
      "id": "E1E12",
      "correct": 0,
      "question": "What must the VE team do with the application form if the examinee does not pass the exam?",
      "answers": [
        "Return the application document to the examinee",
        "Maintain the application form with the VEC's records",
        "Send the application form to the FCC and inform the FCC of the grade",
        "Destroy the application form"
      ]
    },
    {
      "id": "Which",
      "correct": 37,
      "question": "A. Record the exam session on video tape for later review by the",
      "answers": [
        "VE team",
        "Use a real-time video link and the Internet to connect the exam session to the observing VEs",
        "The exam proctor observes the applicants and reports any violations",
        "Have each applicant sign an affidavit stating that all session rules were followed"
      ]
    },
    {
      "id": "E1E14",
      "correct": 0,
      "question": "For which types of out-of-pocket expenses do the Part 97 rules state that VEs and VECs may be reimbursed?",
      "answers": [
        "Preparing, processing, administering and coordinating an examination for an amateur radio license",
        "Teaching an amateur operator license examination preparation course",
        "No expenses are authorized for reimbursement",
        "Providing amateur operator license examination preparation training materials"
      ]
    },
    {
      "id": "E1F01",
      "correct": 1,
      "question": "On what frequencies are spread spectrum transmissions permitted?",
      "answers": [
        "Only on amateur frequencies above 50 MHz",
        "Only on amateur frequencies above 222 MHz",
        "Only on amateur frequencies above 420 MHz",
        "Only on amateur frequencies above 144 MHz"
      ]
    },
    {
      "id": "E1F02",
      "correct": 2,
      "question": "What privileges are authorized in the U.S. to persons holding an amateur service license granted by the Government of Canada?",
      "answers": [
        "None, they must obtain a U.S. license",
        "All privileges of the Extra Class license",
        "The operating terms and conditions of the Canadian amateur service license, not to exceed U.S. Extra Class privileges",
        "Full privileges, up to and including those of the Extra Class License, on the 80, 40, 20, 15, and 10 meter bands"
      ]
    },
    {
      "id": "E1F03",
      "correct": 0,
      "question": "Under what circumstances may a dealer sell an external RF power amplifier capable of operation below 144 MHz if it has not been granted FCC certification?",
      "answers": [
        "It was purchased in used condition from an amateur operator and is sold to another amateur operator for use at that operator's station",
        "The equipment dealer assembled it from a kit",
        "It was imported from a manufacturer in a country that does not require certification of RF power amplifiers",
        "It was imported from a manufacturer in another country and was certificated by that country’s government"
      ]
    },
    {
      "id": "E1F04",
      "correct": 0,
      "question": "Which of the following geographic descriptions approximately describes \"Line A\"?",
      "answers": [
        "A line roughly parallel to and south of the U.S.-Canadian border",
        "A line roughly parallel to and west of the U.S. Atlantic coastline",
        "A line roughly parallel to and north of the U.S.-Mexican border and Gulf coastline",
        "A line roughly parallel to and east of the U.S. Pacific coastline"
      ]
    },
    {
      "id": "E1F05",
      "correct": 3,
      "question": "Amateur stations may not transmit in which of the following frequency segments if they are located in the contiguous 48 states and north of Line A?",
      "answers": [
        "440 MHz - 450 MHz",
        "53 MHz - 54 MHz",
        "222 MHz - 223 MHz",
        "420 MHz - 430 MHz"
      ]
    },
    {
      "id": "E1F06",
      "correct": 0,
      "question": "Under what circumstances might the FCC issue a Special Temporary Authority (STA) to an amateur station?",
      "answers": [
        "To provide for experimental amateur communications",
        "To allow regular operation on Land Mobile channels",
        "To provide additional spectrum for personal use",
        "To provide temporary operation while awaiting normal licensing"
      ]
    },
    {
      "id": "E1F07",
      "correct": 3,
      "question": "When may an amateur station send a message to a business?",
      "answers": [
        "When the total money involved does not exceed $25",
        "When the control operator is employed by the FCC or another government agency",
        "When transmitting international third-party communications",
        "When neither the amateur nor his or her employer has a pecuniary interest in the communications"
      ]
    },
    {
      "id": "E1F08",
      "correct": 0,
      "question": "Which of the following types of amateur station communications are prohibited?",
      "answers": [
        "Communications transmitted for hire or material compensation, except as otherwise provided in the rules",
        "Communications that have a political content, except as allowed by the Fairness Doctrine",
        "Communications that have a religious content",
        "Communications in a language other than English"
      ]
    },
    {
      "id": "E1F09",
      "correct": 3,
      "question": "Which of the following conditions apply when transmitting spread spectrum emission?",
      "answers": [
        "A station transmitting SS emission must not cause harmful interference to other stations employing other authorized emissions",
        "The transmitting station must be in an area regulated by the FCC or in a country that permits SS emissions",
        "The transmission must not be used to obscure the meaning of any communication",
        "All of these choices are correct"
      ]
    },
    {
      "id": "E1F10",
      "correct": 2,
      "question": "What is the maximum permitted transmitter peak envelope power for an amateur station transmitting spread spectrum communications?",
      "answers": [
        "1 W",
        "1.5 W",
        "10 W",
        "1.5 kW"
      ]
    },
    {
      "id": "E1F11",
      "correct": 3,
      "question": "Which of the following best describes one of the standards that must be met by an external RF power amplifier if it is to qualify for a grant of FCC certification?",
      "answers": [
        "It must produce full legal output when driven by not more than 5 watts of mean RF input power",
        "It must be capable of external RF switching between its input and output networks",
        "It must exhibit a gain of 0 dB or less over its full output range",
        "It must satisfy the FCC's spurious emission standards when operated at the lesser of 1500 watts or its full output power"
      ]
    },
    {
      "id": "E1F12",
      "correct": 1,
      "question": "Who may be the control operator of an auxiliary station?",
      "answers": [
        "Any licensed amateur operator",
        "Only Technician, General, Advanced or Amateur Extra Class operators",
        "Only General, Advanced or Amateur Extra Class operators",
        "Only Amateur Extra Class operators"
      ]
    },
    {
      "id": "E2A01",
      "correct": 2,
      "question": "What is the direction of an ascending pass for an amateur satellite?",
      "answers": [
        "From west to east",
        "From east to west",
        "From south to north",
        "From north to south"
      ]
    },
    {
      "id": "E2A02",
      "correct": 0,
      "question": "What is the direction of a descending pass for an amateur satellite?",
      "answers": [
        "From north to south",
        "From west to east",
        "From east to west",
        "From south to north"
      ]
    },
    {
      "id": "E2A03",
      "correct": 2,
      "question": "What is the orbital period of an Earth satellite?",
      "answers": [
        "The point of maximum height of a satellite's orbit",
        "The point of minimum height of a satellite's orbit",
        "The time it takes for a satellite to complete one revolution around the Earth",
        "The time it takes for a satellite to travel from perigee to apogee"
      ]
    },
    {
      "id": "E2A04",
      "correct": 1,
      "question": "What is meant by the term mode as applied to an amateur radio satellite?",
      "answers": [
        "The type of signals that can be relayed through the satellite",
        "The satellite's uplink and downlink frequency bands",
        "The satellite's orientation with respect to the Earth",
        "Whether the satellite is in a polar or equatorial orbit"
      ]
    },
    {
      "id": "E2A05",
      "correct": 3,
      "question": "What do the letters in a satellite's mode designator specify?",
      "answers": [
        "Power limits for uplink and downlink transmissions",
        "The location of the ground control station",
        "The polarization of uplink and downlink signals",
        "The uplink and downlink frequency ranges"
      ]
    },
    {
      "id": "E2A06",
      "correct": 0,
      "question": "On what band would a satellite receive signals if it were operating in mode U/V?",
      "answers": [
        "435 MHz - 438 MHz",
        "144 MHz - 146 MHz",
        "50.0 MHz - 50.2 MHz",
        "29.5 MHz - 29.7 MHz"
      ]
    },
    {
      "id": "E2A07",
      "correct": 3,
      "question": "Which of the following types of signals can be relayed through a linear transponder?",
      "answers": [
        "FM and CW",
        "SSB and SSTV",
        "PSK and Packet",
        "All of these choices are correct"
      ]
    },
    {
      "id": "E2A08",
      "correct": 1,
      "question": "Why should effective radiated power to a satellite which uses a linear transponder be limited?",
      "answers": [
        "To prevent creating errors in the satellite telemetry",
        "To avoid reducing the downlink power to all other users",
        "To prevent the satellite from emitting out-of-band signals",
        "To avoid interfering with terrestrial QSOs"
      ]
    },
    {
      "id": "E2A09",
      "correct": 0,
      "question": "What do the terms L band and S band specify with regard to satellite communications?",
      "answers": [
        "The 23 centimeter and 13 centimeter bands",
        "The 2 meter and 70 centimeter bands",
        "FM and Digital Store-and-Forward systems",
        "Which sideband to use"
      ]
    },
    {
      "id": "E2A10",
      "correct": 0,
      "question": "Why may the received signal from an amateur satellite exhibit a rapidly repeating fading effect?",
      "answers": [
        "Because the satellite is spinning",
        "Because of ionospheric absorption",
        "Because of the satellite's low orbital altitude",
        "Because of the Doppler Effect"
      ]
    },
    {
      "id": "E2A11",
      "correct": 1,
      "question": "What type of antenna can be used to minimize the effects of spin modulation and Faraday rotation?",
      "answers": [
        "A linearly polarized antenna",
        "A circularly polarized antenna",
        "An isotropic antenna",
        "A log-periodic dipole array"
      ]
    },
    {
      "id": "E2A12",
      "correct": 3,
      "question": "What is one way to predict the location of a satellite at a given time?",
      "answers": [
        "By means of the Doppler data for the specified satellite",
        "By subtracting the mean anomaly from the orbital inclination",
        "By adding the mean anomaly to the orbital inclination",
        "By calculations using the Keplerian elements for the specified satellite"
      ]
    },
    {
      "id": "E2A13",
      "correct": 1,
      "question": "What type of satellite appears to stay in one position in the sky?",
      "answers": [
        "HEO",
        "Geostationary",
        "Geomagnetic",
        "LEO"
      ]
    },
    {
      "id": "E2A14",
      "correct": 2,
      "question": "What technology is used to track, in real time, balloons carrying amateur radio transmitters?",
      "answers": [
        "Radar",
        "Bandwidth compressed LORAN",
        "APRS",
        "Doppler shift of beacon signals"
      ]
    },
    {
      "id": "E2B01",
      "correct": 0,
      "question": "How many times per second is a new frame transmitted in a fast-scan (NTSC) television system?",
      "answers": [
        "30",
        "60",
        "90",
        "120"
      ]
    },
    {
      "id": "E2B02",
      "correct": 2,
      "question": "How many horizontal lines make up a fast-scan (NTSC) television frame?",
      "answers": [
        "30",
        "60",
        "525",
        "1080"
      ]
    },
    {
      "id": "E2B03",
      "correct": -24,
      "question": "How is an interlaced scanning pattern generated in a fast-scan (NTSC) television system?",
      "answers": [
        "By scanning two fields simultaneously",
        "By scanning each field from bottom to top",
        "By scanning lines from left to right in one field and right to left in the next",
        "By scanning odd numbered lines in one field and even numbered lines in the next"
      ]
    },
    {
      "id": "E2B04",
      "correct": 1,
      "question": "What is blanking in a video signal?",
      "answers": [
        "Synchronization of the horizontal and vertical sync pulses",
        "Turning off the scanning beam while it is traveling from right to left or from bottom to top",
        "Turning off the scanning beam at the conclusion of a transmission",
        "Transmitting a black and white test pattern"
      ]
    },
    {
      "id": "E2B05",
      "correct": 2,
      "question": "Which of the following is an advantage of using vestigial sideband for standard fast- scan TV transmissions?",
      "answers": [
        "The vestigial sideband carries the audio information",
        "The vestigial sideband contains chroma information",
        "Vestigial sideband reduces bandwidth while allowing for simple video detector circuitry",
        "Vestigial sideband provides high frequency emphasis to sharpen the picture"
      ]
    },
    {
      "id": "E2B06",
      "correct": 0,
      "question": "What is vestigial sideband modulation?",
      "answers": [
        "Amplitude modulation in which one complete sideband and a portion of the other are transmitted",
        "A type of modulation in which one sideband is inverted",
        "Narrow-band FM modulation achieved by filtering one sideband from the audio before frequency modulating the carrier",
        "Spread spectrum modulation achieved by applying FM modulation following single sideband amplitude modulation"
      ]
    },
    {
      "id": "E2B07",
      "correct": 1,
      "question": "What is the name of the signal component that carries color information in NTSC video?",
      "answers": [
        "Luminance",
        "Chroma",
        "Hue",
        "Spectral Intensity"
      ]
    },
    {
      "id": "E2B08",
      "correct": 3,
      "question": "Which of the following is a common method of transmitting accompanying audio with amateur fast-scan television?",
      "answers": [
        "Frequency-modulated sub-carrier",
        "A separate VHF or UHF audio link",
        "Frequency modulation of the video carrier",
        "All of these choices are correct"
      ]
    },
    {
      "id": "E2B09",
      "correct": 3,
      "question": "What hardware, other than a receiver with SSB capability and a suitable computer, is needed to decode SSTV using Digital Radio Mondiale (DRM)?",
      "answers": [
        "A special IF converter",
        "A special front end limiter",
        "A special notch filter to remove synchronization pulses",
        "No other hardware is needed"
      ]
    },
    {
      "id": "E2B10",
      "correct": 0,
      "question": "Which of the following is an acceptable bandwidth for Digital Radio Mondiale (DRM) based voice or SSTV digital transmissions made on the HF amateur bands?",
      "answers": [
        "3 KHz",
        "10 KHz",
        "15 KHz",
        "20 KHz"
      ]
    },
    {
      "id": "E2B11",
      "correct": 1,
      "question": "What is the function of the Vertical Interval Signaling (VIS) code sent as part of an SSTV transmission?",
      "answers": [
        "To lock the color burst oscillator in color SSTV images",
        "To identify the SSTV mode being used",
        "To provide vertical synchronization",
        "To identify the call sign of the station transmitting"
      ]
    },
    {
      "id": "E2B12",
      "correct": 3,
      "question": "How are analog SSTV images typically transmitted on the HF bands?",
      "answers": [
        "Video is converted to equivalent Baudot representation",
        "Video is converted to equivalent ASCII representation",
        "Varying tone frequencies representing the video are transmitted using PSK",
        "Varying tone frequencies representing the video are transmitted using single sideband"
      ]
    },
    {
      "id": "E2B13",
      "correct": 2,
      "question": "How many lines are commonly used in each frame of an amateur slow-scan color television picture?",
      "answers": [
        "30 or 60",
        "60 or 100",
        "128 or 256",
        "180 or 360"
      ]
    },
    {
      "id": "E2B14",
      "correct": 0,
      "question": "What aspect of an amateur slow-scan television signal encodes the brightness of the picture?",
      "answers": [
        "Tone frequency",
        "Tone amplitude",
        "Sync amplitude",
        "Sync frequency"
      ]
    },
    {
      "id": "E2B15",
      "correct": 0,
      "question": "What signals SSTV receiving equipment to begin a new picture line?",
      "answers": [
        "Specific tone frequencies",
        "Elapsed time",
        "Specific tone amplitudes",
        "A two-tone signal"
      ]
    },
    {
      "id": "E2B16",
      "correct": 3,
      "question": "Which is a video standard used by North American Fast Scan ATV stations?",
      "answers": [
        "PAL",
        "DRM",
        "Scottie",
        "NTSC"
      ]
    },
    {
      "id": "E2B17",
      "correct": 1,
      "question": "What is the approximate bandwidth of a slow-scan TV signal?",
      "answers": [
        "600 Hz",
        "3 kHz",
        "2 MHz",
        "6 MHz"
      ]
    },
    {
      "id": "E2B18",
      "correct": 3,
      "question": "On which of the following frequencies is one likely to find FM ATV transmissions?",
      "answers": [
        "14.230 MHz",
        "29.6 MHz",
        "52.525 MHz",
        "1255 MHz"
      ]
    },
    {
      "id": "E2B19",
      "correct": 2,
      "question": "What special operating frequency restrictions are imposed on slow scan TV transmissions?",
      "answers": [
        "None; they are allowed on all amateur frequencies",
        "They are restricted to 7.245 MHz, 14.245 MHz, 21.345 MHz, and 28.945 MHz",
        "They are restricted to phone band segments and their bandwidth can be no greater than that of a voice signal of the same modulation type",
        "They are not permitted above 54 MHz"
      ]
    },
    {
      "id": "E2C01",
      "correct": 0,
      "question": "Which of the following is true about contest operating?",
      "answers": [
        "Operators are permitted to make contacts even if they do not submit a log",
        "Interference to other amateurs is unavoidable and therefore acceptable",
        "It is mandatory to transmit the call sign of the station being worked as part of every transmission to that station",
        "Every contest requires a signal report in the exchange"
      ]
    },
    {
      "id": "E2C02",
      "correct": 0,
      "question": "Which of the following best describes the term self-spotting in regards to HF contest operating?",
      "answers": [
        "The generally prohibited practice of posting one’s own call sign and frequency on a spotting network",
        "The acceptable practice of manually posting the call signs of stations on a spotting network",
        "A manual technique for rapidly zero beating or tuning to a station’s frequency before calling that station",
        "An automatic method for rapidly zero beating or tuning to a station’s frequency before calling that station"
      ]
    },
    {
      "id": "E2C03",
      "correct": 0,
      "question": "From which of the following bands is amateur radio contesting generally excluded?",
      "answers": [
        "30 m",
        "6 m",
        "2 m",
        "33 cm"
      ]
    },
    {
      "id": "E2C04",
      "correct": 0,
      "question": "What type of transmission is most often used for a ham radio mesh network?",
      "answers": [
        "Spread spectrum in the 2.4 GHz band",
        "Multiple Frequency Shift Keying in the 10 GHz band",
        "Store and forward on the 440 MHz band",
        "Frequency division multiplex in the 24 GHz band"
      ]
    },
    {
      "id": "E2C05",
      "correct": 1,
      "question": "What is the function of a DX QSL Manager?",
      "answers": [
        "To allocate frequencies for DXpeditions",
        "To handle the receiving and sending of confirmation cards for a DX station",
        "To run a net to allow many stations to contact a rare DX station",
        "To relay calls to and from a DX station"
      ]
    },
    {
      "id": "E2C06",
      "correct": 2,
      "question": "During a VHF/UHF contest, in which band segment would you expect to find the highest level of activity?",
      "answers": [
        "At the top of each band, usually in a segment reserved for contests",
        "In the middle of each band, usually on the national calling frequency",
        "In the weak signal segment of the band, with most of the activity near the calling frequency",
        "In the middle of the band, usually 25 kHz above the national calling frequency"
      ]
    },
    {
      "id": "E2C07",
      "correct": 0,
      "question": "What is the Cabrillo format?",
      "answers": [
        "A standard for submission of electronic contest logs",
        "A method of exchanging information during a contest QSO",
        "The most common set of contest rules",
        "The rules of order for meetings between contest sponsors"
      ]
    },
    {
      "id": "E2C08",
      "correct": 1,
      "question": "Which of the following contacts may be confirmed through the U.S. QSL bureau system?",
      "answers": [
        "Special event contacts between stations in the U.S.",
        "Contacts between a U.S. station and a non-U.S. station",
        "Repeater contacts between U.S. club members",
        "Contacts using tactical call signs"
      ]
    },
    {
      "id": "E2C09",
      "correct": 2,
      "question": "What type of equipment is commonly used to implement a ham radio mesh network?",
      "answers": [
        "A 2 meter VHF transceiver with a 1200 baud modem",
        "An optical cable connection between the USB ports of 2 separate computers",
        "A standard wireless router running custom software",
        "A 440 MHz transceiver with a 9600 baud modem"
      ]
    },
    {
      "id": "E2C10",
      "correct": 3,
      "question": "Why might a DX station state that they are listening on another frequency?",
      "answers": [
        "Because the DX station may be transmitting on a frequency that is prohibited to some responding stations",
        "To separate the calling stations from the DX station",
        "To improve operating efficiency by reducing interference",
        "All of these choices are correct"
      ]
    },
    {
      "id": "E2C11",
      "correct": 0,
      "question": "How should you generally identify your station when attempting to contact a DX station during a contest or in a pileup?",
      "answers": [
        "Send your full call sign once or twice",
        "Send only the last two letters of your call sign until you make contact",
        "Send your full call sign and grid square",
        "Send the call sign of the DX station three times, the words \"this is\", then your call sign three times"
      ]
    },
    {
      "id": "E2C12",
      "correct": 1,
      "question": "What might help to restore contact when DX signals become too weak to copy across an entire HF band a few hours after sunset?",
      "answers": [
        "Switch to a higher frequency HF band",
        "Switch to a lower frequency HF band",
        "Wait 90 minutes or so for the signal degradation to pass",
        "Wait 24 hours before attempting another communication on the band"
      ]
    },
    {
      "id": "E2C13",
      "correct": 3,
      "question": "What indicator is required to be used by U.S.-licensed operators when operating a station via remote control where the transmitter is located in the U.S.?",
      "answers": [
        "/ followed by the USPS two letter abbreviation for the state in which the remote station is located",
        "/R# where # is the district of the remote station",
        "The ARRL section of the remote station",
        "No additional indicator is required"
      ]
    },
    {
      "id": "E2D01",
      "correct": 1,
      "question": "Which of the following digital modes is especially designed for use for meteor scatter signals?",
      "answers": [
        "WSPR",
        "FSK441",
        "Hellschreiber",
        "APRS"
      ]
    },
    {
      "id": "E2D02",
      "correct": 3,
      "question": "Which of the following is a good technique for making meteor scatter contacts?",
      "answers": [
        "15 second timed transmission sequences with stations alternating based on location",
        "Use of high speed CW or digital modes",
        "Short transmission with rapidly repeated call signs and signal reports",
        "All of these choices are correct"
      ]
    },
    {
      "id": "E2D03",
      "correct": 3,
      "question": "Which of the following digital modes is especially useful for EME communications?",
      "answers": [
        "FSK441",
        "PACTOR III",
        "Olivia",
        "JT65"
      ]
    },
    {
      "id": "E2D04",
      "correct": 2,
      "question": "What is the purpose of digital store-and-forward functions on an Amateur Radio satellite?",
      "answers": [
        "To upload operational software for the transponder",
        "To delay download of telemetry between satellites",
        "To store digital messages in the satellite for later download by other stations",
        "To relay messages between satellites"
      ]
    },
    {
      "id": "E2D05",
      "correct": 1,
      "question": "Which of the following techniques is normally used by low Earth orbiting digital satellites to relay messages around the world?",
      "answers": [
        "Digipeating",
        "Store-and-forward",
        "Multi-satellite relaying",
        "Node hopping"
      ]
    },
    {
      "id": "E2D06",
      "correct": 0,
      "question": "Which of the following describes a method of establishing EME contacts?",
      "answers": [
        "Time synchronous transmissions alternately from each station",
        "Storing and forwarding digital messages",
        "Judging optimum transmission times by monitoring beacons reflected from the Moon",
        "High speed CW identification to avoid fading"
      ]
    },
    {
      "id": "E2D07",
      "correct": 2,
      "question": "What digital protocol is used by APRS?",
      "answers": [
        "PACTOR",
        "802.11",
        "AX.25",
        "AMTOR"
      ]
    },
    {
      "id": "E2D08",
      "correct": 0,
      "question": "What type of packet frame is used to transmit APRS beacon data?",
      "answers": [
        "Unnumbered Information",
        "Disconnect",
        "Acknowledgement",
        "Connect"
      ]
    },
    {
      "id": "E2D09",
      "correct": 3,
      "question": "Which of these digital modes has the fastest data throughput under clear communication conditions? ",
      "answers": [
        "AMTOR",
        "170 Hz shift, 45 baud RTTY",
        "PSK31",
        "300 baud packet"
      ]
    },
    {
      "id": "E2D10",
      "correct": 2,
      "question": "How can an APRS station be used to help support a public service communications activity?",
      "answers": [
        "An APRS station with an emergency medical technician can automatically transmit medical data to the nearest hospital",
        "APRS stations with General Personnel Scanners can automatically relay the participant numbers and time as they pass the check points",
        "An APRS station with a GPS unit can automatically transmit information to show a mobile station's position during the event",
        "All of these choices are correct"
      ]
    },
    {
      "id": "E2D11",
      "correct": 3,
      "question": "Which of the following data are used by the APRS network to communicate your location?",
      "answers": [
        "Polar coordinates",
        "Time and frequency",
        "Radio direction finding spectrum analysis",
        "Latitude and longitude"
      ]
    },
    {
      "id": "E2D12",
      "correct": 0,
      "question": "How does JT65 improve EME communications?",
      "answers": [
        "It can decode signals many dB below the noise floor using FEC",
        "It controls the receiver to track Doppler shift",
        "It supplies signals to guide the antenna to track the Moon",
        "All of these choices are correct"
      ]
    },
    {
      "id": "E2D13",
      "correct": 0,
      "question": "What type of modulation is used for JT65 contacts?",
      "answers": [
        "Multi-tone AFSK",
        "PSK",
        "RTTY",
        "IEEE 802.11"
      ]
    },
    {
      "id": "E2D14",
      "correct": 1,
      "question": "What is one advantage of using JT65 coding?",
      "answers": [
        "Uses only a 65 Hz bandwidth",
        "The ability to decode signals which have a very low signal to noise ratio",
        "Easily copied by ear if necessary",
        "Permits fast-scan TV transmissions over narrow bandwidth"
      ]
    },
    {
      "id": "E2E01",
      "correct": 1,
      "question": "Which type of modulation is common for data emissions below 30 MHz?",
      "answers": [
        "DTMF tones modulating an FM signal",
        "FSK",
        "Pulse modulation",
        "Spread spectrum"
      ]
    },
    {
      "id": "E2E02",
      "correct": 0,
      "question": "What do the letters FEC mean as they relate to digital operation?",
      "answers": [
        "Forward Error Correction",
        "First Error Correction",
        "Fatal Error Correction",
        "Final Error Correction"
      ]
    },
    {
      "id": "E2E03",
      "correct": 2,
      "question": "How is the timing of JT65 contacts organized?",
      "answers": [
        "By exchanging ACK/NAK packets",
        "Stations take turns on alternate days",
        "Alternating transmissions at 1 minute intervals",
        "It depends on the lunar phase"
      ]
    },
    {
      "id": "E2E04",
      "correct": 0,
      "question": "What is indicated when one of the ellipses in an FSK crossed-ellipse display suddenly disappears?",
      "answers": [
        "Selective fading has occurred",
        "One of the signal filters is saturated",
        "The receiver has drifted 5 kHz from the desired receive frequency",
        "The mark and space signal have been inverted"
      ]
    },
    {
      "id": "E2E05",
      "correct": 0,
      "question": "Which type of digital mode does not support keyboard-to-keyboard operation?",
      "answers": [
        "Winlink",
        "RTTY",
        "PSK31",
        "MFSK"
      ]
    },
    {
      "id": "E2E06",
      "correct": 2,
      "question": "What is the most common data rate used for HF packet?",
      "answers": [
        "48 baud",
        "110 baud",
        "300 baud",
        "1200 baud"
      ]
    },
    {
      "id": "E2E07",
      "correct": 1,
      "question": "What is the typical bandwidth of a properly modulated MFSK16 signal?",
      "answers": [
        "31 Hz",
        "316 Hz",
        "550 Hz",
        "2.16 kHz"
      ]
    },
    {
      "id": "E2E08",
      "correct": 1,
      "question": "Which of the following HF digital modes can be used to transfer binary files?",
      "answers": [
        "Hellschreiber",
        "PACTOR",
        "RTTY",
        "AMTOR"
      ]
    },
    {
      "id": "E2E09",
      "correct": 3,
      "question": "Which of the following HF digital modes uses variable-length coding for bandwidth efficiency?",
      "answers": [
        "RTTY",
        "PACTOR",
        "MT63",
        "PSK31"
      ]
    },
    {
      "id": "E2E10",
      "correct": 2,
      "question": "Which of these digital modes has the narrowest bandwidth?",
      "answers": [
        "MFSK16",
        "170 Hz shift, 45 baud RTTY",
        "PSK31",
        "300-baud packet"
      ]
    },
    {
      "id": "E2E11",
      "correct": 0,
      "question": "What is the difference between direct FSK and audio FSK?",
      "answers": [
        "Direct FSK applies the data signal to the transmitter VFO",
        "Audio FSK has a superior frequency response",
        "Direct FSK uses a DC-coupled data connection",
        "Audio FSK can be performed anywhere in the transmit chain"
      ]
    },
    {
      "id": "E2E12",
      "correct": 2,
      "question": "Which type of control is used by stations using the Automatic Link Enable (ALE) protocol?",
      "answers": [
        "Local",
        "Remote",
        "Automatic",
        "ALE can use any type of control"
      ]
    },
    {
      "id": "E2E13",
      "correct": 3,
      "question": "Which of the following is a possible reason that attempts to initiate contact with a digital station on a clear frequency are unsuccessful?",
      "answers": [
        "Your transmit frequency is incorrect",
        "The protocol version you are using is not the supported by the digital station",
        "Another station you are unable to hear is using the frequency",
        "All of these choices are correct"
      ]
    },
    {
      "id": "E3A01",
      "correct": 3,
      "question": "What is the approximate maximum separation measured along the surface of the Earth between two stations communicating by Moon bounce?",
      "answers": [
        "500 miles, if the Moon is at perigee",
        "2000 miles, if the Moon is at apogee",
        "5000 miles, if the Moon is at perigee",
        "12,000 miles, if the Moon is visible by both stations"
      ]
    },
    {
      "id": "E3A02",
      "correct": 1,
      "question": "What characterizes libration fading of an EME signal?",
      "answers": [
        "A slow change in the pitch of the CW signal",
        "A fluttery irregular fading",
        "A gradual loss of signal as the Sun rises",
        "The returning echo is several Hertz lower in frequency than the transmitted signal"
      ]
    },
    {
      "id": "E3A03",
      "correct": 0,
      "question": "When scheduling EME contacts, which of these conditions will generally result in the least path loss?",
      "answers": [
        "When the Moon is at perigee",
        "When the Moon is full",
        "When the Moon is at apogee",
        "When the MUF is above 30 MHz"
      ]
    },
    {
      "id": "E3A04",
      "correct": 3,
      "question": "What do Hepburn maps predict?",
      "answers": [
        "Sporadic E propagation",
        "Locations of auroral reflecting zones",
        "Likelihood of rain-scatter along cold or warm fronts",
        "Probability of tropospheric propagation"
      ]
    },
    {
      "id": "E3A05",
      "correct": 2,
      "question": "Tropospheric propagation of microwave signals often occurs along what weather related structure?",
      "answers": [
        "Gray-line",
        "Lightning discharges",
        "Warm and cold fronts",
        "Sprites and jets"
      ]
    },
    {
      "id": "E3A06",
      "correct": 2,
      "question": "Which of the following is required for microwave propagation via rain scatter?",
      "answers": [
        "Rain droplets must be electrically charged",
        "Rain droplets must be within the E layer",
        "The rain must be within radio range of both stations",
        "All of these choices are correct"
      ]
    },
    {
      "id": "E3A07",
      "correct": 2,
      "question": "Atmospheric ducts capable of propagating microwave signals often form over what geographic feature?",
      "answers": [
        "Mountain ranges",
        "Forests",
        "Bodies of water",
        "Urban areas"
      ]
    },
    {
      "id": "E3A08",
      "correct": 0,
      "question": "When a meteor strikes the Earth's atmosphere, a cylindrical region of free electrons is formed at what layer of the ionosphere?",
      "answers": [
        "The E layer",
        "The F1 layer",
        "The F2 layer",
        "The D layer"
      ]
    },
    {
      "id": "E3A09",
      "correct": 2,
      "question": "Which of the following frequency range is most suited for meteor scatter communications?",
      "answers": [
        "1.8 MHz - 1.9 MHz",
        "10 MHz - 14 MHz",
        "28 MHz - 148 MHz",
        "220 MHz - 450 MHz"
      ]
    },
    {
      "id": "E3A10",
      "correct": 1,
      "question": "Which type of atmospheric structure can create a path for microwave propagation?",
      "answers": [
        "The jet stream",
        "Temperature inversion",
        "Wind shear",
        "Dust devil"
      ]
    },
    {
      "id": "E3A11",
      "correct": 1,
      "question": "What is a typical range for tropospheric propagation of microwave signals?",
      "answers": [
        "10 miles to 50 miles",
        "100 miles to 300 miles",
        "1200 miles",
        "2500 miles"
      ]
    },
    {
      "id": "E3A12",
      "correct": 2,
      "question": "What is the cause of auroral activity?",
      "answers": [
        "The interaction in the F2 layer between the solar wind and the Van Allen belt",
        "A low sunspot level combined with tropospheric ducting",
        "The interaction in the E layer of charged particles from the Sun with the Earth’s magnetic field",
        "Meteor showers concentrated in the extreme northern and southern latitudes"
      ]
    },
    {
      "id": "E3A13",
      "correct": 0,
      "question": "Which emission mode is best for aurora propagation?",
      "answers": [
        "CW",
        "SSB",
        "FM",
        "RTTY"
      ]
    },
    {
      "id": "E3A14",
      "correct": 1,
      "question": "From the contiguous 48 states, in which approximate direction should an antenna be pointed to take maximum advantage of aurora propagation?",
      "answers": [
        "South",
        "North",
        "East",
        "West"
      ]
    },
    {
      "id": "E3A15",
      "correct": 2,
      "question": "What is an electromagnetic wave?",
      "answers": [
        "A wave of alternating current, in the core of an electromagnet",
        "A wave consisting of two electric fields at parallel right angles to each other",
        "A wave consisting of an electric field and a magnetic field oscillating at right angles to each other",
        "A wave consisting of two magnetic fields at right angles to each other"
      ]
    },
    {
      "id": "E3A16",
      "correct": 3,
      "question": "Which of the following best describes electromagnetic waves traveling in free space?",
      "answers": [
        "Electric and magnetic fields become aligned as they travel",
        "The energy propagates through a medium with a high refractive index",
        "The waves are reflected by the ionosphere and return to their source",
        "Changing electric and magnetic fields propagate the energy"
      ]
    },
    {
      "id": "E3A17",
      "correct": 1,
      "question": "What is meant by circularly polarized electromagnetic waves?",
      "answers": [
        "Waves with an electric field bent into a circular shape",
        "Waves with a rotating electric field",
        "Waves that circle the Earth",
        "Waves produced by a loop antenna"
      ]
    },
    {
      "id": "E3B01",
      "correct": 0,
      "question": "What is transequatorial propagation?",
      "answers": [
        "Propagation between two mid-latitude points at approximately the same distance north and south of the magnetic equator",
        "Propagation between any two points located on the magnetic equator",
        "Propagation between two continents by way of ducts along the magnetic equator",
        "Propagation between two stations at the same latitude"
      ]
    },
    {
      "id": "E3B02",
      "correct": 2,
      "question": "What is the approximate maximum range for signals using transequatorial propagation?",
      "answers": [
        "1000 miles",
        "2500 miles",
        "5000 miles",
        "7500 miles"
      ]
    },
    {
      "id": "E3B03",
      "correct": 2,
      "question": "What is the best time of day for transequatorial propagation?",
      "answers": [
        "Morning",
        "Noon",
        "Afternoon or early evening",
        "Late at night"
      ]
    },
    {
      "id": "E3B04",
      "correct": 1,
      "question": "What is meant by the terms extraordinary and ordinary waves?",
      "answers": [
        "Extraordinary waves describe rare long skip propagation compared to ordinary waves which travel shorter distances",
        "Independent waves created in the ionosphere that are elliptically polarized",
        "Long path and short path waves",
        "Refracted rays and reflected waves"
      ]
    },
    {
      "id": "E3B05",
      "correct": 2,
      "question": "Which amateur bands typically support long-path propagation?",
      "answers": [
        "160 meters to 40 meters",
        "30 meters to 10 meters",
        "160 meters to 10 meters",
        "6 meters to 2 meters"
      ]
    },
    {
      "id": "E3B06",
      "correct": 1,
      "question": "Which of the following amateur bands most frequently provides long-path propagation?",
      "answers": [
        "80 meters",
        "20 meters",
        "10 meters",
        "6 meters"
      ]
    },
    {
      "id": "E3B07",
      "correct": 3,
      "question": "Which of the following could account for hearing an echo on the received signal of a distant station?",
      "answers": [
        "High D layer absorption",
        "Meteor scatter",
        "Transmit frequency is higher than the MUF",
        "Receipt of a signal by more than one path"
      ]
    },
    {
      "id": "E3B08",
      "correct": 3,
      "question": "What type of HF propagation is probably occurring if radio signals travel along the terminator between daylight and darkness?",
      "answers": [
        "Transequatorial",
        "Sporadic-E",
        "Long-path",
        "Gray-line"
      ]
    },
    {
      "id": "E3B09",
      "correct": 0,
      "question": "At what time of year is Sporadic E propagation most likely to occur?",
      "answers": [
        "Around the solstices, especially the summer solstice",
        "Around the solstices, especially the winter solstice",
        "Around the equinoxes, especially the spring equinox",
        "Around the equinoxes, especially the fall equinox"
      ]
    },
    {
      "id": "E3B10",
      "correct": 1,
      "question": "What is the cause of gray-line propagation?",
      "answers": [
        "At midday, the Sun super heats the ionosphere causing increased refraction of radio waves",
        "At twilight and sunrise, D-layer absorption is low while E-layer and F-layer propagation remains high",
        "In darkness, solar absorption drops greatly while atmospheric ionization remains steady",
        "At mid-afternoon, the Sun heats the ionosphere decreasing radio wave refraction and the MUF"
      ]
    },
    {
      "id": "E3B11",
      "correct": 3,
      "question": "At what time of day is Sporadic-E propagation most likely to occur?",
      "answers": [
        "Around sunset",
        "Around sunrise",
        "Early evening",
        "Any time"
      ]
    },
    {
      "id": "E3B12",
      "correct": 1,
      "question": "What is the primary characteristic of chordal hop propagation?",
      "answers": [
        "Propagation away from the great circle bearing between stations",
        "Successive ionospheric reflections without an intermediate reflection from the ground",
        "Propagation across the geomagnetic equator",
        "Signals reflected back toward the transmitting station"
      ]
    },
    {
      "id": "E3B13",
      "correct": 0,
      "question": "Why is chordal hop propagation desirable?",
      "answers": [
        "The signal experiences less loss along the path compared to normal skip propagation",
        "The MUF for chordal hop propagation is much lower than for normal skip propagation",
        "Atmospheric noise is lower in the direction of chordal hop propagation",
        "Signals travel faster along ionospheric chords"
      ]
    },
    {
      "id": "E3B14",
      "correct": 2,
      "question": "What happens to linearly polarized radio waves that split into ordinary and extraordinary waves in the ionosphere?",
      "answers": [
        "They are bent toward the magnetic poles",
        "Their polarization is randomly modified",
        "They become elliptically polarized",
        "They become phase-locked"
      ]
    },
    {
      "id": "E3C01",
      "correct": 1,
      "question": "What does the term ray tracing describe in regard to radio communications?",
      "answers": [
        "The process in which an electronic display presents a pattern",
        "Modeling a radio wave's path through the ionosphere",
        "Determining the radiation pattern from an array of antennas",
        "Evaluating high voltage sources for X-Rays"
      ]
    },
    {
      "id": "E3C02",
      "correct": 0,
      "question": "What is indicated by a rising A or K index?",
      "answers": [
        "Increasing disruption of the geomagnetic field",
        "Decreasing disruption of the geomagnetic field",
        "Higher levels of solar UV radiation",
        "An increase in the critical frequency"
      ]
    },
    {
      "id": "E3C03",
      "correct": 1,
      "question": "Which of the following signal paths is most likely to experience high levels of absorption when the A index or K index is elevated?",
      "answers": [
        "Transequatorial propagation",
        "Polar paths",
        "Sporadic-E",
        "NVIS"
      ]
    },
    {
      "id": "E3C04",
      "correct": 2,
      "question": "What does the value of Bz (B sub Z) represent?",
      "answers": [
        "Geomagnetic field stability",
        "Critical frequency for vertical transmissions",
        "Direction and strength of the interplanetary magnetic field",
        "Duration of long-delayed echoes"
      ]
    },
    {
      "id": "E3C05",
      "correct": 0,
      "question": "What orientation of Bz (B sub z) increases the likelihood that incoming particles from the Sun will cause disturbed conditions?",
      "answers": [
        "Southward",
        "Northward",
        "Eastward",
        "Westward"
      ]
    },
    {
      "id": "E3C06",
      "correct": 0,
      "question": "By how much does the VHF/UHF radio horizon distance exceed the geometric horizon?",
      "answers": [
        "By approximately 15 percent of the distance",
        "By approximately twice the distance",
        "By approximately 50 percent of the distance",
        "By approximately four times the distance"
      ]
    },
    {
      "id": "E3C07",
      "correct": 3,
      "question": "Which of the following descriptors indicates the greatest solar flare intensity?",
      "answers": [
        "Class A",
        "Class B",
        "Class M",
        "Class X"
      ]
    },
    {
      "id": "E3C08",
      "correct": 0,
      "question": "What does the space weather term G5 mean?",
      "answers": [
        "An extreme geomagnetic storm",
        "Very low solar activity",
        "Moderate solar wind",
        "Waning sunspot numbers"
      ]
    },
    {
      "id": "E3C09",
      "correct": 2,
      "question": "How does the intensity of an X3 flare compare to that of an X2 flare?",
      "answers": [
        "10 percent greater",
        "50 percent greater",
        "Twice as great",
        "Four times as great"
      ]
    },
    {
      "id": "E3C10",
      "correct": 1,
      "question": "What does the 304A solar parameter measure?",
      "answers": [
        "The ratio of X-Ray flux to radio flux, correlated to sunspot number",
        "UV emissions at 304 angstroms, correlated to solar flux index",
        "The solar wind velocity at 304 degrees from the solar equator, correlated to solar activity",
        "The solar emission at 304 GHz, correlated to X-Ray flare levels"
      ]
    },
    {
      "id": "E3C11",
      "correct": 2,
      "question": "What does VOACAP software model?",
      "answers": [
        "AC voltage and impedance",
        "VHF radio propagation",
        "HF propagation",
        "AC current and impedance"
      ]
    },
    {
      "id": "E3C12",
      "correct": 2,
      "question": "How does the maximum distance of ground-wave propagation change when the signal frequency is increased?",
      "answers": [
        "It stays the same",
        "It increases",
        "It decreases",
        "It peaks at roughly 14 MHz"
      ]
    },
    {
      "id": "E3C13",
      "correct": 0,
      "question": "What type of polarization is best for ground-wave propagation?",
      "answers": [
        "Vertical",
        "Horizontal",
        "Circular",
        "Elliptical"
      ]
    },
    {
      "id": "E3C14",
      "correct": 3,
      "question": "Why does the radio-path horizon distance exceed the geometric horizon?",
      "answers": [
        "E-region skip",
        "D-region skip",
        "Downward bending due to aurora refraction",
        "Downward bending due to density variations in the atmosphere"
      ]
    },
    {
      "id": "E3C15",
      "correct": 1,
      "question": "What might a sudden rise in radio background noise indicate?",
      "answers": [
        "A meteor ping",
        "A solar flare has occurred",
        "Increased transequatorial propagation likely",
        "Long-path propagation is occurring"
      ]
    },
    {
      "id": "E4A01",
      "correct": 2,
      "question": "Which of the following parameter determines the bandwidth of a digital or computer-based oscilloscope?",
      "answers": [
        "Input capacitance",
        "Input impedance",
        "Sampling rate",
        "Sample resolution"
      ]
    },
    {
      "id": "E4A02",
      "correct": 1,
      "question": "Which of the following parameters would a spectrum analyzer display on the vertical and horizontal axes?",
      "answers": [
        "RF amplitude and time",
        "RF amplitude and frequency",
        "SWR and frequency",
        "SWR and time"
      ]
    },
    {
      "id": "E4A03",
      "correct": 1,
      "question": "Which of the following test instrument is used to display spurious signals and/or intermodulation distortion products in an SSB transmitter?",
      "answers": [
        "A wattmeter",
        "A spectrum analyzer",
        "A logic analyzer",
        "A time-domain reflectometer"
      ]
    },
    {
      "id": "E4A04",
      "correct": 0,
      "question": "What determines the upper frequency limit for a computer soundcard-based oscilloscope program?",
      "answers": [
        "Analog-to-digital conversion speed of the soundcard",
        "Amount of memory on the soundcard",
        "Q of the interface of the interface circuit",
        "All of these choices are correct"
      ]
    },
    {
      "id": "E4A05",
      "correct": 3,
      "question": "What might be an advantage of a digital vs an analog oscilloscope?",
      "answers": [
        "Automatic amplitude and frequency numerical readout",
        "Storage of traces for future reference",
        "Manipulation of time base after trace capture",
        "All of these choices are correct"
      ]
    },
    {
      "id": "E4A06",
      "correct": 0,
      "question": "What is the effect of aliasing in a digital or computer-based oscilloscope?",
      "answers": [
        "False signals are displayed",
        "All signals will have a DC offset",
        "Calibration of the vertical scale is no longer valid",
        "False triggering occurs"
      ]
    },
    {
      "id": "E4A07",
      "correct": 1,
      "question": "Which of the following is an advantage of using an antenna analyzer compared to an SWR bridge to measure antenna SWR?",
      "answers": [
        "Antenna analyzers automatically tune your antenna for resonance",
        "Antenna analyzers do not need an external RF source",
        "Antenna analyzers display a time-varying representation of the modulation envelope",
        "All of these choices are correct"
      ]
    },
    {
      "id": "E4A08",
      "correct": 3,
      "question": "Which of the following instrument would be best for measuring the SWR of a beam antenna?",
      "answers": [
        "A spectrum analyzer",
        "A Q meter",
        "An ohmmeter",
        "An antenna analyzer"
      ]
    },
    {
      "id": "E4A09",
      "correct": 1,
      "question": "When using a computer’s soundcard input to digitize signals, what is the highest frequency signal that can be digitized without aliasing?",
      "answers": [
        "The same as the sample rate",
        "One-half the sample rate",
        "One-tenth the sample rate",
        "It depends on how the data is stored internally"
      ]
    },
    {
      "id": "E4A10",
      "correct": 3,
      "question": "Which of the following displays multiple digital signal states simultaneously?",
      "answers": [
        "Network analyzer",
        "Bit error rate tester",
        "Modulation monitor",
        "Logic analyzer"
      ]
    },
    {
      "id": "E4A11",
      "correct": 0,
      "question": "Which of the following is good practice when using an oscilloscope probe?",
      "answers": [
        "Keep the signal ground connection of the probe as short as possible",
        "Never use a high impedance probe to measure a low impedance circuit",
        "Never use a DC-coupled probe to measure an AC circuit",
        "All of these choices are correct"
      ]
    },
    {
      "id": "E4A12",
      "correct": 1,
      "question": "Which of the following procedures is an important precaution to follow when connecting a spectrum analyzer to a transmitter output?",
      "answers": [
        "Use high quality double shielded coaxial cables to reduce signal losses",
        "Attenuate the transmitter output going to the spectrum analyzer",
        "Match the antenna to the load",
        "All of these choices are correct"
      ]
    },
    {
      "id": "E4A13",
      "correct": 0,
      "question": "How is the compensation of an oscilloscope probe typically adjusted?",
      "answers": [
        "A square wave is displayed and the probe is adjusted until the horizontal portions of the displayed wave are as nearly flat as possible",
        "A high frequency sine wave is displayed and the probe is adjusted for maximum amplitude",
        "A frequency standard is displayed and the probe is adjusted until the deflection time is accurate",
        "A DC voltage standard is displayed and the probe is adjusted until the displayed voltage is accurate"
      ]
    },
    {
      "id": "E4A14",
      "correct": 3,
      "question": "What is the purpose of the prescaler function on a frequency counter?",
      "answers": [
        "It amplifies low level signals for more accurate counting",
        "It multiplies a higher frequency signal so a low-frequency counter can display the operating frequency",
        "It prevents oscillation in a low-frequency counter circuit",
        "It divides a higher frequency signal so a low-frequency counter can display the input frequency"
      ]
    },
    {
      "id": "E4A15",
      "correct": 2,
      "question": "What is an advantage of a period-measuring frequency counter over a direct-count type?",
      "answers": [
        "It can run on battery power for remote measurements",
        "It does not require an expensive high-precision time base",
        "It provides improved resolution of low-frequency signals within a comparable time period",
        "It can directly measure the modulation index of an FM transmitter"
      ]
    },
    {
      "id": "E4B01",
      "correct": 1,
      "question": "Which of the following factors most affects the accuracy of a frequency counter?",
      "answers": [
        "Input attenuator accuracy",
        "Time base accuracy",
        "Decade divider accuracy",
        "Temperature coefficient of the logic"
      ]
    },
    {
      "id": "E4B02",
      "correct": 2,
      "question": "What is an advantage of using a bridge circuit to measure impedance?",
      "answers": [
        "It provides an excellent match under all conditions",
        "It is relatively immune to drift in the signal generator source",
        "It is very precise in obtaining a signal null",
        "It can display results directly in Smith chart format"
      ]
    },
    {
      "id": "E4B03",
      "correct": 2,
      "question": "If a frequency counter with a specified accuracy of +/- 1.0 ppm reads 146,520,000 Hz, what is the most the actual frequency being measured could differ from the reading?",
      "answers": [
        "165.2 Hz",
        "14.652 kHz",
        "146.52 Hz",
        "1.4652 MHz"
      ]
    },
    {
      "id": "E4B04",
      "correct": 0,
      "question": "If a frequency counter with a specified accuracy of +/- 0.1 ppm reads 146,520,000 Hz, what is the most the actual frequency being measured could differ from the reading?",
      "answers": [
        "14.652 Hz",
        "0.1 MHz",
        "1.4652 Hz",
        "1.4652 kHz"
      ]
    },
    {
      "id": "E4B05",
      "correct": 3,
      "question": "If a frequency counter with a specified accuracy of +/- 10 ppm reads 146,520,000 Hz, what is the most the actual frequency being measured could differ from the reading?",
      "answers": [
        "146.52 Hz",
        "10 Hz",
        "146.52 kHz",
        "1465.20 Hz"
      ]
    },
    {
      "id": "E4B06",
      "correct": 3,
      "question": "How much power is being absorbed by the load when a directional power meter connected between a transmitter and a terminating load reads 100 watts forward power and 25 watts reflected power?",
      "answers": [
        "100 watts",
        "125 watts",
        "25 watts",
        "75 watts"
      ]
    },
    {
      "id": "E4B07",
      "correct": 0,
      "question": "What do the subscripts of S parameters represent?",
      "answers": [
        "The port or ports at which measurements are made",
        "The relative time between measurements",
        "Relative quality of the data",
        "Frequency order of the measurements"
      ]
    },
    {
      "id": "E4B08",
      "correct": 2,
      "question": "Which of the following is a characteristic of a good DC voltmeter?",
      "answers": [
        "High reluctance input",
        "Low reluctance input",
        "High impedance input",
        "Low impedance input"
      ]
    },
    {
      "id": "E4B09",
      "correct": 3,
      "question": "What is indicated if the current reading on an RF ammeter placed in series with the antenna feed line of a transmitter increases as the transmitter is tuned to resonance?",
      "answers": [
        "There is possibly a short to ground in the feed line",
        "The transmitter is not properly neutralized",
        "There is an impedance mismatch between the antenna and feed line",
        "There is more power going into the antenna"
      ]
    },
    {
      "id": "E4B10",
      "correct": 1,
      "question": "Which of the following describes a method to measure intermodulation distortion in an SSB transmitter?",
      "answers": [
        "Modulate the transmitter with two non-harmonically related radio frequencies and observe the RF output with a spectrum analyzer",
        "Modulate the transmitter with two non-harmonically related audio frequencies and observe the RF output with a spectrum analyzer",
        "Modulate the transmitter with two harmonically related audio frequencies and observe the RF output with a peak reading wattmeter",
        "Modulate the transmitter with two harmonically related audio frequencies and observe the RF output with a logic analyzer"
      ]
    },
    {
      "id": "E4B11",
      "correct": 3,
      "question": "How should an antenna analyzer be connected when measuring antenna resonance and feed point impedance?",
      "answers": [
        "Loosely couple the analyzer near the antenna base",
        "Connect the analyzer via a high-impedance transformer to the antenna",
        "Loosely couple the antenna and a dummy load to the analyzer",
        "Connect the antenna feed line directly to the analyzer's connector"
      ]
    },
    {
      "id": "E4B12",
      "correct": 0,
      "question": "What is the significance of voltmeter sensitivity expressed in ohms per volt?",
      "answers": [
        "The full scale reading of the voltmeter multiplied by its ohms per volt rating will indicate the input impedance of the voltmeter",
        "When used as a galvanometer, the reading in volts multiplied by the ohms per volt rating will determine the power drawn by the device under test",
        "When used as an ohmmeter, the reading in ohms divided by the ohms per volt rating will determine the voltage applied to the circuit",
        "When used as an ammeter, the full scale reading in amps divided by ohms per volt rating will determine the size of shunt needed"
      ]
    },
    {
      "id": "E4B13",
      "correct": 2,
      "question": "Which S parameter is equivalent to forward gain?",
      "answers": [
        "S11",
        "S12",
        "S21",
        "S22"
      ]
    },
    {
      "id": "E4B14",
      "correct": 1,
      "question": "What happens if a dip meter is too tightly coupled to a tuned circuit being checked?",
      "answers": [
        "Harmonics are generated",
        "A less accurate reading results",
        "Cross modulation occurs",
        "Intermodulation distortion occurs"
      ]
    },
    {
      "id": "E4B15",
      "correct": 2,
      "question": "Which of the following can be used as a relative measurement of the Q for a series-tuned circuit?",
      "answers": [
        "The inductance to capacitance ratio",
        "The frequency shift",
        "The bandwidth of the circuit's frequency response",
        "The resonant frequency of the circuit"
      ]
    },
    {
      "id": "E4B16",
      "correct": 0,
      "question": "Which S parameter represents return loss or SWR?",
      "answers": [
        "S11",
        "S12",
        "S21",
        "S22"
      ]
    },
    {
      "id": "E4B17",
      "correct": 1,
      "question": "What three test loads are used to calibrate a standard RF vector network analyzer?",
      "answers": [
        "50 ohms, 75 ohms, and 90 ohms",
        "Short circuit, open circuit, and 50 ohms",
        "Short circuit, open circuit, and resonant circuit",
        "50 ohms through 1/8 wavelength, 1/4 wavelength, and 1/2 wavelength of coaxial cable"
      ]
    },
    {
      "id": "E4C01",
      "correct": 3,
      "question": "What is an effect of excessive phase noise in the local oscillator section of a receiver?",
      "answers": [
        "It limits the receiver’s ability to receive strong signals",
        "It reduces receiver sensitivity",
        "It decreases receiver third-order intermodulation distortion dynamic range",
        "It can cause strong signals on nearby frequencies to interfere with reception of weak signals"
      ]
    },
    {
      "id": "E4C02",
      "correct": 0,
      "question": "Which of the following portions of a receiver can be effective in eliminating image signal interference?",
      "answers": [
        "A front-end filter or pre-selector",
        "A narrow IF filter",
        "A notch filter",
        "A properly adjusted product detector"
      ]
    },
    {
      "id": "E4C03",
      "correct": 2,
      "question": "What is the term for the blocking of one FM phone signal by another, stronger FM phone signal?",
      "answers": [
        "Desensitization",
        "Cross-modulation interference",
        "Capture effect",
        "Frequency discrimination"
      ]
    },
    {
      "id": "E4C04",
      "correct": 3,
      "question": "How is the noise figure of a receiver defined?",
      "answers": [
        "The ratio of atmospheric noise to phase noise",
        "The ratio of the noise bandwidth in Hertz to the theoretical bandwidth of a resistive network",
        "The ratio of thermal noise to atmospheric noise",
        "The ratio in dB of the noise generated by the receiver to the theoretical minimum noise"
      ]
    },
    {
      "id": "E4C05",
      "correct": 1,
      "question": "What does a value of -174 dBm/Hz represent with regard to the noise floor of a receiver?",
      "answers": [
        "The minimum detectable signal as a function of receive frequency",
        "The theoretical noise at the input of a perfect receiver at room temperature",
        "The noise figure of a 1 Hz bandwidth receiver",
        "The galactic noise contribution to minimum detectable signal"
      ]
    },
    {
      "id": "E4C06",
      "correct": 3,
      "question": "A CW receiver with the AGC off has an equivalent input noise power density of -174 dBm/Hz.  What would be the level of an unmodulated carrier input to this receiver that would yield an audio output SNR of 0 dB in a 400 Hz noise bandwidth?",
      "answers": [
        "-174 dBm",
        "-164 dBm",
        "-155 dBm",
        "-148 dBm"
      ]
    },
    {
      "id": "E4C07",
      "correct": 1,
      "question": "What does the MDS of a receiver represent?",
      "answers": [
        "The meter display sensitivity",
        "The minimum discernible signal",
        "The multiplex distortion stability",
        "The maximum detectable spectrum"
      ]
    },
    {
      "id": "E4C08",
      "correct": 2,
      "question": "An SDR receiver is overloaded when input signals exceed what level?",
      "answers": [
        "One-half the maximum sample rate",
        "One-half the maximum sampling buffer size",
        "The maximum count value of the analog-to-digital converter",
        "The reference voltage of the analog-to-digital converter"
      ]
    },
    {
      "id": "E4C09",
      "correct": 2,
      "question": "Which of the following choices is a good reason for selecting a high frequency for the design of the IF in a conventional HF or VHF communications receiver?",
      "answers": [
        "Fewer components in the receiver",
        "Reduced drift",
        "Easier for front-end circuitry to eliminate image responses",
        "Improved receiver noise figure"
      ]
    },
    {
      "id": "E4C10",
      "correct": 1,
      "question": "Which of the following is a desirable amount of selectivity for an amateur RTTY HF receiver?",
      "answers": [
        "100 Hz",
        "300 Hz",
        "6000 Hz",
        "2400 Hz"
      ]
    },
    {
      "id": "E4C11",
      "correct": 1,
      "question": "Which of the following is a desirable amount of selectivity for an amateur SSB phone receiver?",
      "answers": [
        "1 kHz",
        "2.4 kHz",
        "4.2 kHz",
        "4.8 kHz"
      ]
    },
    {
      "id": "E4C12",
      "correct": 3,
      "question": "What is an undesirable effect of using too wide a filter bandwidth in the IF section of a receiver?",
      "answers": [
        "Output-offset overshoot",
        "Filter ringing",
        "Thermal-noise distortion",
        "Undesired signals may be heard"
      ]
    },
    {
      "id": "E4C13",
      "correct": 2,
      "question": "How does a narrow-band roofing filter affect receiver performance?",
      "answers": [
        "It improves sensitivity by reducing front end noise",
        "It improves intelligibility by using low Q circuitry to reduce ringing",
        "It improves dynamic range by attenuating strong signals near the receive frequency",
        "All of these choices are correct"
      ]
    },
    {
      "id": "E4C14",
      "correct": 3,
      "question": "What transmit frequency might generate an image response signal in a receiver tuned to 14.300 MHz and which uses a 455 kHz IF frequency?",
      "answers": [
        "13.845 MHz",
        "14.755 MHz",
        "14.445 MHz",
        "15.210 MHz"
      ]
    },
    {
      "id": "E4C15",
      "correct": 3,
      "question": "What is usually the primary source of noise that is heard from an HF receiver with an antenna connected?",
      "answers": [
        "Detector noise",
        "Induction motor noise",
        "Receiver front-end noise",
        "Atmospheric noise"
      ]
    },
    {
      "id": "E4C16",
      "correct": 0,
      "question": "Which of the following is caused by missing codes in an SDR receiver’s analog-to-digital converter?",
      "answers": [
        "Distortion",
        "Overload",
        "Loss of sensitivity",
        "Excess output level"
      ]
    },
    {
      "id": "E4C17",
      "correct": 3,
      "question": "Which of the following has the largest effect on an SDR receiver’s linearity?",
      "answers": [
        "CPU register width in bits",
        "Anti-aliasing input filter bandwidth",
        "RAM speed used for data storage",
        "Analog-to-digital converter sample width in bits"
      ]
    },
    {
      "id": "E4D01",
      "correct": 0,
      "question": "What is meant by the blocking dynamic range of a receiver?",
      "answers": [
        "The difference in dB between the noise floor and the level of an incoming signal which will cause 1 dB of gain compression",
        "The minimum difference in dB between the levels of two FM signals which will cause one signal to block the other",
        "The difference in dB between the noise floor and the third order intercept point",
        "The minimum difference in dB between two signals which produce third order intermodulation products greater than the noise floor"
      ]
    },
    {
      "id": "E4D02",
      "correct": 0,
      "question": "Which of the following describes two problems caused by poor dynamic range in a communications receiver?",
      "answers": [
        "Cross-modulation of the desired signal and desensitization from strong adjacent signals",
        "Oscillator instability requiring frequent retuning and loss of ability to recover the opposite sideband",
        "Cross-modulation of the desired signal and insufficient audio power to operate the speaker",
        "Oscillator instability and severe audio distortion of all but the strongest received signals"
      ]
    },
    {
      "id": "E4D03",
      "correct": 1,
      "question": "How can intermodulation interference between two repeaters occur?",
      "answers": [
        "When the repeaters are in close proximity and the signals cause feedback in the final amplifier of one or both transmitters",
        "When the repeaters are in close proximity and the signals mix in the final amplifier of one or both transmitters",
        "When the signals from the transmitters are reflected out of phase from airplanes passing overhead",
        "When the signals from the transmitters are reflected in phase from airplanes passing overhead"
      ]
    },
    {
      "id": "E4D04",
      "correct": 1,
      "question": "Which of the following may reduce or eliminate intermodulation interference in a repeater caused by another transmitter operating in close proximity?",
      "answers": [
        "A band-pass filter in the feed line between the transmitter and receiver",
        "A properly terminated circulator at the output of the transmitter",
        "A Class C final amplifier",
        "A Class D final amplifier"
      ]
    },
    {
      "id": "E4D05",
      "correct": 0,
      "question": "What transmitter frequencies would cause an intermodulation-product signal in a receiver tuned to 146.70 MHz when a nearby station transmits on 146.52 MHz?",
      "answers": [
        "146.34 MHz and 146.61 MHz",
        "146.88 MHz and 146.34 MHz",
        "146.10 MHz and 147.30 MHz",
        "173.35 MHz and 139.40 MHz"
      ]
    },
    {
      "id": "E4D06",
      "correct": 3,
      "question": "What is the term for unwanted signals generated by the mixing of two or more signals?",
      "answers": [
        "Amplifier desensitization",
        "Neutralization",
        "Adjacent channel interference",
        "Intermodulation interference"
      ]
    },
    {
      "id": "E4D07",
      "correct": 3,
      "question": "Which describes the most significant effect of an off-frequency signal when it is causing cross-modulation interference to a desired signal?",
      "answers": [
        "A large increase in background noise",
        "A reduction in apparent signal strength",
        "The desired signal can no longer be heard",
        "The off-frequency unwanted signal is heard in addition to the desired signal"
      ]
    },
    {
      "id": "E4D08",
      "correct": 2,
      "question": "What causes intermodulation in an electronic circuit?",
      "answers": [
        "Too little gain",
        "Lack of neutralization",
        "Nonlinear circuits or devices",
        "Positive feedback"
      ]
    },
    {
      "id": "E4D09",
      "correct": 2,
      "question": "What is the purpose of the preselector in a communications receiver?",
      "answers": [
        "To store often-used frequencies",
        "To provide a range of AGC time constants",
        "To increase rejection of unwanted signals",
        "To allow selection of the optimum RF amplifier device"
      ]
    },
    {
      "id": "E4D10",
      "correct": 2,
      "question": "What does a third-order intercept level of 40 dBm mean with respect to receiver performance?",
      "answers": [
        "Signals less than 40 dBm will not generate audible third-order intermodulation products",
        "The receiver can tolerate signals up to 40 dB above the noise floor without producing third-order intermodulation products",
        "A pair of 40 dBm signals will theoretically generate a third-order intermodulation product with the same level as the input signals",
        "A pair of 1 mW input signals will produce a third-order intermodulation product which is 40 dB stronger than the input signal"
      ]
    },
    {
      "id": "E4D11",
      "correct": 0,
      "question": "Why are third-order intermodulation products created within a receiver of particular interest compared to other products?",
      "answers": [
        "The third-order product of two signals which are in the band of interest is also likely to be within the band",
        "The third-order intercept is much higher than other orders",
        "Third-order products are an indication of poor image rejection",
        "Third-order intermodulation produces three products for every input signal within the band of interest"
      ]
    },
    {
      "id": "E4D12",
      "correct": 0,
      "question": "What is the term for the reduction in receiver sensitivity caused by a strong signal near the received frequency?",
      "answers": [
        "Desensitization",
        "Quieting",
        "Cross-modulation interference",
        "Squelch gain rollback"
      ]
    },
    {
      "id": "E4D13",
      "correct": 1,
      "question": "Which of the following can cause receiver desensitization?",
      "answers": [
        "Audio gain adjusted too low",
        "Strong adjacent channel signals",
        "Audio bias adjusted too high",
        "Squelch gain misadjusted"
      ]
    },
    {
      "id": "E4D14",
      "correct": 0,
      "question": "Which of the following is a way to reduce the likelihood of receiver desensitization?",
      "answers": [
        "Decrease the RF bandwidth of the receiver",
        "Raise the receiver IF frequency",
        "Increase the receiver front end gain",
        "Switch from fast AGC to slow AGC"
      ]
    },
    {
      "id": "E4E01",
      "correct": 0,
      "question": "Which of the following types of receiver noise can often be reduced by use of a receiver noise blanker?",
      "answers": [
        "Ignition noise",
        "Broadband white noise",
        "Heterodyne interference",
        "All of these choices are correct"
      ]
    },
    {
      "id": "E4E02",
      "correct": 3,
      "question": "Which of the following types of receiver noise can often be reduced with a DSP noise filter?",
      "answers": [
        "Broadband white noise",
        "Ignition noise",
        "Power line noise",
        "All of these choices are correct"
      ]
    },
    {
      "id": "E4E03",
      "correct": 1,
      "question": "Which of the following signals might a receiver noise blanker be able to remove from desired signals?",
      "answers": [
        "Signals which are constant at all IF levels",
        "Signals which appear across a wide bandwidth",
        "Signals which appear at one IF but not another",
        "Signals which have a sharply peaked frequency distribution"
      ]
    },
    {
      "id": "E4E04",
      "correct": 3,
      "question": "How can conducted and radiated noise caused by an automobile alternator be suppressed?",
      "answers": [
        "By installing filter capacitors in series with the DC power lead and a blocking capacitor in the field lead",
        "By installing a noise suppression resistor and a blocking capacitor in both leads",
        "By installing a high-pass filter in series with the radio's power lead and a low-pass filter in parallel with the field lead",
        "By connecting the radio's power leads directly to the battery and by installing coaxial capacitors in line with the alternator leads"
      ]
    },
    {
      "id": "E4E05",
      "correct": 1,
      "question": "How can noise from an electric motor be suppressed?",
      "answers": [
        "By installing a high pass filter in series with the motor’s power leads",
        "By installing a brute-force AC-line filter in series with the motor leads",
        "By installing a bypass capacitor in series with the motor leads",
        "By using a ground-fault current interrupter in the circuit used to power the motor"
      ]
    },
    {
      "id": "E4E06",
      "correct": 1,
      "question": "What is a major cause of atmospheric static?",
      "answers": [
        "Solar radio frequency emissions",
        "Thunderstorms",
        "Geomagnetic storms",
        "Meteor showers"
      ]
    },
    {
      "id": "E4E07",
      "correct": 2,
      "question": "How can you determine if line noise interference is being generated within your home?",
      "answers": [
        "By checking the power line voltage with a time domain reflectometer",
        "By observing the AC power line waveform with an oscilloscope",
        "By turning off the AC power line main circuit breaker and listening on a battery operated radio",
        "By observing the AC power line voltage with a spectrum analyzer"
      ]
    },
    {
      "id": "E4E08",
      "correct": 0,
      "question": "What type of signal is picked up by electrical wiring near a radio antenna?",
      "answers": [
        "A common-mode signal at the frequency of the radio transmitter",
        "An electrical-sparking signal",
        "A differential-mode signal at the AC power line frequency",
        "Harmonics of the AC power line frequency"
      ]
    },
    {
      "id": "E4E09",
      "correct": 2,
      "question": "What undesirable effect can occur when using an IF noise blanker?",
      "answers": [
        "Received audio in the speech range might have an echo effect",
        "The audio frequency bandwidth of the received signal might be compressed",
        "Nearby signals may appear to be excessively wide even if they meet emission standards",
        "FM signals can no longer be demodulated"
      ]
    },
    {
      "id": "E4E10",
      "correct": 3,
      "question": "What is a common characteristic of interference caused by a touch controlled electrical device?",
      "answers": [
        "The interfering signal sounds like AC hum on an AM receiver or a carrier modulated by 60 Hz hum on a SSB or CW receiver",
        "The interfering signal may drift slowly across the HF spectrum",
        "The interfering signal can be several kHz in width and usually repeats at regular intervals across a HF band",
        "All of these choices are correct"
      ]
    },
    {
      "id": "E4E11",
      "correct": 1,
      "question": "Which is the most likely cause if you are hearing combinations of local AM broadcast signals within one or more of the MF or HF ham bands?",
      "answers": [
        "The broadcast station is transmitting an over-modulated signal",
        "Nearby corroded metal joints are mixing and re-radiating the broadcast signals",
        "You are receiving sky wave signals from a distant station",
        "Your station receiver IF amplifier stage is defective"
      ]
    },
    {
      "id": "E4E12",
      "correct": 0,
      "question": "What is one disadvantage of using some types of automatic DSP notch-filters when attempting to copy CW signals?",
      "answers": [
        "A DSP filter can remove the desired signal at the same time as it removes interfering signals",
        "Any nearby signal passing through the DSP system will overwhelm the desired signal",
        "Received CW signals will appear to be modulated at the DSP clock frequency",
        "Ringing in the DSP filter will completely remove the spaces between the CW characters"
      ]
    },
    {
      "id": "E4E13",
      "correct": 3,
      "question": "What might be the cause of a loud roaring or buzzing AC line interference that comes and goes at intervals?",
      "answers": [
        "Arcing contacts in a thermostatically controlled device",
        "A defective doorbell or doorbell transformer inside a nearby residence",
        "A malfunctioning illuminated advertising display",
        "All of these choices are correct"
      ]
    },
    {
      "id": "E4E14",
      "correct": 2,
      "question": "What is one type of electrical interference that might be caused by the operation of a nearby personal computer?",
      "answers": [
        "A loud AC hum in the audio output of your station receiver",
        "A clicking noise at intervals of a few seconds",
        "The appearance of unstable modulated or unmodulated signals at specific frequencies",
        "A whining type noise that continually pulses off and on"
      ]
    },
    {
      "id": "E4E15",
      "correct": 1,
      "question": "Which of the following can cause shielded cables to radiate or receive interference?",
      "answers": [
        "Low inductance ground connections at both ends of the shield",
        "Common mode currents on the shield and conductors",
        "Use of braided shielding material",
        "Tying all ground connections to a common point resulting in differential mode currents in the shield"
      ]
    },
    {
      "id": "E4E16",
      "correct": 1,
      "question": "What current flows equally on all conductors of an unshielded multi-conductor cable?",
      "answers": [
        "Differential-mode current",
        "Common-mode current",
        "Reactive current only",
        "Return current"
      ]
    },
    {
      "id": "E5A01",
      "correct": 0,
      "question": "What can cause the voltage across reactances in series to be larger than the voltage applied to them?",
      "answers": [
        "Resonance",
        "Capacitance",
        "Conductance",
        "Resistance"
      ]
    },
    {
      "id": "E5A02",
      "correct": 2,
      "question": "What is resonance in an electrical circuit?",
      "answers": [
        "The highest frequency that will pass current",
        "The lowest frequency that will pass current",
        "The frequency at which the capacitive reactance equals the inductive reactance",
        "The frequency at which the reactive impedance equals the resistive impedance"
      ]
    },
    {
      "id": "E5A03",
      "correct": 3,
      "question": "What is the magnitude of the impedance of a series RLC circuit at resonance?",
      "answers": [
        "High, as compared to the circuit resistance",
        "Approximately equal to capacitive reactance",
        "Approximately equal to inductive reactance",
        "Approximately equal to circuit resistance"
      ]
    },
    {
      "id": "E5A04",
      "correct": 0,
      "question": "What is the magnitude of the impedance of a circuit with a resistor, an inductor and a capacitor all in parallel, at resonance?",
      "answers": [
        "Approximately equal to circuit resistance",
        "Approximately equal to inductive reactance",
        "Low, as compared to the circuit resistance",
        "Approximately equal to capacitive reactance"
      ]
    },
    {
      "id": "E5A05",
      "correct": 1,
      "question": "What is the magnitude of the current at the input of a series RLC circuit as the frequency goes through resonance?",
      "answers": [
        "Minimum",
        "Maximum",
        "R/L",
        "L/R"
      ]
    },
    {
      "id": "E5A06",
      "correct": 1,
      "question": "What is the magnitude of the circulating current within the components of a parallel LC circuit at resonance?",
      "answers": [
        "It is at a minimum",
        "It is at a maximum",
        "It equals 1 divided by the quantity 2 times Pi, multiplied by the square root of inductance L multiplied by capacitance C",
        "It equals 2 multiplied by Pi, multiplied by frequency, multiplied by inductance"
      ]
    },
    {
      "id": "E5A07",
      "correct": 0,
      "question": "What is the magnitude of the current at the input of a parallel RLC circuit at resonance?",
      "answers": [
        "Minimum",
        "Maximum",
        "R/L",
        "L/R"
      ]
    },
    {
      "id": "E5A08",
      "correct": 2,
      "question": "What is the phase relationship between the current through and the voltage across a series resonant circuit at resonance?",
      "answers": [
        "The voltage leads the current by 90 degrees",
        "The current leads the voltage by 90 degrees",
        "The voltage and current are in phase",
        "The voltage and current are 180 degrees out of phase"
      ]
    },
    {
      "id": "E5A09",
      "correct": 2,
      "question": "How is the Q of an RLC parallel resonant circuit calculated?",
      "answers": [
        "Reactance of either the inductance or capacitance divided by the resistance",
        "Reactance of either the inductance or capacitance multiplied by the resistance",
        "Resistance divided by the reactance of either the inductance or capacitance",
        "Reactance of the inductance multiplied by the reactance of the capacitance"
      ]
    },
    {
      "id": "E5A10",
      "correct": 0,
      "question": "How is the Q of an RLC series resonant circuit calculated?",
      "answers": [
        "Reactance of either the inductance or capacitance divided by the resistance",
        "Reactance of either the inductance or capacitance times the resistance",
        "Resistance divided by the reactance of either the inductance or capacitance",
        "Reactance of the inductance times the reactance of the capacitance"
      ]
    },
    {
      "id": "E5A11",
      "correct": 2,
      "question": "What is the half-power bandwidth of a parallel resonant circuit that has a resonant frequency of 7.1 MHz and a Q of 150?",
      "answers": [
        "157.8 Hz",
        "315.6 Hz",
        "47.3 kHz",
        "23.67 kHz"
      ]
    },
    {
      "id": "E5A12",
      "correct": 2,
      "question": "What is the half-power bandwidth of a parallel resonant circuit that has a resonant frequency of 3.7 MHz and a Q of 118?",
      "answers": [
        "436.6 kHz",
        "218.3 kHz",
        "31.4 kHz",
        "15.7 kHz"
      ]
    },
    {
      "id": "E5A13",
      "correct": 2,
      "question": "What is an effect of increasing Q in a resonant circuit?",
      "answers": [
        "Fewer components are needed for the same performance",
        "Parasitic effects are minimized",
        "Internal voltages and circulating currents increase",
        "Phase shift can become uncontrolled"
      ]
    },
    {
      "id": "E5A14",
      "correct": 2,
      "question": "What is the resonant frequency of a series RLC circuit if R is 22 ohms, L is 50 microhenrys and C is 40 picofarads?",
      "answers": [
        "44.72 MHz",
        "22.36 MHz",
        "3.56 MHz",
        "1.78 MHz"
      ]
    },
    {
      "id": "E5A15",
      "correct": 0,
      "question": "Which of the following can increase Q for inductors and capacitors?",
      "answers": [
        "Lower losses",
        "Lower reactance",
        "Lower self-resonant frequency",
        "Higher self-resonant frequency"
      ]
    },
    {
      "id": "E5A16",
      "correct": 3,
      "question": "What is the resonant frequency of a parallel RLC circuit if R is 33 ohms, L is 50 microhenrys and C is 10 picofarads?",
      "answers": [
        "23.5 MHz",
        "23.5 kHz",
        "7.12 kHz",
        "7.12 MHz"
      ]
    },
    {
      "id": "E5A17",
      "correct": 0,
      "question": "What is the result of increasing the Q of an impedance-matching circuit?",
      "answers": [
        "Matching bandwidth is decreased",
        "Matching bandwidth is increased",
        "Matching range is increased",
        "It has no effect on impedance matching"
      ]
    },
    {
      "id": "E5B01",
      "correct": 1,
      "question": "What is the term for the time required for the capacitor in an RC circuit to be charged to 63.2% of the applied voltage?",
      "answers": [
        "An exponential rate of one",
        "One time constant",
        "One exponential period",
        "A time factor of one"
      ]
    },
    {
      "id": "E5B02",
      "correct": 3,
      "question": "What is the term for the time it takes for a charged capacitor in an RC circuit to discharge to 36.8% of its initial voltage?",
      "answers": [
        "One discharge period",
        "An exponential discharge rate of one",
        "A discharge factor of one",
        "One time constant"
      ]
    },
    {
      "id": "E5B03",
      "correct": 1,
      "question": "What happens to the phase angle of a reactance when it is converted to a susceptance?",
      "answers": [
        "It is unchanged",
        "The sign is reversed",
        "It is shifted by 90 degrees",
        "The susceptance phase angle is the inverse of the reactance phase angle"
      ]
    },
    {
      "id": "E5B04",
      "correct": 3,
      "question": "What is the time constant of a circuit having two 220 microfarad capacitors and two 1 megohm resistors, all in parallel?",
      "answers": [
        "55 seconds",
        "110 seconds",
        "440 seconds",
        "220 seconds"
      ]
    },
    {
      "id": "E5B05",
      "correct": 3,
      "question": "What happens to the magnitude of a reactance when it is converted to a susceptance?",
      "answers": [
        "It is unchanged",
        "The sign is reversed",
        "It is shifted by 90 degrees",
        "The magnitude of the susceptance is the reciprocal of the magnitude of the reactance"
      ]
    },
    {
      "id": "E5B06",
      "correct": 2,
      "question": "What is susceptance?",
      "answers": [
        "The magnetic impedance of a circuit",
        "The ratio of magnetic field to electric field",
        "The inverse of reactance",
        "A measure of the efficiency of a transformer"
      ]
    },
    {
      "id": "E5B07",
      "correct": 2,
      "question": "What is the phase angle between the voltage across and the current through a series RLC circuit if XC is 500 ohms, R is 1 kilohm, and XL is 250 ohms?",
      "answers": [
        "68.2 degrees with the voltage leading the current",
        "14.0 degrees with the voltage leading the current",
        "14.0 degrees with the voltage lagging the current",
        "68.2 degrees with the voltage lagging the current"
      ]
    },
    {
      "id": "E5B08",
      "correct": 0,
      "question": "What is the phase angle between the voltage across and the current through a series RLC circuit if XC is 100 ohms, R is 100 ohms, and XL is 75 ohms?",
      "answers": [
        "14 degrees with the voltage lagging the current",
        "14 degrees with the voltage leading the current",
        "76 degrees with the voltage leading the current",
        "76 degrees with the voltage lagging the current"
      ]
    },
    {
      "id": "E5B09",
      "correct": 3,
      "question": "What is the relationship between the current through a capacitor and the voltage across a capacitor?",
      "answers": [
        "Voltage and current are in phase",
        "Voltage and current are 180 degrees out of phase",
        "Voltage leads current by 90 degrees",
        "Current leads voltage by 90 degrees"
      ]
    },
    {
      "id": "E5B10",
      "correct": 0,
      "question": "What is the relationship between the current through an inductor and the voltage across an inductor?",
      "answers": [
        "Voltage leads current by 90 degrees",
        "Current leads voltage by 90 degrees",
        "Voltage and current are 180 degrees out of phase",
        "Voltage and current are in phase"
      ]
    },
    {
      "id": "E5B11",
      "correct": 1,
      "question": "What is the phase angle between the voltage across and the current through a series RLC circuit if XC is 25 ohms, R is 100 ohms, and XL is 50 ohms?",
      "answers": [
        "14 degrees with the voltage lagging the current",
        "14 degrees with the voltage leading the current",
        "76 degrees with the voltage lagging the current",
        "76 degrees with the voltage leading the current"
      ]
    },
    {
      "id": "E5B12",
      "correct": 0,
      "question": "What is admittance?",
      "answers": [
        "The inverse of impedance",
        "The term for the gain of a field effect transistor",
        "The turns ratio of a transformer",
        "The unit used for Q factor"
      ]
    },
    {
      "id": "E5B13",
      "correct": 3,
      "question": "What letter is commonly used to represent susceptance?",
      "answers": [
        "G",
        "X",
        "Y",
        "B"
      ]
    },
    {
      "id": "E5C01",
      "correct": 0,
      "question": "Which of the following represents a capacitive reactance in rectangular notation?",
      "answers": [
        "–jX",
        "+jX",
        "X",
        "Omega"
      ]
    },
    {
      "id": "E5C02",
      "correct": 2,
      "question": "How are impedances described in polar coordinates?",
      "answers": [
        "By X and R values",
        "By real and imaginary parts",
        "By phase angle and amplitude",
        "By Y and G values"
      ]
    },
    {
      "id": "E5C03",
      "correct": 2,
      "question": "Which of the following represents an inductive reactance in polar coordinates?",
      "answers": [
        "A positive real part",
        "A negative real part",
        "A positive phase angle",
        "A negative phase angle"
      ]
    },
    {
      "id": "E5C04",
      "correct": 3,
      "question": "Which of the following represents a capacitive reactance in polar coordinates?",
      "answers": [
        "A positive real part",
        "A negative real part",
        "A positive phase angle",
        "A negative phase angle"
      ]
    },
    {
      "id": "E5C05",
      "correct": 2,
      "question": "What is the name of the diagram used to show the phase relationship between impedances at a given frequency?",
      "answers": [
        "Venn diagram",
        "Near field diagram",
        "Phasor diagram",
        "Far field diagram"
      ]
    },
    {
      "id": "E5C06",
      "correct": 1,
      "question": "What does the impedance 50–j25 represent?",
      "answers": [
        "50 ohms resistance in series with 25 ohms inductive reactance",
        "50 ohms resistance in series with 25 ohms capacitive reactance",
        "25 ohms resistance in series with 50 ohms inductive reactance",
        "25 ohms resistance in series with 50 ohms capacitive reactance"
      ]
    },
    {
      "id": "E5C07",
      "correct": 1,
      "question": "What is a vector?",
      "answers": [
        "The value of a quantity that changes over time",
        "A quantity with both magnitude and an angular component",
        "The inverse of the tangent function",
        "The inverse of the sine function"
      ]
    },
    {
      "id": "E5C08",
      "correct": 3,
      "question": "What coordinate system is often used to display the phase angle of a circuit containing resistance, inductive and/or capacitive reactance?",
      "answers": [
        "Maidenhead grid",
        "Faraday grid",
        "Elliptical coordinates",
        "Polar coordinates"
      ]
    },
    {
      "id": "E5C09",
      "correct": 0,
      "question": "When using rectangular coordinates to graph the impedance of a circuit, what does the horizontal axis represent?",
      "answers": [
        "Resistive component",
        "Reactive component",
        "The sum of the reactive and resistive components",
        "The difference between the resistive and reactive components"
      ]
    },
    {
      "id": "E5C10",
      "correct": 1,
      "question": "When using rectangular coordinates to graph the impedance of a circuit, what does the vertical axis represent?",
      "answers": [
        "Resistive component",
        "Reactive component",
        "The sum of the reactive and resistive components",
        "The difference between the resistive and reactive components"
      ]
    },
    {
      "id": "E5C11",
      "correct": 2,
      "question": "What do the two numbers that are used to define a point on a graph using rectangular coordinates represent?",
      "answers": [
        "The magnitude and phase of the point",
        "The sine and cosine values",
        "The coordinate values along the horizontal and vertical axes",
        "The tangent and cotangent values"
      ]
    },
    {
      "id": "E5C12",
      "correct": 3,
      "question": "If you plot the impedance of a circuit using the rectangular coordinate system and find the impedance point falls on the right side of the graph on the horizontal axis, what do you know about the circuit?",
      "answers": [
        "It has to be a direct current circuit",
        "It contains resistance and capacitive reactance",
        "It contains resistance and inductive reactance",
        "It is equivalent to a pure resistance"
      ]
    },
    {
      "id": "E5C13",
      "correct": 3,
      "question": "What coordinate system is often used to display the resistive, inductive, and/or capacitive reactance components of impedance?",
      "answers": [
        "Maidenhead grid",
        "Faraday grid",
        "Elliptical coordinates",
        "Rectangular coordinates"
      ]
    },
    {
      "id": "E5C14",
      "correct": 1,
      "question": "Which point on Figure E5-2 best represents the impedance of a series circuit consisting of a 400 ohm resistor and a 38 picofarad capacitor at 14 MHz?",
      "answers": [
        "Point 2",
        "Point 4",
        "Point 5",
        "Point 6"
      ]
    },
    {
      "id": "E5C15",
      "correct": 1,
      "question": "Which point in Figure E5-2 best represents the impedance of a series circuit consisting of a 300 ohm resistor and an 18 microhenry inductor at 3.505 MHz?",
      "answers": [
        "Point 1",
        "Point 3",
        "Point 7",
        "Point 8"
      ]
    },
    {
      "id": "E5C16",
      "correct": 0,
      "question": "Which point on Figure E5-2 best represents the impedance of a series circuit consisting of a 300 ohm resistor and a 19 picofarad capacitor at 21.200 MHz?",
      "answers": [
        "Point 1",
        "Point 3",
        "Point 7",
        "Point 8"
      ]
    },
    {
      "id": "E5C17",
      "correct": 3,
      "question": "Which point on Figure E5-2 best represents the impedance of a series circuit consisting of a 300 ohm resistor, a 0.64-microhenry inductor and an 85-picofarad capacitor at 24.900 MHz?",
      "answers": [
        "Point 1",
        "Point 3",
        "Point 5",
        "Point 8"
      ]
    },
    {
      "id": "E5D01",
      "correct": 0,
      "question": "What is the result of skin effect?",
      "answers": [
        "As frequency increases, RF current flows in a thinner layer of the conductor, closer to the surface",
        "As frequency decreases, RF current flows in a thinner layer of the conductor, closer to the surface",
        "Thermal effects on the surface of the conductor increase the impedance",
        "Thermal effects on the surface of the conductor decrease the impedance"
      ]
    },
    {
      "id": "E5D02",
      "correct": 1,
      "question": "Why is it important to keep lead lengths short for components used in circuits for VHF and above?",
      "answers": [
        "To increase the thermal time constant",
        "To avoid unwanted inductive reactance",
        "To maintain component lifetime",
        "All of these choices are correct"
      ]
    },
    {
      "id": "E5D03",
      "correct": 3,
      "question": "What is microstrip?",
      "answers": [
        "Lightweight transmission line made of common zip cord",
        "Miniature coax used for low power applications",
        "Short lengths of coax mounted on printed circuit boards to minimize time delay between microwave circuits",
        "Precision printed circuit conductors above a ground plane that provide constant impedance interconnects at microwave frequencies"
      ]
    },
    {
      "id": "E5D04",
      "correct": 1,
      "question": "Why are short connections necessary at microwave frequencies?",
      "answers": [
        "To increase neutralizing resistance",
        "To reduce phase shift along the connection",
        "Because of ground reflections",
        "To reduce noise figure"
      ]
    },
    {
      "id": "E5D05",
      "correct": 0,
      "question": "Which parasitic characteristic increases with conductor length?",
      "answers": [
        "Inductance",
        "Permeability",
        "Permittivity",
        "Malleability"
      ]
    },
    {
      "id": "E5D06",
      "correct": 3,
      "question": "In what direction is the magnetic field oriented about a conductor in relation to the direction of electron flow?",
      "answers": [
        "In the same direction as the current",
        "In a direction opposite to the current",
        "In all directions; omni-directional",
        "In a direction determined by the left-hand rule"
      ]
    },
    {
      "id": "E5D07",
      "correct": 3,
      "question": "What determines the strength of the magnetic field around a conductor?",
      "answers": [
        "The resistance divided by the current",
        "The ratio of the current to the resistance",
        "The diameter of the conductor",
        "The amount of current flowing through the conductor"
      ]
    },
    {
      "id": "E5D08",
      "correct": 1,
      "question": "What type of energy is stored in an electromagnetic or electrostatic field?",
      "answers": [
        "Electromechanical energy",
        "Potential energy",
        "Thermodynamic energy",
        "Kinetic energy"
      ]
    },
    {
      "id": "E5D09",
      "correct": 1,
      "question": "What happens to reactive power in an AC circuit that has both ideal inductors and ideal capacitors?",
      "answers": [
        "It is dissipated as heat in the circuit",
        "It is repeatedly exchanged between the associated magnetic and electric fields, but is not dissipated",
        "It is dissipated as kinetic energy in the circuit",
        "It is dissipated in the formation of inductive and capacitive fields"
      ]
    },
    {
      "id": "E5D10",
      "correct": 0,
      "question": "How can the true power be determined in an AC circuit where the voltage and current are out of phase?",
      "answers": [
        "By multiplying the apparent power times the power factor",
        "By dividing the reactive power by the power factor",
        "By dividing the apparent power by the power factor",
        "By multiplying the reactive power times the power factor"
      ]
    },
    {
      "id": "E5D11",
      "correct": 2,
      "question": "What is the power factor of an R-L circuit having a 60 degree phase angle between the voltage and the current?",
      "answers": [
        "1.414",
        "0.866",
        "0.5",
        "1.73"
      ]
    },
    {
      "id": "E5D12",
      "correct": 1,
      "question": "How many watts are consumed in a circuit having a power factor of 0.2 if the input is 100-VAC at 4 amperes?",
      "answers": [
        "400 watts",
        "80 watts",
        "2000 watts",
        "50 watts"
      ]
    },
    {
      "id": "E5D13",
      "correct": 1,
      "question": "How much power is consumed in a circuit consisting of a 100 ohm resistor in series with a 100 ohm inductive reactance drawing 1 ampere?",
      "answers": [
        "70.7 Watts",
        "100 Watts",
        "141.4 Watts",
        "200 Watts"
      ]
    },
    {
      "id": "E5D14",
      "correct": 0,
      "question": "What is reactive power?",
      "answers": [
        "Wattless, nonproductive power",
        "Power consumed in wire resistance in an inductor",
        "Power lost because of capacitor leakage",
        "Power consumed in circuit Q"
      ]
    },
    {
      "id": "E5D15",
      "correct": 3,
      "question": "What is the power factor of an R-L circuit having a 45 degree phase angle between the voltage and the current?",
      "answers": [
        "0.866",
        "1.0",
        "0.5",
        "0.707"
      ]
    },
    {
      "id": "E5D16",
      "correct": 2,
      "question": "What is the power factor of an R-L circuit having a 30 degree phase angle between the voltage and the current?",
      "answers": [
        "1.73",
        "0.5",
        "0.866",
        "0.577"
      ]
    },
    {
      "id": "E5D17",
      "correct": 3,
      "question": "How many watts are consumed in a circuit having a power factor of 0.6 if the input is 200VAC at 5 amperes?",
      "answers": [
        "200 watts",
        "1000 watts",
        "1600 watts",
        "600 watts"
      ]
    },
    {
      "id": "E5D18",
      "correct": 1,
      "question": "How many watts are consumed in a circuit having a power factor of 0.71 if the apparent power is 500VA?",
      "answers": [
        "704 W",
        "355 W",
        "252 W",
        "1.42 mW"
      ]
    },
    {
      "id": "E6A01",
      "correct": 2,
      "question": "In what application is gallium arsenide used as a semiconductor material in preference to germanium or silicon?",
      "answers": [
        "In high-current rectifier circuits",
        "In high-power audio circuits",
        "In microwave circuits",
        "In very low frequency RF circuits"
      ]
    },
    {
      "id": "E6A02",
      "correct": 0,
      "question": "Which of the following semiconductor materials contains excess free electrons?",
      "answers": [
        "N-type",
        "P-type",
        "Bipolar",
        "Insulated gate"
      ]
    },
    {
      "id": "E6A03",
      "correct": 2,
      "question": "Why does a PN-junction diode not conduct current when reverse biased?",
      "answers": [
        "Only P-type semiconductor material can conduct current",
        "Only N-type semiconductor material can conduct current",
        "Holes in P-type material and electrons in the N-type material are separated by the applied voltage, widening the depletion region",
        "Excess holes in P-type material combine with the electrons in N-type material, converting the entire diode into an insulator"
      ]
    },
    {
      "id": "E6A04",
      "correct": 2,
      "question": "What is the name given to an impurity atom that adds holes to a semiconductor crystal structure?",
      "answers": [
        "Insulator impurity",
        "N-type impurity",
        "Acceptor impurity",
        "Donor impurity"
      ]
    },
    {
      "id": "E6A05",
      "correct": 2,
      "question": "What is the alpha of a bipolar junction transistor?",
      "answers": [
        "The change of collector current with respect to base current",
        "The change of base current with respect to collector current",
        "The change of collector current with respect to emitter current",
        "The change of collector current with respect to gate current"
      ]
    },
    {
      "id": "E6A06",
      "correct": 1,
      "question": "What is the beta of a bipolar junction transistor?",
      "answers": [
        "The frequency at which the current gain is reduced to 1",
        "The change in collector current with respect to base current",
        "The breakdown voltage of the base to collector junction",
        "The switching speed of the transistor"
      ]
    },
    {
      "id": "E6A07",
      "correct": 3,
      "question": "Which of the following indicates that a silicon NPN junction transistor is biased on?",
      "answers": [
        "Base-to-emitter resistance of approximately 6 to 7 ohms",
        "Base-to-emitter resistance of approximately 0.6 to 0.7 ohms",
        "Base-to-emitter voltage of approximately 6 to 7 volts",
        "Base-to-emitter voltage of approximately 0.6 to 0.7 volts"
      ]
    },
    {
      "id": "E6A08",
      "correct": 3,
      "question": "What term indicates the frequency at which the grounded-base current gain of a transistor has decreased to 0.7 of the gain obtainable at 1 kHz?",
      "answers": [
        "Corner frequency",
        "Alpha rejection frequency",
        "Beta cutoff frequency",
        "Alpha cutoff frequency"
      ]
    },
    {
      "id": "E6A09",
      "correct": 0,
      "question": "What is a depletion-mode FET?",
      "answers": [
        "An FET that exhibits a current flow between source and drain when no gate voltage is applied",
        "An FET that has no current flow between source and drain when no gate voltage is applied",
        "Any FET without a channel",
        "Any FET for which holes are the majority carriers"
      ]
    },
    {
      "id": "E6A10",
      "correct": 1,
      "question": "In Figure E6-2, what is the schematic symbol for an N-channel dual-gate MOSFET?",
      "answers": [
        "2",
        "4",
        "5",
        "6"
      ]
    },
    {
      "id": "E6A11",
      "correct": 0,
      "question": "In Figure E6-2, what is the schematic symbol for a P-channel junction FET?",
      "answers": [
        "1",
        "2",
        "3",
        "6"
      ]
    },
    {
      "id": "E6A12",
      "correct": 3,
      "question": "Why do many MOSFET devices have internally connected Zener diodes on the gates?",
      "answers": [
        "To provide a voltage reference for the correct amount of reverse-bias gate voltage",
        "To protect the substrate from excessive voltages",
        "To keep the gate voltage within specifications and prevent the device from overheating",
        "To reduce the chance of the gate insulation being punctured by static discharges or excessive voltages"
      ]
    },
    {
      "id": "E6A13",
      "correct": 2,
      "question": "What do the initials CMOS stand for?",
      "answers": [
        "Common Mode Oscillating System",
        "Complementary Mica-Oxide Silicon",
        "Complementary Metal-Oxide Semiconductor",
        "Common Mode Organic Silicon"
      ]
    },
    {
      "id": "E6A14",
      "correct": 2,
      "question": "How does DC input impedance at the gate of a field-effect transistor compare with the DC input impedance of a bipolar transistor?",
      "answers": [
        "They are both low impedance",
        "An FET has low input impedance; a bipolar transistor has high input impedance",
        "An FET has high input impedance; a bipolar transistor has low input impedance",
        "They are both high impedance"
      ]
    },
    {
      "id": "E6A15",
      "correct": 1,
      "question": "Which semiconductor material contains excess holes in the outer shell of electrons?",
      "answers": [
        "N-type",
        "P-type",
        "Superconductor-type",
        "Bipolar-type"
      ]
    },
    {
      "id": "E6A16",
      "correct": 1,
      "question": "What are the majority charge carriers in N-type semiconductor material?",
      "answers": [
        "Holes",
        "Free electrons",
        "Free protons",
        "Free neutrons"
      ]
    },
    {
      "id": "E6A17",
      "correct": 3,
      "question": "What are the names of the three terminals of a field-effect transistor?",
      "answers": [
        "Gate 1, gate 2, drain",
        "Emitter, base, collector",
        "Emitter, base 1, base 2",
        "Gate, drain, source"
      ]
    },
    {
      "id": "E6B01",
      "correct": 1,
      "question": "What is the most useful characteristic of a Zener diode?",
      "answers": [
        "A constant current drop under conditions of varying voltage",
        "A constant voltage drop under conditions of varying current",
        "A negative resistance region",
        "An internal capacitance that varies with the applied voltage"
      ]
    },
    {
      "id": "E6B02",
      "correct": 3,
      "question": "What is an important characteristic of a Schottky diode as compared to an ordinary silicon diode when used as a power supply rectifier?",
      "answers": [
        "Much higher reverse voltage breakdown",
        "Controlled reverse avalanche voltage",
        "Enhanced carrier retention time",
        "Less forward voltage drop"
      ]
    },
    {
      "id": "E6B03",
      "correct": 2,
      "question": "What special type of diode is capable of both amplification and oscillation?",
      "answers": [
        "Point contact",
        "Zener",
        "Tunnel",
        "Junction"
      ]
    },
    {
      "id": "E6B04",
      "correct": 0,
      "question": "What type of semiconductor device is designed for use as a voltage-controlled capacitor?",
      "answers": [
        "Varactor diode",
        "Tunnel diode",
        "Silicon-controlled rectifier",
        "Zener diode"
      ]
    },
    {
      "id": "E6B05",
      "correct": 3,
      "question": "What characteristic of a PIN diode makes it useful as an RF switch or attenuator?",
      "answers": [
        "Extremely high reverse breakdown voltage",
        "Ability to dissipate large amounts of power",
        "Reverse bias controls its forward voltage drop",
        "A large region of intrinsic material"
      ]
    },
    {
      "id": "E6B06",
      "correct": 3,
      "question": "Which of the following is a common use of a hot-carrier diode?",
      "answers": [
        "As balanced mixers in FM generation",
        "As a variable capacitance in an automatic frequency control circuit",
        "As a constant voltage reference in a power supply",
        "As a VHF/UHF mixer or detector"
      ]
    },
    {
      "id": "E6B07",
      "correct": 1,
      "question": "What is the failure mechanism when a junction diode fails due to excessive current?",
      "answers": [
        "Excessive inverse voltage",
        "Excessive junction temperature",
        "Insufficient forward voltage",
        "Charge carrier depletion"
      ]
    },
    {
      "id": "E6B08",
      "correct": 0,
      "question": "Which of the following describes a type of semiconductor diode?",
      "answers": [
        "Metal-semiconductor junction",
        "Electrolytic rectifier",
        "CMOS-field effect",
        "Thermionic emission diode"
      ]
    },
    {
      "id": "E6B09",
      "correct": 2,
      "question": "What is a common use for point contact diodes?",
      "answers": [
        "As a constant current source",
        "As a constant voltage source",
        "As an RF detector",
        "As a high voltage rectifier"
      ]
    },
    {
      "id": "E6B10",
      "correct": 1,
      "question": "In Figure E6-3, what is the schematic symbol for a light-emitting diode?",
      "answers": [
        "1",
        "5",
        "6",
        "7"
      ]
    },
    {
      "id": "E6B11",
      "correct": 0,
      "question": "What is used to control the attenuation of RF signals by a PIN diode?",
      "answers": [
        "Forward DC bias current",
        "A sub-harmonic pump signal",
        "Reverse voltage larger than the RF signal",
        "Capacitance of an RF coupling capacitor"
      ]
    },
    {
      "id": "E6B12",
      "correct": 2,
      "question": "What is one common use for PIN diodes?",
      "answers": [
        "As a constant current source",
        "As a constant voltage source",
        "As an RF switch",
        "As a high voltage rectifier"
      ]
    },
    {
      "id": "E6B13",
      "correct": 1,
      "question": "What type of bias is required for an LED to emit light?",
      "answers": [
        "Reverse bias",
        "Forward bias",
        "Zero bias",
        "Inductive bias"
      ]
    },
    {
      "id": "E6C01",
      "correct": 0,
      "question": "What is the function of hysteresis in a comparator?",
      "answers": [
        "To prevent input noise from causing unstable output signals",
        "To allow the comparator to be used with AC input signal",
        "To cause the output to change states continually",
        "To increase the sensitivity"
      ]
    },
    {
      "id": "E6C02",
      "correct": 1,
      "question": "What happens when the level of a comparator’s input signal crosses the threshold?",
      "answers": [
        "The IC input can be damaged",
        "The comparator changes its output state",
        "The comparator enters latch-up",
        "The feedback loop becomes unstable"
      ]
    },
    {
      "id": "E6C03",
      "correct": 0,
      "question": "What is tri-state logic?",
      "answers": [
        "Logic devices with 0, 1, and high impedance output states",
        "Logic devices that utilize ternary math",
        "Low power logic devices designed to operate at 3 volts",
        "Proprietary logic devices manufactured by Tri-State Devices"
      ]
    },
    {
      "id": "E6C04",
      "correct": 1,
      "question": "What is the primary advantage of tri-state logic?",
      "answers": [
        "Low power consumption",
        "Ability to connect many device outputs to a common bus",
        "High speed operation",
        "More efficient arithmetic operations"
      ]
    },
    {
      "id": "E6C05",
      "correct": 3,
      "question": "What is an advantage of CMOS logic devices over TTL devices?",
      "answers": [
        "Differential output capability",
        "Lower distortion",
        "Immune to damage from static discharge",
        "Lower power consumption"
      ]
    },
    {
      "id": "E6C06",
      "correct": 2,
      "question": "Why do CMOS digital integrated circuits have high immunity to noise on the input signal or power supply?",
      "answers": [
        "Larger bypass capacitors are used in CMOS circuit design",
        "The input switching threshold is about two times the power supply voltage",
        "The input switching threshold is about one-half the power supply voltage",
        "Input signals are stronger"
      ]
    },
    {
      "id": "E6C07",
      "correct": 1,
      "question": "What best describes a pull-up or pull-down resistor?",
      "answers": [
        "A resistor in a keying circuit used to reduce key clicks",
        "A resistor connected to the positive or negative supply line used to establish a voltage when an input or output is an open circuit",
        "A resistor that insures that an oscillator frequency does not drive lower over time",
        "A resistor connected to an op-amp output that only functions when the logic output is false"
      ]
    },
    {
      "id": "E6C08",
      "correct": 1,
      "question": "In Figure E6-5, what is the schematic symbol for a NAND gate?",
      "answers": [
        "1",
        "2",
        "3",
        "4"
      ]
    },
    {
      "id": "E6C09",
      "correct": 1,
      "question": "What is a Programmable Logic Device (PLD)?",
      "answers": [
        "A device to control industrial equipment",
        "A programmable collection of logic gates and circuits in a single integrated circuit",
        "Programmable equipment used for testing digital logic integrated circuits",
        "An algorithm for simulating logic functions during circuit design"
      ]
    },
    {
      "id": "E6C10",
      "correct": 3,
      "question": "In Figure E6-5, what is the schematic symbol for a NOR gate?",
      "answers": [
        "1",
        "2",
        "3",
        "4"
      ]
    },
    {
      "id": "E6C11",
      "correct": 2,
      "question": "In Figure E6-5, what is the schematic symbol for the NOT operation (inverter)?",
      "answers": [
        "2",
        "4",
        "5",
        "6"
      ]
    },
    {
      "id": "E6C12",
      "correct": 3,
      "question": "What is BiCMOS logic?",
      "answers": [
        "A logic device with two CMOS circuits per package",
        "A FET logic family based on bimetallic semiconductors",
        "A logic family based on bismuth CMOS devices",
        "An integrated circuit logic family using both bipolar and CMOS transistors"
      ]
    },
    {
      "id": "E6C13",
      "correct": 2,
      "question": "Which of the following is an advantage of BiCMOS logic?",
      "answers": [
        "Its simplicity results in much less expensive devices than standard CMOS",
        "It is totally immune to electrostatic damage",
        "It has the high input impedance of CMOS and the low output impedance of bipolar transistors",
        "All of these choices are correct"
      ]
    },
    {
      "id": "E6C14",
      "correct": 1,
      "question": "What is the primary advantage of using a Programmable Gate Array (PGA) in a logic circuit?",
      "answers": [
        "Many similar gates are less expensive than a mixture of gate types",
        "Complex logic functions can be created in a single integrated circuit",
        "A PGA contains its own internal power supply",
        "All of these choices are correct"
      ]
    },
    {
      "id": "E6D01",
      "correct": 0,
      "question": "How many turns will be required to produce a 5-microhenry inductor using a powdered-iron toroidal core that has an inductance index (A L) value of 40 microhenrys/100 turns?",
      "answers": [
        "35 turns",
        "13 turns",
        "79 turns",
        "141 turns"
      ]
    },
    {
      "id": "E6D02",
      "correct": 0,
      "question": "What is the equivalent circuit of a quartz crystal?",
      "answers": [
        "Motional capacitance, motional inductance, and loss resistance in series, all in parallel with a shunt capacitor representing electrode and stray capacitance",
        "Motional capacitance, motional inductance, loss resistance, and a capacitor representing electrode and stray capacitance all in parallel",
        "Motional capacitance, motional inductance, loss resistance, and a capacitor representing electrode and stray capacitance all in series",
        "Motional inductance and loss resistance in series, paralleled with motional capacitance and a capacitor representing electrode and stray capacitance"
      ]
    },
    {
      "id": "E6D03",
      "correct": 0,
      "question": "Which of the following is an aspect of the piezoelectric effect?",
      "answers": [
        "Mechanical deformation of material by the application of a voltage",
        "Mechanical deformation of material by the application of a magnetic field",
        "Generation of electrical energy in the presence of light",
        "Increased conductivity in the presence of light"
      ]
    },
    {
      "id": "E6D04",
      "correct": 1,
      "question": "Which materials are commonly used as a slug core in a variable inductor?",
      "answers": [
        "Polystyrene and polyethylene",
        "Ferrite and brass",
        "Teflon and Delrin",
        "Cobalt and aluminum"
      ]
    },
    {
      "id": "E6D05",
      "correct": 2,
      "question": "What is one reason for using ferrite cores rather than powdered-iron in an inductor?",
      "answers": [
        "Ferrite toroids generally have lower initial permeability",
        "Ferrite toroids generally have better temperature stability",
        "Ferrite toroids generally require fewer turns to produce a given inductance value",
        "Ferrite toroids are easier to use with surface mount technology"
      ]
    },
    {
      "id": "E6D06",
      "correct": 3,
      "question": "What core material property determines the inductance of a toroidal inductor?",
      "answers": [
        "Thermal impedance",
        "Resistance",
        "Reactivity",
        "Permeability"
      ]
    },
    {
      "id": "E6D07",
      "correct": 1,
      "question": "What is the usable frequency range of inductors that use toroidal cores, assuming a correct selection of core material for the frequency being used?",
      "answers": [
        "From a few kHz to no more than 30 MHz",
        "From less than 20 Hz to approximately 300 MHz",
        "From approximately 10 Hz to no more than 3000 kHz",
        "From about 100 kHz to at least 1000 GHz"
      ]
    },
    {
      "id": "E6D08",
      "correct": 1,
      "question": "What is one reason for using powdered-iron cores rather than ferrite cores in an inductor?",
      "answers": [
        "Powdered-iron cores generally have greater initial permeability",
        "Powdered-iron cores generally maintain their characteristics at higher currents",
        "Powdered-iron cores generally require fewer turns to produce a given inductance",
        "Powdered-iron cores use smaller diameter wire for the same inductance"
      ]
    },
    {
      "id": "E6D09",
      "correct": 2,
      "question": "What devices are commonly used as VHF and UHF parasitic suppressors at the input and output terminals of a transistor HF amplifier?",
      "answers": [
        "Electrolytic capacitors",
        "Butterworth filters",
        "Ferrite beads",
        "Steel-core toroids"
      ]
    },
    {
      "id": "E6D10",
      "correct": 0,
      "question": "What is a primary advantage of using a toroidal core instead of a solenoidal core in an inductor?",
      "answers": [
        "Toroidal cores confine most of the magnetic field within the core material",
        "Toroidal cores make it easier to couple the magnetic energy into other components",
        "Toroidal cores exhibit greater hysteresis",
        "Toroidal cores have lower Q characteristics"
      ]
    },
    {
      "id": "E6D11",
      "correct": 2,
      "question": "How many turns will be required to produce a 1-mH inductor using a core that has an inductance index (A L) value of 523 millihenrys/1000 turns?",
      "answers": [
        "2 turns",
        "4 turns",
        "43 turns",
        "229 turns"
      ]
    },
    {
      "id": "E6D12",
      "correct": 2,
      "question": "What is the definition of saturation in a ferrite core inductor?",
      "answers": [
        "The inductor windings are over coupled",
        "The inductor’s voltage rating is exceeded causing a flashover",
        "The ability of the inductor’s core to store magnetic energy has been exceeded",
        "Adjacent inductors become over-coupled"
      ]
    },
    {
      "id": "E6D13",
      "correct": 0,
      "question": "What is the primary cause of inductor self-resonance?",
      "answers": [
        "Inter-turn capacitance",
        "The skin effect",
        "Inductive kickback",
        "Non-linear core hysteresis"
      ]
    },
    {
      "id": "E6D14",
      "correct": 1,
      "question": "Which type of slug material decreases inductance when inserted into a coil?",
      "answers": [
        "Ceramic",
        "Brass",
        "Ferrite",
        "Powdered-iron"
      ]
    },
    {
      "id": "E6D15",
      "correct": 0,
      "question": "What is current in the primary winding of a transformer called if no load is attached to the secondary?",
      "answers": [
        "Magnetizing current",
        "Direct current",
        "Excitation current",
        "Stabilizing current"
      ]
    },
    {
      "id": "E6D16",
      "correct": 3,
      "question": "What is the common name for a capacitor connected across a transformer secondary that is used to absorb transient voltage spikes?",
      "answers": [
        "Clipper capacitor",
        "Trimmer capacitor",
        "Feedback capacitor",
        "Snubber capacitor"
      ]
    },
    {
      "id": "E6D17",
      "correct": 0,
      "question": "Why should core saturation of a conventional impedance matching transformer be avoided?",
      "answers": [
        "Harmonics and distortion could result",
        "Magnetic flux would increase with frequency",
        "RF susceptance would increase",
        "Temporary changes of the core permeability could result"
      ]
    },
    {
      "id": "E6E01",
      "correct": 2,
      "question": "Which of the following is true of a charge-coupled device (CCD)?",
      "answers": [
        "Its phase shift changes rapidly with frequency",
        "It is a CMOS analog-to-digital converter",
        "It samples an analog signal and passes it in stages from the input to the output",
        "It is used in a battery charger circuit"
      ]
    },
    {
      "id": "E6E02",
      "correct": 0,
      "question": "Which of the following device packages is a through-hole type?",
      "answers": [
        "DIP",
        "PLCC",
        "Ball grid array",
        "SOT"
      ]
    },
    {
      "id": "E6E03",
      "correct": 3,
      "question": "Which of the following materials is likely to provide the highest frequency of operation when used in MMICs?",
      "answers": [
        "Silicon",
        "Silicon nitride",
        "Silicon dioxide",
        "Gallium nitride"
      ]
    },
    {
      "id": "E6E04",
      "correct": 0,
      "question": "Which is the most common input and output impedance of circuits that use MMICs?",
      "answers": [
        "50 ohms",
        "300 ohms",
        "450 ohms",
        "10 ohms"
      ]
    },
    {
      "id": "E6E05",
      "correct": 0,
      "question": "Which of the following noise figure values is typical of a low-noise UHF preamplifier?",
      "answers": [
        "2 dB",
        "-10 dB",
        "44 dBm",
        "-20 dBm"
      ]
    },
    {
      "id": "E6E06",
      "correct": 3,
      "question": "What characteristics of the MMIC make it a popular choice for VHF through microwave circuits?",
      "answers": [
        "The ability to retrieve information from a single signal even in the presence of other strong signals",
        "Plate current that is controlled by a control grid",
        "Nearly infinite gain, very high input impedance, and very low output impedance",
        "Controlled gain, low noise figure, and constant input and output impedance over the specified frequency range"
      ]
    },
    {
      "id": "E6E07",
      "correct": 1,
      "question": "Which of the following is typically used to construct a MMIC-based microwave amplifier?",
      "answers": [
        "Ground-plane construction",
        "Microstrip construction",
        "Point-to-point construction",
        "Wave-soldering construction"
      ]
    },
    {
      "id": "E6E08",
      "correct": 0,
      "question": "How is voltage from a power supply normally furnished to the most common type of monolithic microwave integrated circuit (MMIC)?",
      "answers": [
        "Through a resistor and/or RF choke connected to the amplifier output lead",
        "MMICs require no operating bias",
        "Through a capacitor and RF choke connected to the amplifier input lead",
        "Directly to the bias voltage (VCC IN) lead"
      ]
    },
    {
      "id": "E6E09",
      "correct": 3,
      "question": "Which of the following component package types would be most suitable for use at frequencies above the HF range?",
      "answers": [
        "TO-220",
        "Axial lead",
        "Radial lead",
        "Surface mount"
      ]
    },
    {
      "id": "E6E10",
      "correct": 3,
      "question": "What is the packaging technique in which leadless components are soldered directly to circuit boards?",
      "answers": [
        "Direct soldering",
        "Virtual lead mounting",
        "Stripped lead",
        "Surface mount"
      ]
    },
    {
      "id": "E6E11",
      "correct": 3,
      "question": "What is a characteristic of DIP packaging used for integrated circuits?",
      "answers": [
        "Package mounts in a direct inverted position",
        "Low leakage doubly insulated package",
        "Two chips in each package (Dual In Package)",
        "A total of two rows of connecting pins placed on opposite sides of the package (Dual In-line Package)"
      ]
    },
    {
      "id": "E6E12",
      "correct": 1,
      "question": "Why are high-power RF amplifier ICs and transistors sometimes mounted in ceramic packages?",
      "answers": [
        "High-voltage insulating ability",
        "Better dissipation of heat",
        "Enhanced sensitivity to light",
        "To provide a low-pass frequency response"
      ]
    },
    {
      "id": "E6F01",
      "correct": 1,
      "question": "What is photoconductivity?",
      "answers": [
        "The conversion of photon energy to electromotive energy",
        "The increased conductivity of an illuminated semiconductor",
        "The conversion of electromotive energy to photon energy",
        "The decreased conductivity of an illuminated semiconductor"
      ]
    },
    {
      "id": "E6F02",
      "correct": 0,
      "question": "What happens to the conductivity of a photoconductive material when light shines on it?",
      "answers": [
        "It increases",
        "It decreases",
        "It stays the same",
        "It becomes unstable"
      ]
    },
    {
      "id": "E6F03",
      "correct": 3,
      "question": "What is the most common configuration of an optoisolator or optocoupler?",
      "answers": [
        "A lens and a photomultiplier",
        "A frequency modulated helium-neon laser",
        "An amplitude modulated helium-neon laser",
        "An LED and a phototransistor"
      ]
    },
    {
      "id": "E6F04",
      "correct": 1,
      "question": "What is the photovoltaic effect?",
      "answers": [
        "The conversion of voltage to current when exposed to light",
        "The conversion of light to electrical energy",
        "The conversion of electrical energy to mechanical energy",
        "The tendency of a battery to discharge when used outside"
      ]
    },
    {
      "id": "E6F05",
      "correct": 0,
      "question": "Which describes an optical shaft encoder?",
      "answers": [
        "A device which detects rotation of a control by interrupting a light source with a patterned wheel",
        "A device which measures the strength of a beam of light using analog to digital conversion",
        "A digital encryption device often used to encrypt spacecraft control signals",
        "A device for generating RTTY signals by means of a rotating light source"
      ]
    },
    {
      "id": "E6F06",
      "correct": 0,
      "question": "Which of these materials is affected the most by photoconductivity?",
      "answers": [
        "A crystalline semiconductor",
        "An ordinary metal",
        "A heavy metal",
        "A liquid semiconductor"
      ]
    },
    {
      "id": "E6F07",
      "correct": 1,
      "question": "What is a solid state relay?",
      "answers": [
        "A relay using transistors to drive the relay coil",
        "A device that uses semiconductors to implement the functions of an electromechanical relay",
        "A mechanical relay that latches in the on or off state each time it is pulsed",
        "A passive delay line"
      ]
    },
    {
      "id": "E6F08",
      "correct": 2,
      "question": "Why are optoisolators often used in conjunction with solid state circuits when switching 120VAC?",
      "answers": [
        "Optoisolators provide a low impedance link between a control circuit and a power circuit",
        "Optoisolators provide impedance matching between the control circuit and power circuit",
        "Optoisolators provide a very high degree of electrical isolation between a control circuit and the circuit being switched",
        "Optoisolators eliminate the effects of reflected light in the control circuit"
      ]
    },
    {
      "id": "E6F09",
      "correct": 3,
      "question": "What is the efficiency of a photovoltaic cell?",
      "answers": [
        "The output RF power divided by the input DC power",
        "The effective payback period",
        "The open-circuit voltage divided by the short-circuit current under full illumination",
        "The relative fraction of light that is converted to current"
      ]
    },
    {
      "id": "E6F10",
      "correct": 1,
      "question": "What is the most common type of photovoltaic cell used for electrical power generation?",
      "answers": [
        "Selenium",
        "Silicon",
        "Cadmium Sulfide",
        "Copper oxide"
      ]
    },
    {
      "id": "E6F11",
      "correct": 1,
      "question": "What is the approximate open-circuit voltage produced by a fully-illuminated silicon photovoltaic cell?",
      "answers": [
        "0.1 V",
        "0.5 V",
        "1.5 V",
        "12 V"
      ]
    },
    {
      "id": "E6F12",
      "correct": 2,
      "question": "What absorbs the energy from light falling on a photovoltaic cell?",
      "answers": [
        "Protons",
        "Photons",
        "Electrons",
        "Holes"
      ]
    },
    {
      "id": "E6F13",
      "correct": 1,
      "question": "What is a liquid crystal display (LCD)?",
      "answers": [
        "A modern replacement for a quartz crystal oscillator which displays its fundamental frequency",
        "A display utilizing a crystalline liquid and polarizing filters which becomes opaque when voltage is applied",
        "A frequency-determining unit for a transmitter or receiver",
        "A display that uses a glowing liquid to remain brightly lit in dim light"
      ]
    },
    {
      "id": "E6F14",
      "correct": 1,
      "question": "Which of the following is true of LCD displays?",
      "answers": [
        "They are hard to view in high ambient light conditions",
        "They may be hard view through polarized lenses",
        "They only display alphanumeric symbols",
        "All of these choices are correct"
      ]
    },
    {
      "id": "E7A01",
      "correct": 2,
      "question": "Which is a bi-stable circuit?",
      "answers": [
        "An \"AND\" gate",
        "An \"OR\" gate",
        "A flip-flop",
        "A clock"
      ]
    },
    {
      "id": "E7A02",
      "correct": 0,
      "question": "What is the function of a decade counter digital IC?",
      "answers": [
        "It produces one output pulse for every ten input pulses",
        "It decodes a decimal number for display on a seven segment LED display",
        "It produces ten output pulses for every input pulse",
        "It adds two decimal numbers together"
      ]
    },
    {
      "id": "E7A03",
      "correct": 1,
      "question": "Which of the following can divide the frequency of a pulse train by 2?",
      "answers": [
        "An XOR gate",
        "A flip-flop",
        "An OR gate",
        "A multiplexer"
      ]
    },
    {
      "id": "E7A04",
      "correct": 1,
      "question": "How many flip-flops are required to divide a signal frequency by 4?",
      "answers": [
        "1",
        "2",
        "4",
        "8"
      ]
    },
    {
      "id": "E7A05",
      "correct": 3,
      "question": "Which of the following is a circuit that continuously alternates between two states without an external clock?",
      "answers": [
        "Monostable multivibrator",
        "J-K flip-flop",
        "T flip-flop",
        "Astable multivibrator"
      ]
    },
    {
      "id": "E7A06",
      "correct": 0,
      "question": "What is a characteristic of a monostable multivibrator?",
      "answers": [
        "It switches momentarily to the opposite binary state and then returns to its original state after a set time",
        "It produces a continuous square wave oscillating between 1 and 0",
        "It stores one bit of data in either a 0 or 1 state",
        "It maintains a constant output voltage, regardless of variations in the input voltage"
      ]
    },
    {
      "id": "E7A07",
      "correct": 3,
      "question": "What logical operation does a NAND gate perform?",
      "answers": [
        "It produces logic \"0\" at its output only when all inputs are logic \"0\"",
        "It produces logic \"1\" at its output only when all inputs are logic \"1\"",
        "It produces logic \"0\" at its output if some but not all inputs are logic \"1\"",
        "It produces logic \"0\" at its output only when all inputs are logic \"1\""
      ]
    },
    {
      "id": "E7A08",
      "correct": 0,
      "question": "What logical operation does an OR gate perform?",
      "answers": [
        "It produces logic \"1\" at its output if any or all inputs are logic \"1\"",
        "It produces logic \"0\" at its output if all inputs are logic \"1\"",
        "It only produces logic \"0\" at its output when all inputs are logic \"1\"",
        "It produces logic \"1\" at its output if all inputs are logic \"0\""
      ]
    },
    {
      "id": "E7A09",
      "correct": 2,
      "question": "What logical operation is performed by an exclusive NOR gate?",
      "answers": [
        "It produces logic \"0\" at its output only if all inputs are logic \"0\"",
        "It produces logic \"1\" at its output only if all inputs are logic \"1\"",
        "It produces logic \"0\" at its output if any single input is logic \"1\"",
        "It produces logic \"1\" at its output if any single input is logic \"1\""
      ]
    },
    {
      "id": "E7A10",
      "correct": 2,
      "question": "What is a truth table?",
      "answers": [
        "A table of logic symbols that indicate the high logic states of an op-amp",
        "A diagram showing logic states when the digital device output is true",
        "A list of inputs and corresponding outputs for a digital device",
        "A table of logic symbols that indicate the logic states of an op-amp"
      ]
    },
    {
      "id": "E7A11",
      "correct": 3,
      "question": "What type of logic defines \"1\" as a high voltage?",
      "answers": [
        "Reverse Logic",
        "Assertive Logic",
        "Negative logic",
        "Positive Logic"
      ]
    },
    {
      "id": "E7A12",
      "correct": 2,
      "question": "What type of logic defines \"0\" as a high voltage?",
      "answers": [
        "Reverse Logic",
        "Assertive Logic",
        "Negative logic",
        "Positive Logic"
      ]
    },
    {
      "id": "E7B01",
      "correct": 0,
      "question": "For what portion of a signal cycle does a Class AB amplifier operate?",
      "answers": [
        "More than 180 degrees but less than 360 degrees",
        "Exactly 180 degrees",
        "The entire cycle",
        "Less than 180 degrees"
      ]
    },
    {
      "id": "E7B02",
      "correct": 0,
      "question": "What is a Class D amplifier?",
      "answers": [
        "A type of amplifier that uses switching technology to achieve high efficiency",
        "A low power amplifier that uses a differential amplifier for improved linearity",
        "An amplifier that uses drift-mode FETs for high efficiency",
        "A frequency doubling amplifier"
      ]
    },
    {
      "id": "E7B03",
      "correct": 0,
      "question": "Which of the following components form the output of a class D amplifier circuit?",
      "answers": [
        "A low-pass filter to remove switching signal components",
        "A high-pass filter to compensate for low gain at low frequencies",
        "A matched load resistor to prevent damage by switching transients",
        "A temperature compensating load resistor to improve linearity"
      ]
    },
    {
      "id": "E7B04",
      "correct": 0,
      "question": "Where on the load line of a Class A common emitter amplifier would bias normally be set?",
      "answers": [
        "Approximately half-way between saturation and cutoff",
        "Where the load line intersects the voltage axis",
        "At a point where the bias resistor equals the load resistor",
        "At a point where the load line intersects the zero bias current curve"
      ]
    },
    {
      "id": "E7B05",
      "correct": 2,
      "question": "What can be done to prevent unwanted oscillations in an RF power amplifier?",
      "answers": [
        "Tune the stage for maximum SWR",
        "Tune both the input and output for maximum power",
        "Install parasitic suppressors and/or neutralize the stage",
        "Use a phase inverter in the output filter"
      ]
    },
    {
      "id": "E7B06",
      "correct": 1,
      "question": "Which of the following amplifier types reduces or eliminates even order harmonics?",
      "answers": [
        "Push-push",
        "Push-pull",
        "Class C",
        "Class AB"
      ]
    },
    {
      "id": "E7B07",
      "correct": 3,
      "question": "Which of the following is a likely result when a Class C amplifier is used to amplify a single-sideband phone signal?",
      "answers": [
        "Reduced intermodulation products",
        "Increased overall intelligibility",
        "Signal inversion",
        "Signal distortion and excessive bandwidth"
      ]
    },
    {
      "id": "E7B08",
      "correct": 2,
      "question": "How can an RF power amplifier be neutralized?",
      "answers": [
        "By increasing the driving power",
        "By reducing the driving power",
        "By feeding a 180-degree out-of-phase portion of the output back to the input",
        "By feeding an in-phase component of the output back to the input"
      ]
    },
    {
      "id": "E7B09",
      "correct": 3,
      "question": "Which of the following describes how the loading and tuning capacitors are to be adjusted when tuning a vacuum tube RF power amplifier that employs a Pi-network output circuit?",
      "answers": [
        "The loading capacitor is set to maximum capacitance and the tuning capacitor is adjusted for minimum allowable plate current",
        "The tuning capacitor is set to maximum capacitance and the loading capacitor is adjusted for minimum plate permissible current",
        "The loading capacitor is adjusted to minimum plate current while alternately adjusting the tuning capacitor for maximum allowable plate current",
        "The tuning capacitor is adjusted for minimum plate current, and the loading capacitor is adjusted for maximum permissible plate current"
      ]
    },
    {
      "id": "E7B10",
      "correct": 1,
      "question": "In Figure E7-1, what is the purpose of R1 and R2?",
      "answers": [
        "Load resistors",
        "Fixed bias",
        "Self bias",
        "Feedback"
      ]
    },
    {
      "id": "E7B11",
      "correct": 3,
      "question": "In Figure E7-1, what is the purpose of R3?",
      "answers": [
        "Fixed bias",
        "Emitter bypass",
        "Output load resistor",
        "Self bias"
      ]
    },
    {
      "id": "E7B12",
      "correct": 2,
      "question": "What type of amplifier circuit is shown in Figure E7-1?",
      "answers": [
        "Common base",
        "Common collector",
        "Common emitter",
        "Emitter follower"
      ]
    },
    {
      "id": "E7B13",
      "correct": 0,
      "question": "In Figure E7-2, what is the purpose of R?",
      "answers": [
        "Emitter load",
        "Fixed bias",
        "Collector load",
        "Voltage regulation"
      ]
    },
    {
      "id": "E7B14",
      "correct": 1,
      "question": "Why are switching amplifiers more efficient than linear amplifiers?",
      "answers": [
        "Switching amplifiers operate at higher voltages",
        "The power transistor is at saturation or cut off most of the time, resulting in low power dissipation",
        "Linear amplifiers have high gain resulting in higher harmonic content",
        "Switching amplifiers use push-pull circuits"
      ]
    },
    {
      "id": "E7B15",
      "correct": 2,
      "question": "What is one way to prevent thermal runaway in a bipolar transistor amplifier?",
      "answers": [
        "Neutralization",
        "Select transistors with high beta",
        "Use a resistor in series with the emitter",
        "All of these choices are correct"
      ]
    },
    {
      "id": "E7B16",
      "correct": 0,
      "question": "What is the effect of intermodulation products in a linear power amplifier?",
      "answers": [
        "Transmission of spurious signals",
        "Creation of parasitic oscillations",
        "Low efficiency",
        "All of these choices are correct"
      ]
    },
    {
      "id": "E7B17",
      "correct": 0,
      "question": "Why are odd-order rather than even-order intermodulation distortion products of concern in linear power amplifiers?",
      "answers": [
        "Because they are relatively close in frequency to the desired signal",
        "Because they are relatively far in frequency from the desired signal",
        "Because they invert the sidebands causing distortion",
        "Because they maintain the sidebands, thus causing multiple duplicate signals"
      ]
    },
    {
      "id": "E7B18",
      "correct": 2,
      "question": "What is a characteristic of a grounded-grid amplifier?",
      "answers": [
        "High power gain",
        "High filament voltage",
        "Low input impedance",
        "Low bandwidth"
      ]
    },
    {
      "id": "E7C01",
      "correct": 3,
      "question": "How are the capacitors and inductors of a low-pass filter Pi-network arranged between the network's input and output?",
      "answers": [
        "Two inductors are in series between the input and output, and a capacitor is connected between the two inductors and ground",
        "Two capacitors are in series between the input and output, and an inductor is connected between the two capacitors and ground",
        "An inductor is connected between the input and ground, another inductor is connected between the output and ground, and a capacitor is connected between the input and output",
        "A capacitor is connected between the input and ground, another capacitor is connected between the output and ground, and an inductor is connected between input and output"
      ]
    },
    {
      "id": "E7C02",
      "correct": 2,
      "question": "Which of the following is a property of a T-network with series capacitors and a parallel shunt inductor?",
      "answers": [
        "It is a low-pass filter",
        "It is a band-pass filter",
        "It is a high-pass filter",
        "It is a notch filter"
      ]
    },
    {
      "id": "E7C03",
      "correct": 0,
      "question": "What advantage does a Pi-L-network have over a regular Pi-network for impedance matching between the final amplifier of a vacuum-tube transmitter and an antenna?",
      "answers": [
        "Greater harmonic suppression",
        "Higher efficiency",
        "Lower losses",
        "Greater transformation range"
      ]
    },
    {
      "id": "E7C04",
      "correct": 2,
      "question": "How does an impedance-matching circuit transform a complex impedance to a resistive impedance?",
      "answers": [
        "It introduces negative resistance to cancel the resistive part of impedance",
        "It introduces transconductance to cancel the reactive part of impedance",
        "It cancels the reactive part of the impedance and changes the resistive part to a desired value",
        "Network resistances are substituted for load resistances and reactances are matched to the resistances"
      ]
    },
    {
      "id": "E7C05",
      "correct": 3,
      "question": "Which filter type is described as having ripple in the passband and a sharp cutoff?",
      "answers": [
        "A Butterworth filter",
        "An active LC filter",
        "A passive op-amp filter",
        "A Chebyshev filter"
      ]
    },
    {
      "id": "E7C06",
      "correct": 2,
      "question": "What are the distinguishing features of an elliptical filter?",
      "answers": [
        "Gradual passband rolloff with minimal stop band ripple",
        "Extremely flat response over its pass band with gradually rounded stop band corners",
        "Extremely sharp cutoff with one or more notches in the stop band",
        "Gradual passband rolloff with extreme stop band ripple"
      ]
    },
    {
      "id": "E7C07",
      "correct": 1,
      "question": "What kind of filter would you use to attenuate an interfering carrier signal while receiving an SSB transmission?",
      "answers": [
        "A band-pass filter",
        "A notch filter",
        "A Pi-network filter",
        "An all-pass filter"
      ]
    },
    {
      "id": "E7C08",
      "correct": 0,
      "question": "Which of the following factors has the greatest effect in helping determine the bandwidth and response shape of a crystal ladder filter?",
      "answers": [
        "The relative frequencies of the individual crystals",
        "The DC voltage applied to the quartz crystal",
        "The gain of the RF stage preceding the filter",
        "The amplitude of the signals passing through the filter"
      ]
    },
    {
      "id": "E7C10",
      "correct": 1,
      "question": "Which of the following filters would be the best choice for use in a 2 meter repeater duplexer?",
      "answers": [
        "A crystal filter",
        "A cavity filter",
        "A DSP filter",
        "An L-C filter"
      ]
    },
    {
      "id": "E7C11",
      "correct": 3,
      "question": "Which of the following is the common name for a filter network which is equivalent to two L-networks connected back-to-back with the two inductors in series and the capacitors in shunt at the input and output?",
      "answers": [
        "Pi-L",
        "Cascode",
        "Omega",
        "Pi"
      ]
    },
    {
      "id": "E7C12",
      "correct": 1,
      "question": "Which describes a Pi-L-network used for matching a vacuum tube final amplifier to a 50 ohm unbalanced output?",
      "answers": [
        "A Phase Inverter Load network",
        "A Pi-network with an additional series inductor on the output",
        "A network with only three discrete parts",
        "A matching network in which all components are isolated from ground"
      ]
    },
    {
      "id": "E7C13",
      "correct": 0,
      "question": "What is one advantage of a Pi-matching network over an L-matching network consisting of a single inductor and a single capacitor?",
      "answers": [
        "The Q of Pi-networks can be varied depending on the component values chosen",
        "L-networks cannot perform impedance transformation",
        "Pi-networks have fewer components",
        "Pi-networks are designed for balanced input and output"
      ]
    },
    {
      "id": "E7C14",
      "correct": 2,
      "question": "Which mode is most affected by non-linear phase response in a receiver IF filter?",
      "answers": [
        "Meteor scatter",
        "Single-Sideband voice",
        "Digital",
        "Video"
      ]
    },
    {
      "id": "E7C15",
      "correct": 3,
      "question": "What is a crystal lattice filter?",
      "answers": [
        "A power supply filter made with interlaced quartz crystals",
        "An audio filter made with four quartz crystals that resonate at 1kHz intervals",
        "A filter with wide bandwidth and shallow skirts made using quartz crystals",
        "A filter with narrow bandwidth and steep skirts made using quartz crystals"
      ]
    },
    {
      "id": "E7D01",
      "correct": 3,
      "question": "What is one characteristic of a linear electronic voltage regulator?",
      "answers": [
        "It has a ramp voltage as its output",
        "It eliminates the need for a pass transistor",
        "The control element duty cycle is proportional to the line or load conditions",
        "The conduction of a control element is varied to maintain a constant output voltage"
      ]
    },
    {
      "id": "E7D02",
      "correct": 2,
      "question": "What is one characteristic of a switching electronic voltage regulator?",
      "answers": [
        "The resistance of a control element is varied in direct proportion to the line voltage or load current",
        "It is generally less efficient than a linear regulator",
        "The controlled device’s duty cycle is changed to produce a constant average output voltage",
        "It gives a ramp voltage at its output"
      ]
    },
    {
      "id": "E7D03",
      "correct": 0,
      "question": "What device is typically used as a stable reference voltage in a linear voltage regulator?",
      "answers": [
        "A Zener diode",
        "A tunnel diode",
        "An SCR",
        "A varactor diode"
      ]
    },
    {
      "id": "E7D04",
      "correct": 1,
      "question": "Which of the following types of linear voltage regulator usually make the most efficient use of the primary power source?",
      "answers": [
        "A series current source",
        "A series regulator",
        "A shunt regulator",
        "A shunt current source"
      ]
    },
    {
      "id": "E7D05",
      "correct": 3,
      "question": "Which of the following types of linear voltage regulator places a constant load on the unregulated voltage source?",
      "answers": [
        "A constant current source",
        "A series regulator",
        "A shunt current source",
        "A shunt regulator"
      ]
    },
    {
      "id": "E7D06",
      "correct": 2,
      "question": "What is the purpose of Q1 in the circuit shown in Figure E7-3?",
      "answers": [
        "It provides negative feedback to improve regulation",
        "It provides a constant load for the voltage source",
        "It increases the current-handling capability of the regulator",
        "It provides D1 with current"
      ]
    },
    {
      "id": "E7D07",
      "correct": 0,
      "question": "What is the purpose of C2 in the circuit shown in Figure E7-3?",
      "answers": [
        "It bypasses hum around D1",
        "It is a brute force filter for the output",
        "To self-resonate at the hum frequency",
        "To provide fixed DC bias for Q1"
      ]
    },
    {
      "id": "E7D08",
      "correct": 2,
      "question": "What type of circuit is shown in Figure E7-3?",
      "answers": [
        "Switching voltage regulator",
        "Grounded emitter amplifier",
        "Linear voltage regulator",
        "Emitter follower"
      ]
    },
    {
      "id": "E7D09",
      "correct": 2,
      "question": "What is the main reason to use a charge controller with a solar power system?",
      "answers": [
        "Prevention of battery undercharge",
        "Control of electrolyte levels during battery discharge",
        "Prevention of battery damage due to overcharge",
        "Matching of day and night charge rates"
      ]
    },
    {
      "id": "E7D10",
      "correct": 2,
      "question": "What is the primary reason that a high-frequency switching type high voltage power supply can be both less expensive and lighter in weight than a conventional power supply?",
      "answers": [
        "The inverter design does not require any output filtering",
        "It uses a diode bridge rectifier for increased output",
        "The high frequency inverter design uses much smaller transformers and filter components for an equivalent power output",
        "It uses a large power factor compensation capacitor to create free power from the unused portion of the AC cycle"
      ]
    },
    {
      "id": "E7D11",
      "correct": 3,
      "question": "What circuit element is controlled by a series analog voltage regulator to maintain a constant output voltage?",
      "answers": [
        "Reference voltage",
        "Switching inductance",
        "Error amplifier",
        "Pass transistor"
      ]
    },
    {
      "id": "E7D12",
      "correct": 2,
      "question": "What is the drop-out voltage of an analog voltage regulator?",
      "answers": [
        "Minimum input voltage for rated power dissipation",
        "Maximum amount that the output voltage drops when the input voltage is varied over its specified range",
        "Minimum input-to-output voltage required to maintain regulation",
        "Maximum amount that the output voltage may decrease at rated load"
      ]
    },
    {
      "id": "E7D13",
      "correct": 2,
      "question": "What is the equation for calculating power dissipation by a series connected linear voltage regulator?",
      "answers": [
        "Input voltage multiplied by input current",
        "Input voltage divided by output current",
        "Voltage difference from input to output multiplied by output current",
        "Output voltage multiplied by output current"
      ]
    },
    {
      "id": "E7D14",
      "correct": 2,
      "question": "What is one purpose of a \"bleeder\" resistor in a conventional unregulated power supply?",
      "answers": [
        "To cut down on waste heat generated by the power supply",
        "To balance the low-voltage filament windings",
        "To improve output voltage regulation",
        "To boost the amount of output current"
      ]
    },
    {
      "id": "E7D15",
      "correct": 3,
      "question": "What is the purpose of a \"step-start\" circuit in a high voltage power supply?",
      "answers": [
        "To provide a dual-voltage output for reduced power applications",
        "To compensate for variations of the incoming line voltage",
        "To allow for remote control of the power supply",
        "To allow the filter capacitors to charge gradually"
      ]
    },
    {
      "id": "E7D16",
      "correct": 3,
      "question": "When several electrolytic filter capacitors are connected in series to increase the operating voltage of a power supply filter circuit, why should resistors be connected across each capacitor?",
      "answers": [
        "To equalize, as much as possible, the voltage drop across each capacitor",
        "To provide a safety bleeder to discharge the capacitors when the supply is off",
        "To provide a minimum load current to reduce voltage excursions at light loads",
        "All of these choices are correct"
      ]
    },
    {
      "id": "E7E01",
      "correct": 1,
      "question": "Which of the following can be used to generate FM phone emissions?",
      "answers": [
        "A balanced modulator on the audio amplifier",
        "A reactance modulator on the oscillator",
        "A reactance modulator on the final amplifier",
        "A balanced modulator on the oscillator"
      ]
    },
    {
      "id": "E7E02",
      "correct": 3,
      "question": "What is the function of a reactance modulator?",
      "answers": [
        "To produce PM signals by using an electrically variable resistance",
        "To produce AM signals by using an electrically variable inductance or capacitance",
        "To produce AM signals by using an electrically variable resistance",
        "To produce PM signals by using an electrically variable inductance or capacitance"
      ]
    },
    {
      "id": "E7E03",
      "correct": 2,
      "question": "How does an analog phase modulator function?",
      "answers": [
        "By varying the tuning of a microphone preamplifier to produce PM signals",
        "By varying the tuning of an amplifier tank circuit to produce AM signals",
        "By varying the tuning of an amplifier tank circuit to produce PM signals",
        "By varying the tuning of a microphone preamplifier to produce AM signals"
      ]
    },
    {
      "id": "E7E04",
      "correct": 0,
      "question": "What is one way a single-sideband phone signal can be generated?",
      "answers": [
        "By using a balanced modulator followed by a filter",
        "By using a reactance modulator followed by a mixer",
        "By using a loop modulator followed by a mixer",
        "By driving a product detector with a DSB signal"
      ]
    },
    {
      "id": "E7E05",
      "correct": 3,
      "question": "What circuit is added to an FM transmitter to boost the higher audio frequencies?",
      "answers": [
        "A de-emphasis network",
        "A heterodyne suppressor",
        "An audio prescaler",
        "A pre-emphasis network"
      ]
    },
    {
      "id": "E7E06",
      "correct": 0,
      "question": "Why is de-emphasis commonly used in FM communications receivers?",
      "answers": [
        "For compatibility with transmitters using phase modulation",
        "To reduce impulse noise reception",
        "For higher efficiency",
        "To remove third-order distortion products"
      ]
    },
    {
      "id": "E7E07",
      "correct": 1,
      "question": "What is meant by the term baseband in radio communications?",
      "answers": [
        "The lowest frequency band that the transmitter or receiver covers",
        "The frequency components present in the modulating signal",
        "The unmodulated bandwidth of the transmitted signal",
        "The basic oscillator frequency in an FM transmitter that is multiplied to increase the deviation and carrier frequency"
      ]
    },
    {
      "id": "E7E08",
      "correct": 2,
      "question": "What are the principal frequencies that appear at the output of a mixer circuit?",
      "answers": [
        "Two and four times the original frequency",
        "The sum, difference and square root of the input frequencies",
        "The two input frequencies along with their sum and difference frequencies",
        "1.414 and 0.707 times the input frequency"
      ]
    },
    {
      "id": "E7E09",
      "correct": 0,
      "question": "What occurs when an excessive amount of signal energy reaches a mixer circuit?",
      "answers": [
        "Spurious mixer products are generated",
        "Mixer blanking occurs",
        "Automatic limiting occurs",
        "A beat frequency is generated"
      ]
    },
    {
      "id": "E7E10",
      "correct": 0,
      "question": "How does a diode detector function?",
      "answers": [
        "By rectification and filtering of RF signals",
        "By breakdown of the Zener voltage",
        "By mixing signals with noise in the transition region of the diode",
        "By sensing the change of reactance in the diode with respect to frequency"
      ]
    },
    {
      "id": "E7E11",
      "correct": 2,
      "question": "Which type of detector is used for demodulating SSB signals?",
      "answers": [
        "Discriminator",
        "Phase detector",
        "Product detector",
        "Phase comparator"
      ]
    },
    {
      "id": "E7E12",
      "correct": 3,
      "question": "What is a frequency discriminator stage in a FM receiver?",
      "answers": [
        "An FM generator circuit",
        "A circuit for filtering two closely adjacent signals",
        "An automatic band-switching circuit",
        "A circuit for detecting FM signals"
      ]
    },
    {
      "id": "E7F01",
      "correct": 2,
      "question": "What is meant by direct digital conversion as applied to software defined radios?",
      "answers": [
        "Software is converted from source code to object code during operation of the receiver",
        "Incoming RF is converted to a control voltage for a voltage controlled oscillator",
        "Incoming RF is digitized by an analog-to-digital converter without being mixed with a local oscillator signal",
        "A switching mixer is used to generate I and Q signals directly from the RF input"
      ]
    },
    {
      "id": "E7F02",
      "correct": 0,
      "question": "What kind of digital signal processing audio filter is used to remove unwanted noise from a received SSB signal?",
      "answers": [
        "An adaptive filter",
        "A crystal-lattice filter",
        "A Hilbert-transform filter",
        "A phase-inverting filter"
      ]
    },
    {
      "id": "E7F03",
      "correct": 2,
      "question": "What type of digital signal processing filter is used to generate an SSB signal?",
      "answers": [
        "An adaptive filter",
        "A notch filter",
        "A Hilbert-transform filter",
        "An elliptical filter"
      ]
    },
    {
      "id": "E7F04",
      "correct": 3,
      "question": "What is a common method of generating an SSB signal using digital signal processing?",
      "answers": [
        "Mixing products are converted to voltages and subtracted by adder circuits",
        "A frequency synthesizer removes the unwanted sidebands",
        "Emulation of quartz crystal filter characteristics",
        "Combine signals with a quadrature phase relationship"
      ]
    },
    {
      "id": "E7F05",
      "correct": 1,
      "question": "How frequently must an analog signal be sampled by an analog-to-digital converter so that the signal can be accurately reproduced?",
      "answers": [
        "At half the rate of the highest frequency component of the signal",
        "At twice the rate of the highest frequency component of the signal",
        "At the same rate as the highest frequency component of the signal",
        "At four times the rate of the highest frequency component of the signal"
      ]
    },
    {
      "id": "E7F06",
      "correct": 3,
      "question": "What is the minimum number of bits required for an analog-to-digital converter to sample a signal with a range of 1 volt at a resolution of 1 millivolt?",
      "answers": [
        "4 bits",
        "6 bits",
        "8 bits",
        "10 bits"
      ]
    },
    {
      "id": "E7F07",
      "correct": 2,
      "question": "What function can a Fast Fourier Transform perform?",
      "answers": [
        "Converting analog signals to digital form",
        "Converting digital signals to analog form",
        "Converting digital signals from the time domain to the frequency domain",
        "Converting 8-bit data to 16 bit data"
      ]
    },
    {
      "id": "E7F08",
      "correct": 1,
      "question": "What is the function of decimation with regard to digital filters?",
      "answers": [
        "Converting data to binary code decimal form",
        "Reducing the effective sample rate by removing samples",
        "Attenuating the signal",
        "Removing unnecessary significant digits"
      ]
    },
    {
      "id": "E7F09",
      "correct": 0,
      "question": "Why is an anti-aliasing digital filter required in a digital decimator?",
      "answers": [
        "It removes high-frequency signal components which would otherwise be reproduced as lower frequency components",
        "It peaks the response of the decimator, improving bandwidth",
        "It removes low frequency signal components to eliminate the need for DC restoration",
        "It notches out the sampling frequency to avoid sampling errors"
      ]
    },
    {
      "id": "E7F10",
      "correct": 0,
      "question": "What aspect of receiver analog-to-digital conversion determines the maximum receive bandwidth of a Direct Digital Conversion SDR?",
      "answers": [
        "Sample rate",
        "Sample width in bits",
        "Sample clock phase noise",
        "Processor latency"
      ]
    },
    {
      "id": "E7F11",
      "correct": 1,
      "question": "What sets the minimum detectable signal level for an SDR in the absence of atmospheric or thermal noise?",
      "answers": [
        "Sample clock phase noise",
        "Reference voltage level and sample width in bits",
        "Data storage transfer rate",
        "Missing codes and jitter"
      ]
    },
    {
      "id": "E7F12",
      "correct": 0,
      "question": "What digital process is applied to I and Q signals in order to recover the baseband modulation information?",
      "answers": [
        "Fast Fourier Transform",
        "Decimation",
        "Signal conditioning",
        "Quadrature mixing"
      ]
    },
    {
      "id": "E7F13",
      "correct": 3,
      "question": "What is the function of taps in a digital signal processing filter?",
      "answers": [
        "To reduce excess signal pressure levels",
        "Provide access for debugging software",
        "Select the point at which baseband signals are generated",
        "Provide incremental signal delays for filter algorithms"
      ]
    },
    {
      "id": "E7F14",
      "correct": 1,
      "question": "Which of the following would allow a digital signal processing filter to create a sharper filter response?",
      "answers": [
        "Higher data rate",
        "More taps",
        "Complex phasor representations",
        "Double-precision math routines"
      ]
    },
    {
      "id": "E7F15",
      "correct": 0,
      "question": "Which of the following is an advantage of a Finite Impulse Response (FIR) filter vs an Infinite Impulse Response (IIR) digital filter?",
      "answers": [
        "FIR filters delay all frequency components of the signal by the same amount",
        "FIR filters are easier to implement for a given set of passband rolloff requirements",
        "FIR filters can respond faster to impulses",
        "All of these choices are correct"
      ]
    },
    {
      "id": "E7F16",
      "correct": 3,
      "question": "How might the sampling rate of an existing digital signal be adjusted by a factor of 3/4?",
      "answers": [
        "Change the gain by a factor of 3/4",
        "Multiply each sample value by a factor of 3/4",
        "Add 3 to each input value and subtract 4 from each output value",
        "Interpolate by a factor of three, then decimate by a factor of four"
      ]
    },
    {
      "id": "E7F17",
      "correct": 3,
      "question": "What do the letters I and Q in I/Q Modulation represent?",
      "answers": [
        "Inactive and Quiescent",
        "Instantaneous and Quasi-stable",
        "Instantaneous and Quenched",
        "In-phase and Quadrature"
      ]
    },
    {
      "id": "E7G01",
      "correct": 0,
      "question": "What is the typical output impedance of an integrated circuit op-amp?",
      "answers": [
        "Very low",
        "Very high",
        "100 ohms",
        "1000 ohms"
      ]
    },
    {
      "id": "E7G02",
      "correct": 3,
      "question": "What is the effect of ringing in a filter?",
      "answers": [
        "An echo caused by a long time delay",
        "A reduction in high frequency response",
        "Partial cancellation of the signal over a range of frequencies",
        "Undesired oscillations added to the desired signal"
      ]
    },
    {
      "id": "E7G03",
      "correct": 3,
      "question": "What is the typical input impedance of an integrated circuit op-amp?",
      "answers": [
        "100 ohms",
        "1000 ohms",
        "Very low",
        "Very high"
      ]
    },
    {
      "id": "E7G04",
      "correct": 2,
      "question": "What is meant by the term op-amp input offset voltage?",
      "answers": [
        "The output voltage of the op-amp minus its input voltage",
        "The difference between the output voltage of the op-amp and the input voltage required in the immediately following stage",
        "The differential input voltage needed to bring the open loop output voltage to zero",
        "The potential between the amplifier input terminals of the op-amp in an open loop condition"
      ]
    },
    {
      "id": "E7G05",
      "correct": 0,
      "question": "How can unwanted ringing and audio instability be prevented in a multi-section op-amp RC audio filter circuit?",
      "answers": [
        "Restrict both gain and Q",
        "Restrict gain but increase Q",
        "Restrict Q but increase gain",
        "Increase both gain and Q"
      ]
    },
    {
      "id": "E7G06",
      "correct": 3,
      "question": "Which of the following is the most appropriate use of an op-amp active filter?",
      "answers": [
        "As a high-pass filter used to block RFI at the input to receivers",
        "As a low-pass filter used between a transmitter and a transmission line",
        "For smoothing power supply output",
        "As an audio filter in a receiver"
      ]
    },
    {
      "id": "E7G07",
      "correct": 2,
      "question": "What magnitude of voltage gain can be expected from the circuit in Figure E7-4 when R1 is 10 ohms and RF is 470 ohms?",
      "answers": [
        "0.21",
        "94",
        "47",
        "24"
      ]
    },
    {
      "id": "E7G08",
      "correct": 3,
      "question": "How does the gain of an ideal operational amplifier vary with frequency?",
      "answers": [
        "It increases linearly with increasing frequency",
        "It decreases linearly with increasing frequency",
        "It decreases logarithmically with increasing frequency",
        "It does not vary with frequency"
      ]
    },
    {
      "id": "E7G09",
      "correct": 3,
      "question": "What will be the output voltage of the circuit shown in Figure E7-4 if R1 is 1000 ohms, RF is 10,000 ohms, and 0.23 volts DC is applied to the input?",
      "answers": [
        "0.23 volts",
        "2.3 volts",
        "-0.23 volts",
        "-2.3 volts"
      ]
    },
    {
      "id": "E7G10",
      "correct": 2,
      "question": "What absolute voltage gain can be expected from the circuit in Figure E7-4 when R1 is 1800 ohms and RF is 68 kilohms?",
      "answers": [
        "1",
        "0.03",
        "38",
        "76"
      ]
    },
    {
      "id": "E7G11",
      "correct": 1,
      "question": "What absolute voltage gain can be expected from the circuit in Figure E7-4 when R1 is 3300 ohms and RF is 47 kilohms?",
      "answers": [
        "28",
        "14",
        "7",
        "0.07"
      ]
    },
    {
      "id": "E7G12",
      "correct": 0,
      "question": "What is an integrated circuit operational amplifier?",
      "answers": [
        "A high-gain, direct-coupled differential amplifier with very high input impedance and very low output impedance",
        "A digital audio amplifier whose characteristics are determined by components external to the amplifier",
        "An amplifier used to increase the average output of frequency modulated amateur signals to the legal limit",
        "A RF amplifier used in the UHF and microwave regions"
      ]
    },
    {
      "id": "E7H01",
      "correct": 3,
      "question": "What are three oscillator circuits used in Amateur Radio equipment?",
      "answers": [
        "Taft, Pierce and negative feedback",
        "Pierce, Fenner and Beane",
        "Taft, Hartley and Pierce",
        "Colpitts, Hartley and Pierce"
      ]
    },
    {
      "id": "E7H02",
      "correct": 2,
      "question": "Which describes a microphonic?",
      "answers": [
        "An IC used for amplifying microphone signals",
        "Distortion caused by RF pickup on the microphone cable",
        "Changes in oscillator frequency due to mechanical vibration",
        "Excess loading of the microphone by an oscillator"
      ]
    },
    {
      "id": "E7H03",
      "correct": 0,
      "question": "How is positive feedback supplied in a Hartley oscillator?",
      "answers": [
        "Through a tapped coil",
        "Through a capacitive divider",
        "Through link coupling",
        "Through a neutralizing capacitor"
      ]
    },
    {
      "id": "E7H04",
      "correct": 2,
      "question": "How is positive feedback supplied in a Colpitts oscillator?",
      "answers": [
        "Through a tapped coil",
        "Through link coupling",
        "Through a capacitive divider",
        "Through a neutralizing capacitor"
      ]
    },
    {
      "id": "E7H05",
      "correct": 3,
      "question": "How is positive feedback supplied in a Pierce oscillator?",
      "answers": [
        "Through a tapped coil",
        "Through link coupling",
        "Through a neutralizing capacitor",
        "Through a quartz crystal"
      ]
    },
    {
      "id": "E7H06",
      "correct": 1,
      "question": "Which of the following oscillator circuits are commonly used in VFOs?",
      "answers": [
        "Pierce and Zener",
        "Colpitts and Hartley",
        "Armstrong and deForest",
        "Negative feedback and balanced feedback"
      ]
    },
    {
      "id": "E7H07",
      "correct": 3,
      "question": "How can an oscillator’s microphonic responses be reduced?",
      "answers": [
        "Use of NP0 capacitors",
        "Eliminating noise on the oscillator’s power supply",
        "Using the oscillator only for CW and digital signals",
        "Mechanically isolating the oscillator circuitry from its enclosure"
      ]
    },
    {
      "id": "E7H08",
      "correct": 0,
      "question": "Which of the following components can be used to reduce thermal drift in crystal oscillators?",
      "answers": [
        "NP0 capacitors",
        "Toroidal inductors",
        "Wirewound resistors",
        "Non-inductive resistors"
      ]
    },
    {
      "id": "E7H09",
      "correct": 0,
      "question": "What type of frequency synthesizer circuit uses a phase accumulator, lookup table, digital to analog converter, and a low-pass anti-alias filter?",
      "answers": [
        "A direct digital synthesizer",
        "A hybrid synthesizer",
        "A phase locked loop synthesizer",
        "A diode-switching matrix synthesizer"
      ]
    },
    {
      "id": "E7H10",
      "correct": 1,
      "question": "What information is contained in the lookup table of a direct digital frequency synthesizer?",
      "answers": [
        "The phase relationship between a reference oscillator and the output waveform",
        "The amplitude values that represent a sine-wave output",
        "The phase relationship between a voltage-controlled oscillator and the output waveform",
        "The synthesizer frequency limits and frequency values stored in the radio memories"
      ]
    },
    {
      "id": "E7H11",
      "correct": 2,
      "question": "What are the major spectral impurity components of direct digital synthesizers?",
      "answers": [
        "Broadband noise",
        "Digital conversion noise",
        "Spurious signals at discrete frequencies",
        "Nyquist limit noise"
      ]
    },
    {
      "id": "E7H12",
      "correct": 1,
      "question": "Which of the following must be done to insure that a crystal oscillator provides the frequency specified by the crystal manufacturer?",
      "answers": [
        "Provide the crystal with a specified parallel inductance",
        "Provide the crystal with a specified parallel capacitance",
        "Bias the crystal at a specified voltage",
        "Bias the crystal at a specified current"
      ]
    },
    {
      "id": "E7H13",
      "correct": 3,
      "question": "Which of the following is a technique for providing highly accurate and stable oscillators needed for microwave transmission and reception?",
      "answers": [
        "Use a GPS signal reference",
        "Use a rubidium stabilized reference oscillator",
        "Use a temperature-controlled high Q dielectric resonator",
        "All of these choices are correct"
      ]
    },
    {
      "id": "E7H14",
      "correct": 2,
      "question": "What is a phase-locked loop circuit?",
      "answers": [
        "An electronic servo loop consisting of a ratio detector, reactance modulator, and voltage-controlled oscillator",
        "An electronic circuit also known as a monostable multivibrator",
        "An electronic servo loop consisting of a phase detector, a low-pass filter, a voltage-controlled oscillator, and a stable reference oscillator",
        "An electronic circuit consisting of a precision push-pull amplifier with a differential input"
      ]
    },
    {
      "id": "E7H15",
      "correct": 3,
      "question": "Which of these functions can be performed by a phase-locked loop?",
      "answers": [
        "Wide-band AF and RF power amplification",
        "Comparison of two digital input signals, digital pulse counter",
        "Photovoltaic conversion, optical coupling",
        "Frequency synthesis, FM demodulation"
      ]
    },
    {
      "id": "E8A01",
      "correct": 0,
      "question": "What is the name of the process that shows that a square wave is made up of a sine wave plus all of its odd harmonics?",
      "answers": [
        "Fourier analysis",
        "Vector analysis",
        "Numerical analysis",
        "Differential analysis"
      ]
    },
    {
      "id": "E8A02",
      "correct": 2,
      "question": "What type of wave has a rise time significantly faster than its fall time (or vice versa)?",
      "answers": [
        "A cosine wave",
        "A square wave",
        "A sawtooth wave",
        "A sine wave"
      ]
    },
    {
      "id": "E8A03",
      "correct": 0,
      "question": "What type of wave does a Fourier analysis show to be made up of sine waves of a given fundamental frequency plus all of its harmonics?",
      "answers": [
        "A sawtooth wave",
        "A square wave",
        "A sine wave",
        "A cosine wave"
      ]
    },
    {
      "id": "E8A04",
      "correct": 1,
      "question": "What is \"dither\" with respect to analog to digital converters?",
      "answers": [
        "An abnormal condition where the converter cannot settle on a value to represent the signal",
        "A small amount of noise added to the input signal to allow more precise representation of a signal over time",
        "An error caused by irregular quantization step size",
        "A method of decimation by randomly skipping samples"
      ]
    },
    {
      "id": "E8A05",
      "correct": 3,
      "question": "What would be the most accurate way of measuring the RMS voltage of a complex waveform?",
      "answers": [
        "By using a grid dip meter",
        "By measuring the voltage with a D'Arsonval meter",
        "By using an absorption wave meter",
        "By measuring the heating effect in a known resistor"
      ]
    },
    {
      "id": "E8A06",
      "correct": 0,
      "question": "What is the approximate ratio of PEP-to-average power in a typical single-sideband phone signal?",
      "answers": [
        "2.5 to 1",
        "25 to 1",
        "1 to 1",
        "100 to 1"
      ]
    },
    {
      "id": "E8A07",
      "correct": 1,
      "question": "What determines the PEP-to-average power ratio of a single-sideband phone signal?",
      "answers": [
        "The frequency of the modulating signal",
        "The characteristics of the modulating signal",
        "The degree of carrier suppression",
        "The amplifier gain"
      ]
    },
    {
      "id": "E8A08",
      "correct": 2,
      "question": "Why would a direct or flash conversion analog-to-digital converter be useful for a software defined radio?",
      "answers": [
        "Very low power consumption decreases frequency drift",
        "Immunity to out of sequence coding reduces spurious responses",
        "Very high speed allows digitizing high frequencies",
        "All of these choices are correct"
      ]
    },
    {
      "id": "E8A09",
      "correct": 3,
      "question": "How many levels can an analog-to-digital converter with 8 bit resolution encode?",
      "answers": [
        "8",
        "8 multiplied by the gain of the input amplifier",
        "256 divided by the gain of the input amplifier",
        "256"
      ]
    },
    {
      "id": "E8A10",
      "correct": 2,
      "question": "What is the purpose of a low pass filter used in conjunction with a digital-to-analog converter?",
      "answers": [
        "Lower the input bandwidth to increase the effective resolution",
        "Improve accuracy by removing out of sequence codes from the input",
        "Remove harmonics from the output caused by the discrete analog levels generated",
        "All of these choices are correct"
      ]
    },
    {
      "id": "E8A11",
      "correct": 3,
      "question": "What type of information can be conveyed using digital waveforms?",
      "answers": [
        "Human speech",
        "Video signals",
        "Data",
        "All of these choices are correct"
      ]
    },
    {
      "id": "E8A12",
      "correct": 2,
      "question": "What is an advantage of using digital signals instead of analog signals to convey the same information?",
      "answers": [
        "Less complex circuitry is required for digital signal generation and detection",
        "Digital signals always occupy a narrower bandwidth",
        "Digital signals can be regenerated multiple times without error",
        "All of these choices are correct"
      ]
    },
    {
      "id": "E8A13",
      "correct": 0,
      "question": "Which of these methods is commonly used to convert analog signals to digital signals?",
      "answers": [
        "Sequential sampling",
        "Harmonic regeneration",
        "Level shifting",
        "Phase reversal"
      ]
    },
    {
      "id": "E8B01",
      "correct": 3,
      "question": "What is the term for the ratio between the frequency deviation of an RF carrier wave and the modulating frequency of its corresponding FM-phone signal?",
      "answers": [
        "FM compressibility",
        "Quieting index",
        "Percentage of modulation",
        "Modulation index"
      ]
    },
    {
      "id": "E8B02",
      "correct": 3,
      "question": "How does the modulation index of a phase-modulated emission vary with RF carrier frequency (the modulated frequency)?",
      "answers": [
        "It increases as the RF carrier frequency increases",
        "It decreases as the RF carrier frequency increases",
        "It varies with the square root of the RF carrier frequency",
        "It does not depend on the RF carrier frequency"
      ]
    },
    {
      "id": "E8B03",
      "correct": 0,
      "question": "What is the modulation index of an FM-phone signal having a maximum frequency deviation of 3000 Hz either side of the carrier frequency when the modulating frequency is 1000 Hz?",
      "answers": [
        "3",
        "0.3",
        "3000",
        "1000"
      ]
    },
    {
      "id": "E8B04",
      "correct": 1,
      "question": "What is the modulation index of an FM-phone signal having a maximum carrier deviation of plus or minus 6 kHz when modulated with a 2 kHz modulating frequency?",
      "answers": [
        "6000",
        "3",
        "2000",
        "1/3"
      ]
    },
    {
      "id": "E8B05",
      "correct": 3,
      "question": "What is the deviation ratio of an FM-phone signal having a maximum frequency swing of plus-or-minus 5 kHz when the maximum modulation frequency is 3 kHz?",
      "answers": [
        "60",
        "0.167",
        "0.6",
        "1.67"
      ]
    },
    {
      "id": "E8B06",
      "correct": 0,
      "question": "What is the deviation ratio of an FM-phone signal having a maximum frequency swing of plus or minus 7.5 kHz when the maximum modulation frequency is 3.5 kHz?",
      "answers": [
        "2.14",
        "0.214",
        "0.47",
        "47"
      ]
    },
    {
      "id": "E8B07",
      "correct": 0,
      "question": "Orthogonal Frequency Division Multiplexing is a technique used for which type of amateur communication?",
      "answers": [
        "High speed digital modes",
        "Extremely low-power contacts",
        "EME",
        "OFDM signals are not allowed on amateur bands"
      ]
    },
    {
      "id": "E8B08",
      "correct": 3,
      "question": "What describes Orthogonal Frequency Division Multiplexing?",
      "answers": [
        "A frequency modulation technique which uses non-harmonically related frequencies",
        "A bandwidth compression technique using Fourier transforms",
        "A digital mode for narrow band, slow speed transmissions",
        "A digital modulation technique using subcarriers at frequencies chosen to avoid intersymbol interference"
      ]
    },
    {
      "id": "E8B09",
      "correct": 1,
      "question": "What is meant by deviation ratio?",
      "answers": [
        "The ratio of the audio modulating frequency to the center carrier frequency",
        "The ratio of the maximum carrier frequency deviation to the highest audio modulating frequency",
        "The ratio of the carrier center frequency to the audio modulating frequency",
        "The ratio of the highest audio modulating frequency to the average audio modulating frequency"
      ]
    },
    {
      "id": "E8B10",
      "correct": 1,
      "question": "What describes frequency division multiplexing?",
      "answers": [
        "The transmitted signal jumps from band to band at a predetermined rate",
        "Two or more information streams are merged into a baseband, which then modulates the transmitter",
        "The transmitted signal is divided into packets of information",
        "Two or more information streams are merged into a digital combiner, which then pulse position modulates the transmitter"
      ]
    },
    {
      "id": "E8B11",
      "correct": 1,
      "question": "What is digital time division multiplexing?",
      "answers": [
        "Two or more data streams are assigned to discrete sub-carriers on an FM transmitter",
        "Two or more signals are arranged to share discrete time slots of a data transmission",
        "Two or more data streams share the same channel by transmitting time of transmission as the sub-carrier",
        "Two or more signals are quadrature modulated to increase bandwidth efficiency"
      ]
    },
    {
      "id": "E8C01",
      "correct": 2,
      "question": "How is Forward Error Correction implemented?",
      "answers": [
        "By the receiving station repeating each block of three data characters",
        "By transmitting a special algorithm to the receiving station along with the data characters",
        "By transmitting extra data that may be used to detect and correct transmission errors",
        "By varying the frequency shift of the transmitted signal according to a predefined algorithm"
      ]
    },
    {
      "id": "E8C02",
      "correct": 2,
      "question": "What is the definition of symbol rate in a digital transmission?",
      "answers": [
        "The number of control characters in a message packet",
        "The duration of each bit in a message sent over the air",
        "The rate at which the waveform of a transmitted signal changes to convey information",
        "The number of characters carried per second by the station-to-station link "
      ]
    },
    {
      "id": "E8C03",
      "correct": 0,
      "question": "When performing phase shift keying, why is it advantageous to shift phase precisely at the zero crossing of the RF carrier?",
      "answers": [
        "This results in the least possible transmitted bandwidth for the particular mode",
        "It is easier to demodulate with a conventional, non-synchronous detector",
        "It improves carrier suppression",
        "All of these choices are correct"
      ]
    },
    {
      "id": "E8C04",
      "correct": 2,
      "question": "What technique is used to minimize the bandwidth requirements of a PSK31 signal?",
      "answers": [
        "Zero-sum character encoding",
        "Reed-Solomon character encoding",
        "Use of sinusoidal data pulses",
        "Use of trapezoidal data pulses"
      ]
    },
    {
      "id": "E8C05",
      "correct": 2,
      "question": "What is the necessary bandwidth of a 13-WPM international Morse code transmission?",
      "answers": [
        "Approximately 13 Hz",
        "Approximately 26 Hz",
        "Approximately 52 Hz",
        "Approximately 104 Hz"
      ]
    },
    {
      "id": "E8C06",
      "correct": 2,
      "question": "What is the necessary bandwidth of a 170-hertz shift, 300-baud ASCII transmission?",
      "answers": [
        "0.1 Hz",
        "0.3 kHz",
        "0.5 kHz",
        "1.0 kHz"
      ]
    },
    {
      "id": "E8C07",
      "correct": 0,
      "question": "What is the necessary bandwidth of a 4800-Hz frequency shift, 9600-baud ASCII FM transmission?",
      "answers": [
        "15.36 kHz",
        "9.6 kHz",
        "4.8 kHz",
        "5.76 kHz"
      ]
    },
    {
      "id": "E8C08",
      "correct": 3,
      "question": "How does ARQ accomplish error correction?",
      "answers": [
        "Special binary codes provide automatic correction",
        "Special polynomial codes provide automatic correction",
        "If errors are detected, redundant data is substituted",
        "If errors are detected, a retransmission is requested"
      ]
    },
    {
      "id": "E8C09",
      "correct": 3,
      "question": "Which is the name of a digital code where each preceding or following character changes by only one bit?",
      "answers": [
        "Binary Coded Decimal Code",
        "Extended Binary Coded Decimal Interchange Code",
        "Excess 3 code",
        "Gray code"
      ]
    },
    {
      "id": "E8C10",
      "correct": 3,
      "question": "What is an advantage of Gray code in digital communications where symbols are transmitted as multiple bits",
      "answers": [
        "It increases security",
        "It has more possible states than simple binary",
        "It has more resolution than simple binary",
        "It facilitates error detection"
      ]
    },
    {
      "id": "E8C11",
      "correct": 0,
      "question": "What is the relationship between symbol rate and baud?",
      "answers": [
        "They are the same",
        "Baud is twice the symbol rate",
        "Symbol rate is only used for packet-based modes",
        "Baud is only used for RTTY"
      ]
    },
    {
      "id": "E8D01",
      "correct": 0,
      "question": "Why are received spread spectrum signals resistant to interference?",
      "answers": [
        "Signals not using the spread spectrum algorithm are suppressed in the receiver",
        "The high power used by a spread spectrum transmitter keeps its signal from being easily overpowered",
        "The receiver is always equipped with a digital blanker",
        "If interference is detected by the receiver it will signal the transmitter to change frequencies"
      ]
    },
    {
      "id": "E8D02",
      "correct": 1,
      "question": "What spread spectrum communications technique uses a high speed binary bit stream to shift the phase of an RF carrier?",
      "answers": [
        "Frequency hopping",
        "Direct sequence",
        "Binary phase-shift keying",
        "Phase compandored spread spectrum"
      ]
    },
    {
      "id": "E8D03",
      "correct": 3,
      "question": "How does the spread spectrum technique of frequency hopping work?",
      "answers": [
        "If interference is detected by the receiver it will signal the transmitter to change frequencies",
        "If interference is detected by the receiver it will signal the transmitter to wait until the frequency is clear",
        "A pseudo-random binary bit stream is used to shift the phase of an RF carrier very rapidly in a particular sequence",
        "The frequency of the transmitted signal is changed very rapidly according to a particular sequence also used by the receiving station"
      ]
    },
    {
      "id": "E8D04",
      "correct": 2,
      "question": "What is the primary effect of extremely short rise or fall time on a CW signal?",
      "answers": [
        "More difficult to copy",
        "The generation of RF harmonics",
        "The generation of key clicks",
        "Limits data speed"
      ]
    },
    {
      "id": "E8D05",
      "correct": 0,
      "question": "What is the most common method of reducing key clicks?",
      "answers": [
        "Increase keying waveform rise and fall times",
        "Low-pass filters at the transmitter output",
        "Reduce keying waveform rise and fall times",
        "High-pass filters at the transmitter output"
      ]
    },
    {
      "id": "E8D06",
      "correct": 1,
      "question": "Which of the following indicates likely overmodulation of an AFSK signal such as PSK or MFSK?",
      "answers": [
        "High reflected power",
        "Strong ALC action",
        "Harmonics on higher bands",
        "Rapid signal fading"
      ]
    },
    {
      "id": "E8D07",
      "correct": 3,
      "question": "What is a common cause of overmodulation of AFSK signals?",
      "answers": [
        "Excessive numbers of retries",
        "Ground loops",
        "Bit errors in the modem",
        "Excessive transmit audio levels"
      ]
    },
    {
      "id": "E8D08",
      "correct": 3,
      "question": "What parameter might indicate that excessively high input levels are causing distortion in an AFSK signal?",
      "answers": [
        "Signal to noise ratio",
        "Baud rate",
        "Repeat Request Rate (RRR)",
        "Intermodulation Distortion (IMD)"
      ]
    },
    {
      "id": "E8D09",
      "correct": 3,
      "question": "What is considered a good minimum IMD level for an idling PSK signal?",
      "answers": [
        "+10 dB",
        "+15 dB",
        "-20 dB",
        "-30 dB"
      ]
    },
    {
      "id": "E8D10",
      "correct": 1,
      "question": "What are some of the differences between the Baudot digital code and ASCII?",
      "answers": [
        "Baudot uses 4 data bits per character, ASCII uses 7 or 8; Baudot uses 1 character as a letters/figures shift code, ASCII has no letters/figures code",
        "Baudot uses 5 data bits per character, ASCII uses 7 or 8; Baudot uses 2 characters as letters/figures shift codes, ASCII has no letters/figures shift code",
        "Baudot uses 6 data bits per character, ASCII uses 7 or 8; Baudot has no letters/figures shift code, ASCII uses 2 letters/figures shift codes",
        "Baudot uses 7 data bits per character, ASCII uses 8; Baudot has no letters/figures shift code, ASCII uses 2 letters/figures shift codes"
      ]
    },
    {
      "id": "E8D11",
      "correct": 2,
      "question": "What is one advantage of using ASCII code for data communications?",
      "answers": [
        "It includes built in error correction features",
        "It contains fewer information bits per character than any other code",
        "It is possible to transmit both upper and lower case text",
        "It uses one character as a shift code to send numeric and special characters"
      ]
    },
    {
      "id": "E9A01",
      "correct": 2,
      "question": "What describes an isotropic antenna?",
      "answers": [
        "A grounded antenna used to measure earth conductivity",
        "A horizontally polarized antenna used to compare Yagi antennas",
        "A theoretical antenna used as a reference for antenna gain",
        "A spacecraft antenna used to direct signals toward the earth"
      ]
    },
    {
      "id": "E9A02",
      "correct": 3,
      "question": "What antenna has no gain in any direction?",
      "answers": [
        "Quarter-wave vertical",
        "Yagi",
        "Half-wave dipole",
        "Isotropic antenna"
      ]
    },
    {
      "id": "E9A03",
      "correct": 0,
      "question": "Why would one need to know the feed point impedance of an antenna?",
      "answers": [
        "To match impedances in order to minimize standing wave ratio on the transmission line",
        "To measure the near-field radiation density from a transmitting antenna",
        "To calculate the front-to-side ratio of the antenna",
        "To calculate the front-to-back ratio of the antenna"
      ]
    },
    {
      "id": "E9A04",
      "correct": 1,
      "question": "Which of the following factors may affect the feed point impedance of an antenna?",
      "answers": [
        "Transmission-line length",
        "Antenna height, conductor length/diameter ratio and location of nearby conductive objects",
        "The settings of an antenna tuner at the transmitter",
        "Sunspot activity and time of day"
      ]
    },
    {
      "id": "E9A05",
      "correct": 3,
      "question": "What is included in the total resistance of an antenna system?",
      "answers": [
        "Radiation resistance plus space impedance",
        "Radiation resistance plus transmission resistance",
        "Transmission-line resistance plus radiation resistance",
        "Radiation resistance plus ohmic resistance"
      ]
    },
    {
      "id": "E9A06",
      "correct": 3,
      "question": "How does the beamwidth of an antenna vary as the gain is increased?",
      "answers": [
        "It increases geometrically",
        "It increases arithmetically",
        "It is essentially unaffected",
        "It decreases"
      ]
    },
    {
      "id": "E9A07",
      "correct": 0,
      "question": "What is meant by antenna gain?",
      "answers": [
        "The ratio of the radiated signal strength of an antenna in the direction of maximum radiation to that of a reference antenna",
        "The ratio of the signal in the forward direction to that in the opposite direction",
        "The ratio of the amount of power radiated by an antenna compared to the transmitter output power",
        "The final amplifier gain minus the transmission line losses"
      ]
    },
    {
      "id": "E9A08",
      "correct": 1,
      "question": "What is meant by antenna bandwidth?",
      "answers": [
        "Antenna length divided by the number of elements",
        "The frequency range over which an antenna satisfies a performance requirement",
        "The angle between the half-power radiation points",
        "The angle formed between two imaginary lines drawn through the element ends"
      ]
    },
    {
      "id": "E9A09",
      "correct": 1,
      "question": "How is antenna efficiency calculated?",
      "answers": [
        "(radiation resistance / transmission resistance) x 100 per cent",
        "(radiation resistance / total resistance) x 100 per cent",
        "(total resistance / radiation resistance) x 100 per cent",
        "(effective radiated power / transmitter output) x 100 percent"
      ]
    },
    {
      "id": "E9A10",
      "correct": 0,
      "question": "Which of the following choices is a way to improve the efficiency of a ground-mounted quarter-wave vertical antenna?",
      "answers": [
        "Install a good radial system",
        "Isolate the coax shield from ground",
        "Shorten the radiating element",
        "Reduce the diameter of the radiating element"
      ]
    },
    {
      "id": "E9A11",
      "correct": 2,
      "question": "Which of the following factors determines ground losses for a ground-mounted vertical antenna operating in the 3 MHz to 30 MHz range?",
      "answers": [
        "The standing wave ratio",
        "Distance from the transmitter",
        "Soil conductivity",
        "Take-off angle"
      ]
    },
    {
      "id": "E9A12",
      "correct": 0,
      "question": "How much gain does an antenna have compared to a 1/2-wavelength dipole when it has 6 dB gain over an isotropic antenna?",
      "answers": [
        "3.85 dB",
        "6.0 dB",
        "8.15 dB",
        "2.79 dB"
      ]
    },
    {
      "id": "E9A13",
      "correct": 1,
      "question": "How much gain does an antenna have compared to a 1/2-wavelength dipole when it has 12 dB gain over an isotropic antenna?",
      "answers": [
        "6.17 dB",
        "9.85 dB",
        "12.5 dB",
        "14.15 dB"
      ]
    },
    {
      "id": "E9A14",
      "correct": 2,
      "question": "What is meant by the radiation resistance of an antenna?",
      "answers": [
        "The combined losses of the antenna elements and feed line",
        "The specific impedance of the antenna",
        "The value of a resistance that would dissipate the same amount of power as that radiated from an antenna",
        "The resistance in the atmosphere that an antenna must overcome to be able to radiate a signal"
      ]
    },
    {
      "id": "E9A15",
      "correct": 3,
      "question": "What is the effective radiated power relative to a dipole of a repeater station with 150 watts transmitter power output, 2 dB feed line loss, 2.2 dB duplexer loss, and 7 dBd antenna gain?",
      "answers": [
        "1977 watts",
        "78.7 watts",
        "420 watts",
        "286 watts"
      ]
    },
    {
      "id": "E9A16",
      "correct": 0,
      "question": "What is the effective radiated power relative to a dipole of a repeater station with 200 watts transmitter power output, 4 dB feed line loss, 3.2 dB duplexer loss, 0.8 dB circulator loss, and 10 dBd antenna gain?",
      "answers": [
        "317 watts",
        "2000 watts",
        "126 watts",
        "300 watts"
      ]
    },
    {
      "id": "E9A17",
      "correct": 1,
      "question": "What is the effective radiated power of a repeater station with 200 watts transmitter power output, 2 dB feed line loss, 2.8 dB duplexer loss, 1.2 dB circulator loss, and 7 dBi antenna gain?",
      "answers": [
        "159 watts",
        "252 watts",
        "632 watts",
        "63.2 watts"
      ]
    },
    {
      "id": "E9A18",
      "correct": 2,
      "question": "What term describes station output, taking into account all gains and losses?",
      "answers": [
        "Power factor",
        "Half-power bandwidth",
        "Effective radiated power",
        "Apparent power"
      ]
    },
    {
      "id": "E9B01",
      "correct": 1,
      "question": "In the antenna radiation pattern shown in Figure E9-1, what is the 3 dB beam-width?",
      "answers": [
        "75 degrees",
        "50 degrees",
        "25 degrees",
        "30 degrees"
      ]
    },
    {
      "id": "E9B02",
      "correct": 1,
      "question": "In the antenna radiation pattern shown in Figure E9-1, what is the front-to-back ratio?",
      "answers": [
        "36 dB",
        "18 dB",
        "24 dB",
        "14 dB"
      ]
    },
    {
      "id": "E9B03",
      "correct": 1,
      "question": "In the antenna radiation pattern shown in Figure E9-1, what is the front-to-side ratio?",
      "answers": [
        "12 dB",
        "14 dB",
        "18 dB",
        "24 dB"
      ]
    },
    {
      "id": "E9B04",
      "correct": 3,
      "question": "What may occur when a directional antenna is operated at different frequencies within the band for which it was designed?",
      "answers": [
        "Feed point impedance may become negative",
        "The E-field and H-field patterns may reverse",
        "Element spacing limits could be exceeded",
        "The gain may change depending on frequency"
      ]
    },
    {
      "id": "E9B05",
      "correct": 0,
      "question": "What type of antenna pattern over real ground is shown in Figure E9-2?",
      "answers": [
        "Elevation",
        "Azimuth",
        "Radiation resistance",
        "Polarization"
      ]
    },
    {
      "id": "E9B06",
      "correct": 2,
      "question": "What is the elevation angle of peak response in the antenna radiation pattern shown in Figure E9-2?",
      "answers": [
        "45 degrees",
        "75 degrees",
        "7.5 degrees",
        "25 degrees"
      ]
    },
    {
      "id": "E9B07",
      "correct": 2,
      "question": "How does the total amount of radiation emitted by a directional gain antenna compare with the total amount of radiation emitted from an isotropic antenna, assuming each is driven by the same amount of power?",
      "answers": [
        "The total amount of radiation from the directional antenna is increased by the gain of the antenna",
        "The total amount of radiation from the directional antenna is stronger by its front-to-back ratio",
        "They are the same",
        "The radiation from the isotropic antenna is 2.15 dB stronger than that from the directional antenna"
      ]
    },
    {
      "id": "E9B08",
      "correct": 0,
      "question": "How can the approximate beam-width in a given plane of a directional antenna be determined?",
      "answers": [
        "Note the two points where the signal strength of the antenna is 3 dB less than maximum and compute the angular difference",
        "Measure the ratio of the signal strengths of the radiated power lobes from the front and rear of the antenna",
        "Draw two imaginary lines through the ends of the elements and measure the angle between the lines",
        "Measure the ratio of the signal strengths of the radiated power lobes from the front and side of the antenna"
      ]
    },
    {
      "id": "E9B09",
      "correct": 1,
      "question": "What type of computer program technique is commonly used for modeling antennas?",
      "answers": [
        "Graphical analysis",
        "Method of Moments",
        "Mutual impedance analysis",
        "Calculus differentiation with respect to physical properties"
      ]
    },
    {
      "id": "E9B10",
      "correct": 0,
      "question": "What is the principle of a Method of Moments analysis?",
      "answers": [
        "A wire is modeled as a series of segments, each having a uniform value of current",
        "A wire is modeled as a single sine-wave current generator",
        "A wire is modeled as a series of points, each having a distinct location in space",
        "A wire is modeled as a series of segments, each having a distinct value of voltage across it"
      ]
    },
    {
      "id": "E9B11",
      "correct": 2,
      "question": "What is a disadvantage of decreasing the number of wire segments in an antenna model below the guideline of 10 segments per half-wavelength?",
      "answers": [
        "Ground conductivity will not be accurately modeled",
        "The resulting design will favor radiation of harmonic energy",
        "The computed feed point impedance may be incorrect",
        "The antenna will become mechanically unstable"
      ]
    },
    {
      "id": "E9B12",
      "correct": 3,
      "question": "What is the far field of an antenna?",
      "answers": [
        "The region of the ionosphere where radiated power is not refracted",
        "The region where radiated power dissipates over a specified time period",
        "The region where radiated field strengths are obstructed by objects of reflection",
        "The region where the shape of the antenna pattern is independent of distance"
      ]
    },
    {
      "id": "E9B13",
      "correct": 1,
      "question": "What does the abbreviation NEC stand for when applied to antenna modeling programs?",
      "answers": [
        "Next Element Comparison",
        "Numerical Electromagnetic Code",
        "National Electrical Code",
        "Numeric Electrical Computation"
      ]
    },
    {
      "id": "E9B14",
      "correct": 3,
      "question": "What type of information can be obtained by submitting the details of a proposed new antenna to a modeling program?",
      "answers": [
        "SWR vs frequency charts",
        "Polar plots of the far field elevation and azimuth patterns",
        "Antenna gain",
        "All of these choices are correct"
      ]
    },
    {
      "id": "E9B15",
      "correct": 1,
      "question": "What is the front-to-back ratio of the radiation pattern shown in Figure E9-2?",
      "answers": [
        "15 dB",
        "28 dB",
        "3 dB",
        "24 dB"
      ]
    },
    {
      "id": "E9B16",
      "correct": 0,
      "question": "How many elevation lobes appear in the forward direction of the antenna radiation pattern shown in Figure E9-2?",
      "answers": [
        "4",
        "3",
        "1",
        "7"
      ]
    },
    {
      "id": "E9C01",
      "correct": 3,
      "question": "What is the radiation pattern of two 1/4-wavelength vertical antennas spaced 1/2-wavelength apart and fed 180 degrees out of phase?",
      "answers": [
        "Cardioid",
        "Omni-directional",
        "A figure-8 broadside to the axis of the array",
        "A figure-8 oriented along the axis of the array"
      ]
    },
    {
      "id": "E9C02",
      "correct": 0,
      "question": "What is the radiation pattern of two 1/4 wavelength vertical antennas spaced 1/4 wavelength apart and fed 90 degrees out of phase?",
      "answers": [
        "Cardioid",
        "A figure-8 end-fire along the axis of the array",
        "A figure-8 broadside to the axis of the array",
        "Omni-directional"
      ]
    },
    {
      "id": "E9C03",
      "correct": 2,
      "question": "What is the radiation pattern of two 1/4 wavelength vertical antennas spaced a 1/2 wavelength apart and fed in phase?",
      "answers": [
        "Omni-directional",
        "Cardioid",
        "A Figure-8 broadside to the axis of the array",
        "A Figure-8 end-fire along the axis of the array"
      ]
    },
    {
      "id": "E9C04",
      "correct": 1,
      "question": "What happens to the radiation pattern of an unterminated long wire antenna as the wire length is increased?",
      "answers": [
        "The lobes become more perpendicular to the wire",
        "The lobes align more in the direction of the wire",
        "The vertical angle increases",
        "The front-to-back ratio decreases"
      ]
    },
    {
      "id": "E9C05",
      "correct": 0,
      "question": "What is an OCFD antenna?",
      "answers": [
        "A dipole feed approximately 1/3 the way from one end with a 4:1 balun to provide multiband operation",
        "A remotely tunable dipole antenna using orthogonally controlled frequency diversity",
        "An eight band dipole antenna using octophase filters",
        "A multiband dipole antenna using one-way circular polarization for frequency diversity"
      ]
    },
    {
      "id": "E9C06",
      "correct": 1,
      "question": "What is the effect of a terminating resistor on a rhombic antenna?",
      "answers": [
        "It reflects the standing waves on the antenna elements back to the transmitter",
        "It changes the radiation pattern from bidirectional to unidirectional",
        "It changes the radiation pattern from horizontal to vertical polarization",
        "It decreases the ground loss"
      ]
    },
    {
      "id": "E9C07",
      "correct": 0,
      "question": "What is the approximate feed point impedance at the center of a two-wire folded dipole antenna?",
      "answers": [
        "300 ohms",
        "72 ohms",
        "50 ohms",
        "450 ohms"
      ]
    },
    {
      "id": "E9C08",
      "correct": 2,
      "question": "What is a folded dipole antenna?",
      "answers": [
        "A dipole one-quarter wavelength long",
        "A type of ground-plane antenna",
        "A dipole consisting of one wavelength of wire forming a very thin loop",
        "A dipole configured to provide forward gain"
      ]
    },
    {
      "id": "E9C09",
      "correct": 0,
      "question": "What is a G5RV antenna?",
      "answers": [
        "A multi-band dipole antenna fed with coax and a balun through a selected length of open wire transmission line",
        "A multi-band trap antenna",
        "A phased array antenna consisting of multiple loops",
        "A wide band dipole using shorted coaxial cable for the radiating elements and fed with a 4:1 balun"
      ]
    },
    {
      "id": "E9C10",
      "correct": 1,
      "question": "Which of the following describes a Zepp antenna?",
      "answers": [
        "A dipole constructed from zip cord",
        "An end fed dipole antenna",
        "An omni-directional antenna commonly used for satellite communications",
        "A vertical array capable of quickly changing the direction of maximum radiation by changing phasing lines"
      ]
    },
    {
      "id": "E9C11",
      "correct": 3,
      "question": "How is the far-field elevation pattern of a vertically polarized antenna affected by being mounted over seawater versus rocky ground?",
      "answers": [
        "The low-angle radiation decreases",
        "The high-angle radiation increases",
        "Both the high-angle and low-angle radiation decrease",
        "The low-angle radiation increases"
      ]
    },
    {
      "id": "E9C12",
      "correct": 2,
      "question": "Which of the following describes an extended double Zepp antenna?",
      "answers": [
        "A wideband vertical antenna constructed from precisely tapered aluminum tubing",
        "A portable antenna erected using two push support poles",
        "A center fed 1.25 wavelength antenna (two 5/8 wave elements in phase)",
        "An end fed folded dipole antenna"
      ]
    },
    {
      "id": "E9C13",
      "correct": 2,
      "question": "What is the main effect of placing a vertical antenna over an imperfect ground?",
      "answers": [
        "It causes increased SWR",
        "It changes the impedance angle of the matching network",
        "It reduces low-angle radiation",
        "It reduces losses in the radiating portion of the antenna"
      ]
    },
    {
      "id": "E9C14",
      "correct": 1,
      "question": "How does the performance of a horizontally polarized antenna mounted on the side of a hill compare with the same antenna mounted on flat ground?",
      "answers": [
        "The main lobe takeoff angle increases in the downhill direction",
        "The main lobe takeoff angle decreases in the downhill direction",
        "The horizontal beam width decreases in the downhill direction",
        "The horizontal beam width increases in the uphill direction"
      ]
    },
    {
      "id": "E9C15",
      "correct": 1,
      "question": "How does the radiation pattern of a horizontally polarized 3-element beam antenna vary with its height above ground?",
      "answers": [
        "The main lobe takeoff angle increases with increasing height",
        "The main lobe takeoff angle decreases with increasing height",
        "The horizontal beam width increases with height",
        "The horizontal beam width decreases with height"
      ]
    },
    {
      "id": "E9D01",
      "correct": 2,
      "question": "How does the gain of an ideal parabolic dish antenna change when the operating frequency is doubled?",
      "answers": [
        "Gain does not change",
        "Gain is multiplied by 0.707",
        "Gain increases by 6 dB",
        "Gain increases by 3 dB"
      ]
    },
    {
      "id": "E9D02",
      "correct": 2,
      "question": "How can linearly polarized Yagi antennas be used to produce circular polarization?",
      "answers": [
        "Stack two Yagis fed 90 degrees out of phase to form an array with the respective elements in parallel planes",
        "Stack two Yagis fed in phase to form an array with the respective elements in parallel planes",
        "Arrange two Yagis perpendicular to each other with the driven elements at the same point on the boom fed 90 degrees out of phase",
        "Arrange two Yagis collinear to each other with the driven elements fed 180 degrees out of phase"
      ]
    },
    {
      "id": "E9D03",
      "correct": 0,
      "question": "Where should a high Q loading coil be placed to minimize losses in a shortened vertical antenna?",
      "answers": [
        "Near the center of the vertical radiator",
        "As low as possible on the vertical radiator",
        "As close to the transmitter as possible",
        "At a voltage node"
      ]
    },
    {
      "id": "E9D04",
      "correct": 2,
      "question": "Why should an HF mobile antenna loading coil have a high ratio of reactance to resistance?",
      "answers": [
        "To swamp out harmonics",
        "To maximize losses",
        "To minimize losses",
        "To minimize the Q"
      ]
    },
    {
      "id": "E9D05",
      "correct": 0,
      "question": "What is a disadvantage of using a multiband trapped antenna?",
      "answers": [
        "It might radiate harmonics",
        "It radiates the harmonics and fundamental equally well",
        "It is too sharply directional at lower frequencies",
        "It must be neutralized"
      ]
    },
    {
      "id": "E9D06",
      "correct": 1,
      "question": "What happens to the bandwidth of an antenna as it is shortened through the use of loading coils?",
      "answers": [
        "It is increased",
        "It is decreased",
        "No change occurs",
        "It becomes flat"
      ]
    },
    {
      "id": "E9D07",
      "correct": 3,
      "question": "What is an advantage of using top loading in a shortened HF vertical antenna?",
      "answers": [
        "Lower Q",
        "Greater structural strength",
        "Higher losses",
        "Improved radiation efficiency"
      ]
    },
    {
      "id": "E9D08",
      "correct": 1,
      "question": "What happens as the Q of an antenna increases?",
      "answers": [
        "SWR bandwidth increases",
        "SWR bandwidth decreases",
        "Gain is reduced",
        "More common-mode current is present on the feed line"
      ]
    },
    {
      "id": "E9D09",
      "correct": 3,
      "question": "What is the function of a loading coil used as part of an HF mobile antenna?",
      "answers": [
        "To increase the SWR bandwidth",
        "To lower the losses",
        "To lower the Q",
        "To cancel capacitive reactance"
      ]
    },
    {
      "id": "E9D10",
      "correct": 1,
      "question": "What happens to feed point impedance at the base of a fixed length HF mobile antenna as the frequency of operation is lowered?",
      "answers": [
        "The radiation resistance decreases and the capacitive reactance decreases",
        "The radiation resistance decreases and the capacitive reactance increases",
        "The radiation resistance increases and the capacitive reactance decreases",
        "The radiation resistance increases and the capacitive reactance increases"
      ]
    },
    {
      "id": "E9D11",
      "correct": 1,
      "question": "Which of the following types of conductors would be best for minimizing losses in a station's RF ground system?",
      "answers": [
        "A resistive wire, such as spark plug wire",
        "A wide flat copper strap",
        "A cable with six or seven 18 gauge conductors in parallel",
        "A single 12 gauge or 10 gauge stainless steel wire"
      ]
    },
    {
      "id": "E9D12",
      "correct": 2,
      "question": "Which of the following would provide the best RF ground for your station?",
      "answers": [
        "A 50 ohm resistor connected to ground",
        "An electrically short connection to a metal water pipe",
        "An electrically short connection to 3 or 4 interconnected ground rods driven into the Earth",
        "An electrically short connection to 3 or 4 interconnected ground rods via a series RF choke"
      ]
    },
    {
      "id": "E9D13",
      "correct": 1,
      "question": "What usually occurs if a Yagi antenna is designed solely for maximum forward gain?",
      "answers": [
        "The front-to-back ratio increases",
        "The front-to-back ratio decreases",
        "The frequency response is widened over the whole frequency band",
        "The SWR is reduced"
      ]
    },
    {
      "id": "E9E01",
      "correct": 1,
      "question": "What system matches a higher impedance transmission line to a lower impedance antenna by connecting the line to the driven element in two places spaced a fraction of a wavelength each side of element center?",
      "answers": [
        "The gamma matching system",
        "The delta matching system",
        "The omega matching system",
        "The stub matching system"
      ]
    },
    {
      "id": "E9E02",
      "correct": 0,
      "question": "What is the name of an antenna matching system that matches an unbalanced feed line to an antenna by feeding the driven element both at the center of the element and at a fraction of a wavelength to one side of center?",
      "answers": [
        "The gamma match",
        "The delta match",
        "The epsilon match",
        "The stub match"
      ]
    },
    {
      "id": "E9E03",
      "correct": 3,
      "question": "What is the name of the matching system that uses a section of transmission line connected in parallel with the feed line at or near the feed point?",
      "answers": [
        "The gamma match",
        "The delta match",
        "The omega match",
        "The stub match"
      ]
    },
    {
      "id": "E9E04",
      "correct": 1,
      "question": "What is the purpose of the series capacitor in a gamma-type antenna matching network?",
      "answers": [
        "To provide DC isolation between the feed line and the antenna",
        "To cancel the inductive reactance of the matching network",
        "To provide a rejection notch that prevents the radiation of harmonics",
        "To transform the antenna impedance to a higher value"
      ]
    },
    {
      "id": "E9E05",
      "correct": 0,
      "question": "How must the driven element in a 3-element Yagi be tuned to use a hairpin matching system?",
      "answers": [
        "The driven element reactance must be capacitive",
        "The driven element reactance must be inductive",
        "The driven element resonance must be lower than the operating frequency",
        "The driven element radiation resistance must be higher than the characteristic impedance of the transmission line"
      ]
    },
    {
      "id": "What",
      "correct": 50,
      "question": "3-element Yagi?",
      "answers": [
        "Pi-network",
        "Pi-L-network",
        "A shunt inductor",
        "A series capacitor"
      ]
    },
    {
      "id": "E9E07",
      "correct": 1,
      "question": "What term best describes the interactions at the load end of a mismatched transmission line?",
      "answers": [
        "Characteristic impedance",
        "Reflection coefficient",
        "Velocity factor",
        "Dielectric constant"
      ]
    },
    {
      "id": "E9E08",
      "correct": 3,
      "question": "Which of the following measurements is characteristic of a mismatched transmission line?",
      "answers": [
        "An SWR less than 1:1",
        "A reflection coefficient greater than 1",
        "A dielectric constant greater than 1",
        "An SWR greater than 1:1"
      ]
    },
    {
      "id": "E9E09",
      "correct": 2,
      "question": "Which of these matching systems is an effective method of connecting a 50 ohm coaxial cable feed line to a grounded tower so it can be used as a vertical antenna?",
      "answers": [
        "Double-bazooka match",
        "Hairpin match",
        "Gamma match",
        "All of these choices are correct"
      ]
    },
    {
      "id": "E9E10",
      "correct": 2,
      "question": "Which of these choices is an effective way to match an antenna with a 100 ohm feed point impedance to a 50 ohm coaxial cable feed line?",
      "answers": [
        "Connect a 1/4-wavelength open stub of 300 ohm twin-lead in parallel with the coaxial feed line where it connects to the antenna",
        "Insert a 1/2 wavelength piece of 300 ohm twin-lead in series between the antenna terminals and the 50 ohm feed cable",
        "Insert a 1/4-wavelength piece of 75 ohm coaxial cable transmission line in series between the antenna terminals and the 50 ohm feed cable",
        "Connect 1/2 wavelength shorted stub of 75 ohm cable in parallel with the 50 ohm cable where it attaches to the antenna"
      ]
    },
    {
      "id": "E9E11",
      "correct": 1,
      "question": "What is an effective way of matching a feed line to a VHF or UHF antenna when the impedances of both the antenna and feed line are unknown?",
      "answers": [
        "Use a 50 ohm 1:1 balun between the antenna and feed line",
        "Use the universal stub matching technique",
        "Connect a series-resonant LC network across the antenna feed terminals",
        "Connect a parallel-resonant LC network across the antenna feed terminals"
      ]
    },
    {
      "id": "E9E12",
      "correct": 0,
      "question": "What is the primary purpose of a phasing line when used with an antenna having multiple driven elements?",
      "answers": [
        "It ensures that each driven element operates in concert with the others to create the desired antenna pattern",
        "It prevents reflected power from traveling back down the feed line and causing harmonic radiation from the transmitter",
        "It allows single-band antennas to operate on other bands",
        "It makes sure the antenna has a low-angle radiation pattern"
      ]
    },
    {
      "id": "E9E13",
      "correct": 2,
      "question": "What is a use for a Wilkinson divider?",
      "answers": [
        "It divides the operating frequency of a transmitter signal so it can be used on a lower frequency band",
        "It is used to feed high-impedance antennas from a low-impedance source",
        "It is used to divide power equally between two 50 ohm loads while maintaining 50 ohm input impedance",
        "It is used to feed low-impedance loads from a high-impedance source"
      ]
    },
    {
      "id": "E9F01",
      "correct": 3,
      "question": "What is the velocity factor of a transmission line?",
      "answers": [
        "The ratio of the characteristic impedance of the line to the terminating impedance",
        "The index of shielding for coaxial cable",
        "The velocity of the wave in the transmission line multiplied by the velocity of light in a vacuum",
        "The velocity of the wave in the transmission line divided by the velocity of light in a vacuum"
      ]
    },
    {
      "id": "E9F02",
      "correct": 2,
      "question": "Which of the following determines the velocity factor of a transmission line?",
      "answers": [
        "The termination impedance",
        "The line length",
        "Dielectric materials used in the line",
        "The center conductor resistivity"
      ]
    },
    {
      "id": "E9F03",
      "correct": 3,
      "question": "Why is the physical length of a coaxial cable transmission line shorter than its electrical length?",
      "answers": [
        "Skin effect is less pronounced in the coaxial cable",
        "The characteristic impedance is higher in a parallel feed line",
        "The surge impedance is higher in a parallel feed line",
        "Electrical signals move more slowly in a coaxial cable than in air"
      ]
    },
    {
      "id": "E9F04",
      "correct": 1,
      "question": "What is the typical velocity factor for a coaxial cable with solid polyethylene dielectric?",
      "answers": [
        "2.70",
        "0.66",
        "0.30",
        "0.10"
      ]
    },
    {
      "id": "E9F05",
      "correct": 2,
      "question": "What is the approximate physical length of a solid polyethylene dielectric coaxial transmission line that is electrically one-quarter wavelength long at 14.1 MHz?",
      "answers": [
        "20 meters",
        "2.3 meters",
        "3.5 meters",
        "0.2 meters"
      ]
    },
    {
      "id": "E9F06",
      "correct": 2,
      "question": "What is the approximate physical length of an air-insulated, parallel conductor transmission line that is electrically one-half wavelength long at 14.10 MHz?",
      "answers": [
        "15 meters",
        "20 meters",
        "10 meters",
        "71 meters"
      ]
    },
    {
      "id": "E9F07",
      "correct": 0,
      "question": "How does ladder line compare to small-diameter coaxial cable such as RG-58 at 50 MHz?",
      "answers": [
        "Lower loss",
        "Higher SWR",
        "Smaller reflection coefficient",
        "Lower velocity factor"
      ]
    },
    {
      "id": "E9F08",
      "correct": 0,
      "question": "What is the term for the ratio of the actual speed at which a signal travels through a transmission line to the speed of light in a vacuum?",
      "answers": [
        "Velocity factor",
        "Characteristic impedance",
        "Surge impedance",
        "Standing wave ratio"
      ]
    },
    {
      "id": "E9F09",
      "correct": 1,
      "question": "What is the approximate physical length of a solid polyethylene dielectric coaxial transmission line that is electrically one-quarter wavelength long at 7.2 MHz?",
      "answers": [
        "10 meters",
        "6.9 meters",
        "24 meters",
        "50 meters"
      ]
    },
    {
      "id": "E9F10",
      "correct": 2,
      "question": "What impedance does a 1/8 wavelength transmission line present to a generator when the line is shorted at the far end?",
      "answers": [
        "A capacitive reactance",
        "The same as the characteristic impedance of the line",
        "An inductive reactance",
        "The same as the input impedance to the final generator stage"
      ]
    },
    {
      "id": "E9F11",
      "correct": 2,
      "question": "What impedance does a 1/8 wavelength transmission line present to a generator when the line is open at the far end?",
      "answers": [
        "The same as the characteristic impedance of the line",
        "An inductive reactance",
        "A capacitive reactance",
        "The same as the input impedance of the final generator stage"
      ]
    },
    {
      "id": "E9F12",
      "correct": 3,
      "question": "What impedance does a 1/4 wavelength transmission line present to a generator when the line is open at the far end?",
      "answers": [
        "The same as the characteristic impedance of the line",
        "The same as the input impedance to the generator",
        "Very high impedance",
        "Very low impedance"
      ]
    },
    {
      "id": "E9F13",
      "correct": 0,
      "question": "What impedance does a 1/4 wavelength transmission line present to a generator when the line is shorted at the far end?",
      "answers": [
        "Very high impedance",
        "Very low impedance",
        "The same as the characteristic impedance of the transmission line",
        "The same as the generator output impedance"
      ]
    },
    {
      "id": "E9F14",
      "correct": 1,
      "question": "What impedance does a 1/2 wavelength transmission line present to a generator when the line is shorted at the far end?",
      "answers": [
        "Very high impedance",
        "Very low impedance",
        "The same as the characteristic impedance of the line",
        "The same as the output impedance of the generator"
      ]
    },
    {
      "id": "E9F15",
      "correct": 0,
      "question": "What impedance does a 1/2 wavelength transmission line present to a generator when the line is open at the far end?",
      "answers": [
        "Very high impedance",
        "Very low impedance",
        "The same as the characteristic impedance of the line",
        "The same as the output impedance of the generator"
      ]
    },
    {
      "id": "E9F16",
      "correct": 3,
      "question": "Which of the following is a significant difference between foam dielectric coaxial cable and solid dielectric cable, assuming all other parameters are the same?",
      "answers": [
        "Foam dielectric has lower safe operating voltage limits",
        "Foam dielectric has lower loss per unit of length",
        "Foam dielectric has higher velocity factor",
        "All of these choices are correct"
      ]
    },
    {
      "id": "E9G01",
      "correct": 0,
      "question": "Which of the following can be calculated using a Smith chart?",
      "answers": [
        "Impedance along transmission lines",
        "Radiation resistance",
        "Antenna radiation pattern",
        "Radio propagation"
      ]
    },
    {
      "id": "E9G02",
      "correct": 1,
      "question": "What type of coordinate system is used in a Smith chart?",
      "answers": [
        "Voltage circles and current arcs",
        "Resistance circles and reactance arcs",
        "Voltage lines and current chords",
        "Resistance lines and reactance chords"
      ]
    },
    {
      "id": "E9G03",
      "correct": 2,
      "question": "Which of the following is often determined using a Smith chart?",
      "answers": [
        "Beam headings and radiation patterns",
        "Satellite azimuth and elevation bearings",
        "Impedance and SWR values in transmission lines",
        "Trigonometric functions"
      ]
    },
    {
      "id": "E9G04",
      "correct": 2,
      "question": "What are the two families of circles and arcs that make up a Smith chart?",
      "answers": [
        "Resistance and voltage",
        "Reactance and voltage",
        "Resistance and reactance",
        "Voltage and impedance"
      ]
    },
    {
      "id": "E9G05",
      "correct": 0,
      "question": "What type of chart is shown in Figure E9-3?",
      "answers": [
        "Smith chart",
        "Free space radiation directivity chart",
        "Elevation angle radiation pattern chart",
        "Azimuth angle radiation pattern chart"
      ]
    },
    {
      "id": "E9G06",
      "correct": 1,
      "question": "On the Smith chart shown in Figure E9-3, what is the name for the large outer circle on which the reactance arcs terminate?",
      "answers": [
        "Prime axis",
        "Reactance axis",
        "Impedance axis",
        "Polar axis"
      ]
    },
    {
      "id": "E9G07",
      "correct": 3,
      "question": "On the Smith chart shown in Figure E9-3, what is the only straight line shown?",
      "answers": [
        "The reactance axis",
        "The current axis",
        "The voltage axis",
        "The resistance axis"
      ]
    },
    {
      "id": "E9G08",
      "correct": 2,
      "question": "What is the process of normalization with regard to a Smith chart?",
      "answers": [
        "Reassigning resistance values with regard to the reactance axis",
        "Reassigning reactance values with regard to the resistance axis",
        "Reassigning impedance values with regard to the prime center",
        "Reassigning prime center with regard to the reactance axis"
      ]
    },
    {
      "id": "E9G09",
      "correct": 0,
      "question": "What third family of circles is often added to a Smith chart during the process of solving problems?",
      "answers": [
        "Standing wave ratio circles",
        "Antenna-length circles",
        "Coaxial-length circles",
        "Radiation-pattern circles"
      ]
    },
    {
      "id": "E9G10",
      "correct": 3,
      "question": "What do the arcs on a Smith chart represent?",
      "answers": [
        "Frequency",
        "SWR",
        "Points with constant resistance",
        "Points with constant reactance"
      ]
    },
    {
      "id": "E9G11",
      "correct": 1,
      "question": "How are the wavelength scales on a Smith chart calibrated?",
      "answers": [
        "In fractions of transmission line electrical frequency",
        "In fractions of transmission line electrical wavelength",
        "In fractions of antenna electrical wavelength",
        "In fractions of antenna electrical frequency"
      ]
    },
    {
      "id": "E9H01",
      "correct": 3,
      "question": "When constructing a Beverage antenna, which of the following factors should be included in the design to achieve good performance at the desired frequency?",
      "answers": [
        "Its overall length must not exceed 1/4 wavelength",
        "It must be mounted more than 1 wavelength above ground",
        "It should be configured as a four-sided loop",
        "It should be one or more wavelengths long"
      ]
    },
    {
      "id": "E9H02",
      "correct": 0,
      "question": "Which is generally true for low band (160 meter and 80 meter) receiving antennas?",
      "answers": [
        "Atmospheric noise is so high that gain over a dipole is not important",
        "They must be erected at least 1/2 wavelength above the ground to attain good directivity",
        "Low loss coax transmission line is essential for good performance",
        "All of these choices are correct"
      ]
    },
    {
      "id": "E9H04",
      "correct": 1,
      "question": "What is an advantage of using a shielded loop antenna for direction finding?",
      "answers": [
        "It automatically cancels ignition noise in mobile installations",
        "It is electro statically balanced against ground, giving better nulls",
        "It eliminates tracking errors caused by strong out-of-band signals",
        "It allows stations to communicate without giving away their position"
      ]
    },
    {
      "id": "E9H05",
      "correct": 0,
      "question": "What is the main drawback of a wire-loop antenna for direction finding?",
      "answers": [
        "It has a bidirectional pattern",
        "It is non-rotatable",
        "It receives equally well in all directions",
        "It is practical for use only on VHF bands"
      ]
    },
    {
      "id": "E9H06",
      "correct": 2,
      "question": "What is the triangulation method of direction finding?",
      "answers": [
        "The geometric angles of sky waves from the source are used to determine its position",
        "A fixed receiving station plots three headings to the signal source",
        "Antenna headings from several different receiving locations are used to locate the signal source",
        "A fixed receiving station uses three different antennas to plot the location of the signal source"
      ]
    },
    {
      "id": "E9H07",
      "correct": 3,
      "question": "Why is it advisable to use an RF attenuator on a receiver being used for direction finding?",
      "answers": [
        "It narrows the bandwidth of the received signal to improve signal to noise ratio",
        "It compensates for the effects of an isotropic antenna, thereby improving directivity",
        "It reduces loss of received signals caused by antenna pattern nulls, thereby increasing sensitivity",
        "It prevents receiver overload which could make it difficult to determine peaks or nulls"
      ]
    },
    {
      "id": "E9H08",
      "correct": 0,
      "question": "What is the function of a sense antenna?",
      "answers": [
        "It modifies the pattern of a DF antenna array to provide a null in one direction",
        "It increases the sensitivity of a DF antenna array",
        "It allows DF antennas to receive signals at different vertical angles",
        "It provides diversity reception that cancels multipath signals"
      ]
    },
    {
      "id": "E9H09",
      "correct": 2,
      "question": "Which of the following describes the construction of a receiving loop antenna?",
      "answers": [
        "A large circularly polarized antenna",
        "A small coil of wire tightly wound around a toroidal ferrite core",
        "One or more turns of wire wound in the shape of a large open coil",
        "A vertical antenna coupled to a feed line through an inductive loop of wire"
      ]
    },
    {
      "id": "E9H10",
      "correct": 3,
      "question": "How can the output voltage of a multiple turn receiving loop antenna be increased?",
      "answers": [
        "By reducing the permeability of the loop shield",
        "By increasing the number of wire turns in the loop and reducing the area of the loop structure",
        "By winding adjacent turns in opposing directions",
        "By increasing either the number of wire turns in the loop or the area of the loop structure or both"
      ]
    },
    {
      "id": "E9H11",
      "correct": 1,
      "question": "What characteristic of a cardioid pattern antenna is useful for direction finding?",
      "answers": [
        "A very sharp peak",
        "A very sharp single null",
        "Broad band response",
        "High-radiation angle"
      ]
    },
    {
      "id": "E0A01",
      "correct": 1,
      "question": "What is the primary function of an external earth connection or ground rod?",
      "answers": [
        "Reduce received noise",
        "Lightning protection",
        "Reduce RF current flow between pieces of equipment",
        "Reduce RFI to telephones and home entertainment systems"
      ]
    },
    {
      "id": "E0A02",
      "correct": 1,
      "question": "When evaluating RF exposure levels from your station at a neighbor’s home, what must you do?",
      "answers": [
        "Make sure signals from your station are less than the controlled MPE limits",
        "Make sure signals from your station are less than the uncontrolled MPE limits",
        "You need only evaluate exposure levels on your own property",
        "Advise your neighbors of the results of your tests"
      ]
    },
    {
      "id": "E0A03",
      "correct": 2,
      "question": "Which of the following would be a practical way to estimate whether the RF fields produced by an amateur radio station are within permissible MPE limits?",
      "answers": [
        "Use a calibrated antenna analyzer",
        "Use a hand calculator plus Smith-chart equations to calculate the fields",
        "Use an antenna modeling program to calculate field strength at accessible locations",
        "All of the choices are correct"
      ]
    },
    {
      "id": "E0A04",
      "correct": 2,
      "question": "When evaluating a site with multiple transmitters operating at the same time, the operators and licensees of which transmitters are responsible for mitigating over-exposure situations?",
      "answers": [
        "Only the most powerful transmitter",
        "Only commercial transmitters",
        "Each transmitter that produces 5 percent or more of its MPE limit at accessible locations",
        "Each transmitter operating with a duty-cycle greater than 50 percent"
      ]
    },
    {
      "id": "E0A05",
      "correct": 1,
      "question": "What is one of the potential hazards of using microwaves in the amateur radio bands?",
      "answers": [
        "Microwaves are ionizing radiation",
        "The high gain antennas commonly used can result in high exposure levels",
        "Microwaves often travel long distances by ionospheric reflection",
        "The extremely high frequency energy can damage the joints of antenna structures"
      ]
    },
    {
      "id": "E0A06",
      "correct": 3,
      "question": "Why are there separate electric (E) and magnetic (H) field MPE limits?",
      "answers": [
        "The body reacts to electromagnetic radiation from both the E and H fields",
        "Ground reflections and scattering make the field impedance vary with location",
        "E field and H field radiation intensity peaks can occur at different locations",
        "All of these choices are correct"
      ]
    },
    {
      "id": "E0A07",
      "correct": 1,
      "question": "How may dangerous levels of carbon monoxide from an emergency generator be detected?",
      "answers": [
        "By the odor",
        "Only with a carbon monoxide detector",
        "Any ordinary smoke detector can be used",
        "By the yellowish appearance of the gas"
      ]
    },
    {
      "id": "E0A08",
      "correct": 2,
      "question": "What does SAR measure?",
      "answers": [
        "Synthetic Aperture Ratio of the human body",
        "Signal Amplification Rating",
        "The rate at which RF energy is absorbed by the body",
        "The rate of RF energy reflected from stationary terrain"
      ]
    },
    {
      "id": "E0A09",
      "correct": 2,
      "question": "Which insulating material commonly used as a thermal conductor for some types of electronic devices is extremely toxic if broken or crushed and the particles are accidentally inhaled?",
      "answers": [
        "Mica",
        "Zinc oxide",
        "Beryllium Oxide",
        "Uranium Hexafluoride"
      ]
    },
    {
      "id": "E0A10",
      "correct": 0,
      "question": "What toxic material may be present in some electronic components such as high voltage capacitors and transformers?",
      "answers": [
        "Polychlorinated Biphenyls",
        "Polyethylene",
        "Polytetrafluroethylene",
        "Polymorphic silicon"
      ]
    },
    {
      "id": "E0A11",
      "correct": 2,
      "question": "Which of the following injuries can result from using high-power UHF or microwave transmitters?",
      "answers": [
        "Hearing loss caused by high voltage corona discharge",
        "Blood clotting from the intense magnetic field",
        "Localized heating of the body from RF exposure in excess of the MPE limits",
        "Ingestion of ozone gas from the cooling system"
      ]
    }
  ]
};
});